body[data-theme='light'],
body[data-theme='dark'] {
  .addButton {
    line-height: 0;
    padding: 4px 6px;

    &:hover, {
      .activityIcon {
        fill: var(--primary-color); } } }

  .activityIcon {
    transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

  .openedButton {
    background-color: var(--primary-color) !important;

    &:hover {
      .activityIcon {
        fill: #fff; } }

    .activityIcon {
      fill: #fff; } }

  .disabledButton {
    visibility: hidden;
    opacity: 0;
    user-select: none; }

  .addPopoverOverlay {
    padding-top: 0;

     :global {
      .ant-popover-arrow {
        display: none; }

      .ant-divider {
        margin: 0; }

      .ant-popover-inner {
        border-radius: 0 5px 5px 5px; } } }

  .partnerSelect {
    width: 340px; } }
