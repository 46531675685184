body[data-theme='light'],
body[data-theme='dark'] {
  .partnerMaskWrapper {
    width: 24px;
    height: 24px;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: center; } }
