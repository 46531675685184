@import '../../../../../helpers/styles/mixins.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .permissionCounter {
    margin-left: 5px;
    border-radius: 50%;
    color: white;
    background-color: var(--primary-color);
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 10px;
    transition: .25s ease;
    padding-top: 2px; }

  .permissionsSelectWrapper {
    position: relative;

     :global {
      .ant-popover-open {
        background-color: var(--primary-color);
        color: white;

        .permissionCounter {
          background-color: white;
          color: rgba(0, 0, 0, 0.85); }

        svg {
          fill: white; } } } }

  .permissionsSelect {
    border-radius: 5px;
    background-color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 40px;
    padding: 0px 8px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    transition: .25s ease;

    svg {
      position: relative;
      left: 8px; } }

  .permissionsSelectOverlay {
     :global {
      .ant-popover-content {
        .ant-popover-arrow {
          display: none; }

        .ant-popover-inner {
          border-radius: 5px; } } } }

  .profileInput {
    width: 100%;
    padding: 11px 12px;
    height: 40px;
    border-radius: 5px;

    &:disabled {
      cursor: default;
      padding-left: 0;
      background: transparent;
      color: var(--font-black);
      border: 1px solid transparent;

      &:hover {
        border: 1px solid transparent; } } }

  .entityInput {
    line-height: 40px; }

  .profileSelect {
    width: 100%; }

  .entityField {
    color: var(--font-black);
    height: 40px;
    display: flex;
    align-items: center; }

  .profileSelect:global(.ant-select-disabled) {
     :global {
      .ant-select-selector {
        padding-left: 0 !important; } } }

  .profileInputError {
    display: inline-block;
    font-family: Roboto;
    font-size: 10px;
    line-height: 175%;
    color: red; }

  .profile {
    padding: 16px 34px 34px 34px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

  .profileHeader {
    flex-basis: 100%;
    min-height: 61px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--grey-background-break-gray);
    margin-bottom: 28px;

    h1 {
      margin: 0;
      font-family: Gilroy;
      font-size: 20px;
      line-height: 24px; } }

  .profileUsername {
    font-size: 13px;
    line-height: 15px;
    color: var(--qs-gray); }

  .entitySelect {
     :global {
      .ant-select-selection-item {
        transition: padding 0.3s ease;
        background: none;
        border: none; }

      .ant-select-selection-item {
        line-height: 32px !important;
        height: 32px !important; }

      .ant-select-selection-item-content,
      .ant-select-selection-item-remove {
        display: flex;
        align-items: center; } } }

  .entitySelect:global(.ant-select-disabled) {
     :global {
      .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          padding-left: 0 !important;
          background: none;
          border: none; } } } }

  .entitySelect:global(.ant-select-open) {
     :global {
      .ant-select-selection-item-remove {
        color: #fff; }

      .ant-select-selector .ant-select-selection-item {
        border: 1px solid #FFFFFF80;
        background-color: transparent;
        color: #fff; } } }

  .profileBlockHeader {
    font-family: Gilroy;
    font-size: 15px;
    line-height: 18px;
    color: var(--font-black);
    display: block;
    margin: 0 0 15px 0; }

  .profileBlock {
    margin-top: 35px;
    flex-basis: 100%;
    &:first-of-type {
      margin-top: 0; }

    @media (min-width: 1200px) {
      flex-basis: 49%;

      &:nth-of-type(-n+2) {
        margin-top: 0; } } }

  .profileBlockWrapper {
    position: relative;
    background-color: #fff;
    padding: 0 25px;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);

    &.large {
      min-height: 402px;

      @include mq('tablet-wide', max) {
        min-height: auto; } }

    &.small {
      min-height: 120px;
      display: flex;
      align-items: center;

      .profileBlockRow {
        width: 100%; } } }

  .highlightedText {
    padding: 0;
    margin: 0;
    color: var(--primary-color);
    background-color: transparent;
    font-weight: bold; }

  .profileTextarea {
    padding: 11px 12px;
    width: 100%;
    resize: none;
    height: 212px !important;

    &:disabled {
      cursor: default;
      background-color: transparent;
      border: 1px solid transparent;
      color: var(--font-black);
      padding-left: 0;

      &:hover {
        border: 1px solid transparent; } }

    &.error {
      border: 1px solid red;

      &:focus-within {
        outline: 0; } } }

  .profileTextareaDescription {
    font-size: 10px;
    color: rgba(#000, 0.5);
    height: 13px; }

  .profileBlockRow {
    padding: 10px 0;

    &:first-of-type {
      padding-top: 20px; }

    &:last-of-type {
      padding-bottom: 20px; } }

  .required {
    position: relative;

    &:after {
      transition: all 0.3s;
      position: relative;
      content: '*';
      color: var(--primary-color);
      left: 4px; } }

  .profileButtonGroup {
    display: flex;
    align-items: center;
    gap: 4px; }

  .profileLabel {
    font-size: 13px;
    color: var(--qs-gray);

    &.information {
      transition: all 0.3s;
      display: flex;
      align-items: center; } }

  .divider {
    margin: 10px 0;
    height: 1px;
    width: 100%;
    background-color: var(--background-gray); }

  .notSpecified {
    height: 40px;
    color: var(--primary-color);
    font-size: 15px;
    line-height: 18px; }

  .notSpecifiedPopover {
    z-index: 999; }

  .questionMarkInfo {
    cursor: pointer;
    position: relative;
    left: 10px; }

  .mainSkillDescription {
    position: absolute;
    left: 50%;
    right: 10px;
    bottom: 5px;
    font-size: 10px;
    color: rgba(#000, 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  .profileMainSkill {
    margin: 0;
    padding: 0 25px; }


  .roleSwitcher {
    width: 150px;

     :global {
      .ant-select-selection-item {
        text-transform: capitalize; } } }

  .roleSwitcherItem {
    text-transform: capitalize; }

  @include mq('tablet-wide', max) {
    .mainSkillDescription {
      left: 25px; } } }

body[data-theme="light"] {
  .profileInput,
  .profileTextarea {
    border: 1px solid var(--background-gray);
    background-color: var(--background-gray); }

  .roleSwitcher {
    background-color: #fff; }

  .profileHeader {
    h1 {
      color: var(--font-black); } }

  .profileInput {
    color: var(--font-black);
    -webkit-text-fill-color: var(--font-black); } // black text render for Safari

  .profileTextarea {
    color: var(--font-black); } }

body[data-theme="dark"] {
  .permissionsSelect {
    background-color: var(--dt-qs-dark);
    color: white; }

  .profileInput {
    border: 1px solid transparent; }

  .profileTextareaDescription,
  .mainSkillDescription,
  .profileUsername {
    color: var(--dt-qs-gray); }

  .profileBlockWrapper {
    background-color: var(--dt-second-background-color); }

  .editProfileButton {
    background-color: var(--dt-qs-gray-20); }

  .divider {
    background-color: var(--dt-qs-gray-20); } }







