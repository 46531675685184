body[data-theme='light'],
body[data-theme='dark'] {
  .mobileMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background-color: white;

     :global {
      .ant-dropdown-menu {
        padding: 8px; } } }

  .mobileMenuIcon {
    fill: var(--qs-gray);
    height: 16px;
    width: 16px; }

  .drawerBody {
    min-height: 500px;
    background-color: #fff; }

  .linkContentWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    font-family: Gilroy, Helvetica Neue; }

  .mobileMenuNestedItem {
    height: 50px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    font-family: Gilroy, Helvetica Neue; }

  .drawerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    margin-bottom: 10px;
    font-family: Gilroy, Helvetica Neue;
    border-bottom: 1px solid var(--gray-4);
    background-color: #fff; }

  .mobileMenuDrawer {
     :global {
      .ant-drawer-body {
        padding: 0 0 24px 0;
        background: var(--primary-color) url('../../../helpers/images/LoginImage.jpg') center/cover no-repeat; }

      .ant-drawer-footer {
        text-align: right;
        padding: 16px; }

      .ant-drawer-header {
        padding: 16px; } } }

  .mobileMenuDrawerCloseIcon {
    color: #9EA7AE;
    font-size: 20px; }

  .mobileFooter {
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .changeThemeIcon {
    color: var(--qs-gray); } }

body[data-theme='light'] {
  .mobileLogoutButton {
    font-family: Gilroy, Helvetica Neue;
    font-weight: bold;
    background-color: transparent !important; } }

body[data-theme='dark'] {
  .mobileMenu,
  .drawerBody,
  .drawerHeader {
    background-color: var(--primary-backround-black); }

  .drawerBody {
     :global {
      .ant-menu-item-selected {
        background-color: var(--dt-qs-gray-20); }
      .ant-menu {
        box-shadow: none; } } }

  .mobileMenuDrawer {
     :global {

      .ant-drawer-content {
        background-color: var(--primary-backround-black); } } }

  .drawerHeader {
    border-bottom: 1px solid var(--dt-qs-gray-20); }

  .mobileMenuDrawerCloseIcon {
    fill: #fff; }

  .hamburgerMobileIcon {
    fill: #fff; }

  .mobileLogoutButton {
    background-color: transparent !important; } }
