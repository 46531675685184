body[data-theme='light'],
body[data-theme='dark'] {
  .closedMonthPopup {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 13px 24px;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: rgba(#000, 0.8);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    z-index: 10;
    transition: .15s ease;
    cursor: pointer;

    &:not(.closed):hover {
      transform: translate(-50%, 5px); }

    &.closed {
      bottom: 0;
      transform: translate(-50%, calc(100% - 5px));

      &:hover {
        transform: translate(-50%, calc(100% - 10px)); } } }

  .text {
    line-height: .86; } }
