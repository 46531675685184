body[data-theme='light'],
body[data-theme='dark'] {
  .salaryField {
    font-size: 14px;
    padding: 6px 7px;
    position: relative;
    width: fit-content;
    color: var(--font-black);

    &:hover {
      transition: 0.3s ease;
      cursor: pointer;
      color: var(--primary-color);

      &:after {
        transition: 0.3s ease;
        border-bottom: 1px dashed var(--primary-color); } }

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%, 0);
      bottom: 3px;
      width: 90%;
      border-bottom: 1px dashed var(--qs-gray);
      height: 1px; } }

  .salaryFieldOpened {
    background-color: #23354A;
    color: #fff;
    border-radius: 5px;
    transition: 0.3s ease;

    &:after {
      border-bottom: 1px dashed transparent; }

    &:hover {
      color: #fff;

      &:after {
        border-bottom: 1px dashed transparent; } } }

  .salaryFieldPopoverLayout {
    padding: 23px 14px 12px 14px;
    width: 100%; }

  .salaryFieldPopover {
    width: auto; }

  .salaryFieldInput {
    width: 120px; }

  .salaryFieldPopoverFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

  .salaryFieldFormItem {
    display: flex;
    flex-direction: column;
    margin: 0; }

  .salaryFieldSubmit {
    margin-top: 14px; } }


