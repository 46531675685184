body[data-theme='light'],
body[data-theme='dark'] {
  .userNotificationLayout {
    opacity: 1;
    border-bottom: 1px solid rgba(217, 227, 236, 0.3);
    padding-bottom: 7px; }

  .hidden {
    height: 0;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    margin-bottom: 0;
    border: none; }

  .userNotificationWrapper {
    display: flex;
    padding-right: 7px;

    width: 100%;
    height: 71px;
    background: #FFFFFF;

    border-right: 1px solid var(--orange);
    border-top: 1px solid var(--orange);
    border-bottom: 1px solid var(--orange);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05); }

  .userNotificationSide {
    position: relative;
    width: 40px;
    height: 71px;
    background-color: var(--orange);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; }

  .userNotificationSideLogo {
    position: relative;
    left: 7px;
    top: 12px; }

  .userNotification {
    padding: 0 7px;
    display: flex; }

  .userNotificationLink {
    margin-bottom: 7px;

    &:link {
      text-decoration: none; } }

  .linkDescriptionWrapper {
    padding: 14px 0 10px 10px; }

  .linkLabel {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 16px;
    color: var(--orange);
    margin: 0;
    padding-bottom: 5px; }

  .linkDescription {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: var(--icon-gray);
    margin-bottom: 0; }

  .infoButton {
    background-color: transparent;
    display: flex;
    align-items: center;
    position: absolute;
    top: 280px;
    left: 3px;
    box-shadow: none;

    opacity: 1;
    visibility: visible;
    transition: 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

    border: none;
    padding: 0 6px;
    border-radius: 50%;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent; } }

  .collapsedInfoButton {
    opacity: 0;
    visibility: hidden; }

  .collapsedInfoIcon {
    fill: var(--orange); }

  .notificationOverlay {
    .userNotificationLayout {
      margin: 0; }

    .userNotification {
      padding: 0; }

     :global {
      .ant-popover-inner {
        border-radius: 5px; }
      .ant-popover-arrow {
        border-color: var(--orange) !important; }
      .ant-popover-inner-content {
        padding: 0; } } } }


body[data-theme='dark'] {
  .userNotificationLayout {
    border-bottom: 1px solid var(--dt-qs-gray-20); }

  .linkDescription {
    color: var(--dt-qs-gray); }

  .userNotificationWrapper {
    background-color: #1B2228; }

  .linkLabel {
    color: #fff; } }

