body[data-theme='light'],
body[data-theme='dark'] {
  .requests-tab-wrapper {
    display: flex;
    flex-direction: row;
    position: relative;
    overflow: hidden; }

  .requests-tab-partners_wrapper {
      width: 240px;
      min-height: 100%;
      max-height: 100%;
      height: 100%;
      background-color: var(--white);
      position: relative; }

  .requests-tab-requests {
    &_preloader {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 240px;
      top: 0;
      width: calc(100% - 240px);
      height: 100%;
      background-color: #f1f3f580;
      z-index: 10;

       :global {
        .spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); } } }

    &_wrapper {
      flex-grow: 2;
      background-color: #F1F3F5;
      height: calc(100vh - 85px);
      padding: 25px 40px;
      overflow-y: auto; } }

  .request-tab-partner {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--qs-gray);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      width: 130px;
      margin-bottom: 10px; } } }

body[data-theme='dark'] {
  .requests-tab-requests {
    &_wrapper {
      background-color: var(--primary-backround-black); } } }
