body[data-theme='light'],
body[data-theme='dark'] {
  .userReportsList {
    margin: 0;
    padding: 0;
    max-height: 350px;
    overflow-y: auto;
    padding: 18px 18px;
    margin-top: 18px;
    border-radius: 5px;
    background-color: var(--background-gray); }

  .userReport {
    list-style: none;
    height: 60px;
    display: flex;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, .1); } }

  .userCard {
    gap: 10px;
    display: flex;
    align-items: center;

    .userName {
      flex-grow: 2; } }

  .redText {
    padding: 3px 7px;
    border-radius: 5px;
    background-color: #263341;
    color: white;
    margin: 0 .45em; }

  .checkbox {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    flex-grow: 2;

    span:first-child {
      font-size: 0; } // checkbox input alignment

    span:last-child {
      flex-grow: 2; } } } // label

body[data-theme="dark"] {
  .redText {
    background-color: var(--background-gray); } }
