body[data-theme='light'],
body[data-theme='dark'] {
  .partnersSettings {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; }

  .defaultSelectionValue {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      margin-right: 10px;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: var(--primary-color); } }

  .partnersUsersRadio {
    flex-grow: 2; }

  .filtersWrapper {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px; }

  .partnersRadio {
    z-index: 2;
    padding-left: 0; }

  .partnersInput {
    width: 220px; }

  .partnersSelect {
    width: 150px; } }

body[data-theme='light'] {
  .partnersInput {
    background-color: #fff;

     :global {
      .ant-input {
        background-color: #fff; } } }

  .partnersSelect {
    background-color: #E5EAEE; } }
