body[data-theme='light'],
body[data-theme='dark'] {
  .commentsCustomScrollBar {
    overflow-x: hidden; }

  .groupPopoverCommentsInner {
    padding: 9px 0px;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: calc(100% - 4px);
      left: 0;
      top: 0;
      border-bottom: 1px solid #D9E3EC;
      opacity: 0.3; }

    &.noComments {
      padding: 0;

      &::after {
        content: none; } } }

  .addCommentButton {
    font-size: 11px;
    padding: 0 9px;
    background-color: #E5EAEE;
    color: var(--qs-gray);
    border-radius: 5px;
    border: none;
    margin-top: 10px;

    &:hover,
    &:focus,
    &:active {
      background-color: darken(#E5EAEE, 10);
      color: darken(#727981, 10); }

    &:after,
    &:before {
      display: none; } } }

body[data-theme='dark'] {
  .addCommentButton {
    background-color: var(--gray-9);
    color: var(--font-white-85);

    &:hover,
    &:focus,
    &:active {
      background-color: var(--gray-8);
      color: var(--font-white-85); } } }
