body[data-theme='light'],
body[data-theme='dark'] {
  .table {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: calc(100% - 80px); // 80px comes from table settings component
    overflow: hidden;
    background-color: #ffffff;
    overflow: auto;

 } }    // @media screen and (max-width: 1440px)
