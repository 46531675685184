body[data-theme='light'],
body[data-theme='dark'] {
  .select {
    position: relative;

     :global {
      .ant-select-arrow {
        top: 11px !important; } } }

  .commentText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    margin: 0;
    border-radius: 5px;
    padding: 6px 8px;
    resize: none;
    outline: none;
    overflow-x: hidden;
    width: 100%;
    transition: .25s ease;
    overflow-wrap: break-word;

    & + .commentText {
      margin-top: 5px; } }

  .comment {
    position: relative;
    width: calc(100% - 16px);
    padding-top: 18px;
    margin-bottom: 5px;

    &.new {
      padding-top: 25px; }

     :global {
      textarea.ant-input {
        line-height: 1.1715;
        max-height: 75px;
        min-height: 75px; } }

    .commentInfo {
      position: absolute;
      right: -5px;
      top: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-size: 10px;
      display: flex;
      width: 100%;

      .timeSelector {
        color: rgba(0, 0, 0, .5); } }

    .regularCommentInfo {
      position: absolute;
      right: 6px;
      top: 0;
      display: flex;
      align-items: center;
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      color: rgba(0, 0, 0, .5);
      width: 100%; }

    .subLabel {
      font-size: 14px; }

    .timeSelector {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal; } } }

body[data-theme='light'] {
  .commentText {
    background: #F2F4F6;
    border: 1px solid #F2F4F6;

    &:focus {
      background: #fff; } } }

body[data-theme='dark'] {
  .comment {
    .regularCommentInfo {
      color: white; } } }
