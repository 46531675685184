$cellWidth: 40px;
body[data-theme='light'],
body[data-theme='dark'] {
  .month {
    width: calc(70px + #{$cellWidth} * 7 + 2px);
    display: inline-block;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    vertical-align: top;
    border: 1px solid #F2F4F6;
    transition: .25s ease;
    //margin-left: 24px

     :global {
      .slick-slide:last-child {
        margin-right: 24px; } }

    &:hover {
       :global {
        .gearButton {
          opacity: 1; } } }

    & > * {
      transition: .25s ease; }

    &.loading {
      & > * {
        opacity: .5; } }

    &.active {
      border-color: var(--primary-color); }

    & + .month {
      margin-left: 24px; } } }

body[data-theme='dark'] {
  .month {
    background-color: var(--dt-qs-dark);
    border-color: var(--dt-qs-gray-20); } }
