body[data-theme='light'],
body[data-theme='dark'] {
  .tableSortHeaderCell {
    display: inline-flex;
    align-items: center;
    gap: 6px;

    // setting to 1 in .basic-table class by hover
     :global {
      .placeholder-sort-icon {
        transition: 0.25s ease;
        opacity: 0; } } } }

