body[data-theme='light'],
body[data-theme='dark'] {
  .pageNotFoundWrapper {
    height: 100%;
    margin-right: var(--menu-width-thick);

    display: flex;
    align-items: center;
    justify-content: center; } }

