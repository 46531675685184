body[data-theme='light'] .qs-dropdown,
body[data-theme='dark'] .qs-dropdown {
  position: relative;
  vertical-align: middle;
  display: table-cell; }
  body[data-theme='light'] .qs-dropdown__child-item,
  body[data-theme='dark'] .qs-dropdown__child-item {
    padding: 5px 10px;
    cursor: pointer; }
    body[data-theme='light'] .qs-dropdown__child-item:hover,
    body[data-theme='dark'] .qs-dropdown__child-item:hover {
      background-color: #e6e6e6; }
  body[data-theme='light'] .qs-dropdown__list,
  body[data-theme='dark'] .qs-dropdown__list {
    position: absolute;
    left: 0;
    top: 100%;
    border: 1px solid #cecece;
    background-color: white;
    border-radius: 0 0 4px 4px;
    pointer-events: none;
    opacity: 0;
    padding: 5px 0;
    margin: 0;
    list-style: none;
    z-index: 100;
    min-width: 200px; }
    body[data-theme='light'] .qs-dropdown__list--nowrap,
    body[data-theme='dark'] .qs-dropdown__list--nowrap {
      white-space: nowrap; }
  body[data-theme='light'] .qs-dropdown__trigger,
  body[data-theme='dark'] .qs-dropdown__trigger {
    color: #777;
    position: relative;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: #fff;
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px; }
    body[data-theme='light'] .qs-dropdown__trigger--size-sm,
    body[data-theme='dark'] .qs-dropdown__trigger--size-sm {
      padding: 5px 10px;
      font-size: 12px;
      line-height: 1.5;
      border-radius: 3px; }
    body[data-theme='light'] .qs-dropdown__trigger svg,
    body[data-theme='dark'] .qs-dropdown__trigger svg {
      margin-left: 5px;
      font-size: 12px; }
    body[data-theme='light'] .qs-dropdown__trigger:hover,
    body[data-theme='dark'] .qs-dropdown__trigger:hover {
      background-color: #e6e6e6; }
    body[data-theme='light'] .qs-dropdown__trigger--part-of-input-group,
    body[data-theme='dark'] .qs-dropdown__trigger--part-of-input-group {
      border-radius: 4px 0 0 4px;
      background-color: #e6e6e6;
      margin-right: -1px; }
      body[data-theme='light'] .qs-dropdown__trigger--part-of-input-group:hover,
      body[data-theme='dark'] .qs-dropdown__trigger--part-of-input-group:hover {
        background-color: #e6e6e6;
        border-color: #adadad; }
  body[data-theme='light'] .qs-dropdown--opened .qs-dropdown__list,
  body[data-theme='dark'] .qs-dropdown--opened .qs-dropdown__list {
    opacity: 1;
    pointer-events: all; }
  body[data-theme='light'] .qs-dropdown--opened .qs-dropdown__trigger,
  body[data-theme='dark'] .qs-dropdown--opened .qs-dropdown__trigger {
    background-color: #f2f2f2; }

body[data-theme='dark'] .qs-dropdown__trigger {
  color: var(--dt-qs-gray);
  background-color: var(--dt-qs-dark);
  border-color: var(--dt-qs-gray-20); }
  body[data-theme='dark'] .qs-dropdown__trigger--part-of-input-group:hover {
    border-color: var(--dt-qs-gray-20); }
  body[data-theme='dark'] .qs-dropdown__trigger:hover {
    background-color: var(--dt-gray-button); }

body[data-theme='dark'] .qs-dropdown__child-item:hover {
  background-color: var(--dt-gray-button); }

body[data-theme='dark'] .qs-dropdown__list {
  color: var(--dt-qs-gray);
  border-color: var(--dt-qs-gray-20);
  background-color: var(--dt-qs-dark); }

body[data-theme='dark'] .qs-dropdown--opened .qs-dropdown__trigger {
  background-color: var(--dt-qs-dark); }
