body[data-theme='light'],
body[data-theme='dark'] {
  .activitiesTab {
    padding: 16px 34px 34px 34px; }

  .activitiesTabHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 22px;
    margin-bottom: 28px;
    border-bottom: 1px solid var(--grey-background-break-gray); }

  .pseudoActivity {
    height: 48px;
    display: flex;
    flex-direction: row;
    gap: 15px;
    padding: 15px; }

  .activitiesCategory {
    & + .activitiesCategory {
      margin-top: 25px; } }

  .categoryTitle {
    margin-bottom: 18px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #000000; }

  .headerTitle {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: var(--font-black); }

  .activity {
    cursor: pointer;
    height: 48px;
    width: 100%;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    display: flex;
    align-items: center;
    justify-content: center;

    &.inactive {
      background: none;
      border: 1px solid #E7E8EA; }

    & + .activity {
      margin-top: 8px; } }

  .border {
    position: relative;
    height: 100%;
    width: 5px;
    border-radius: 5px 0 0 5px; }

  .maskIconClassName {
    width: 12px;
    height: 12px; }

  .maskWrapperClassName {
    width: 24px;
    height: 24px;
    border-radius: 5px;
    margin-left: 11px; }

  .activityName {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    margin-left: 6px;
    flex-grow: 2; }

  .popoverButton {
    height: auto;
    text-align: left;
    border: none;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid var(--dt-qs-gray-20); } }

  .activityId {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--qs-gray);
    opacity: 0.75; }

  .nameFieldWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px; }

  .nameField {
    flex-grow: 2; }

  .colorFIeld {
    flex-grow: 1; }

  .activityNamePartnerMask {
    margin-right: 5px; }

  .colorPickerTrigger {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border-radius: 12px;
    cursor: pointer; }

  .randomizeButton {
    display: flex;
    align-items: center;
    color: var(--primary-color);
    gap: 5px;
    border-radius: 5px;

    svg {
      transition: inherit; }

    &:hover {
      color: var(--primary-color);

      svg {
        fill: var(--primary-color); } } }

  .verticalSeparator {
    margin: 0px 8px;
    border-left: 1px solid #E8EDF1;
    height: 15px; }

  .activityColorMark {
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    border-radius: 12px; }

  .threeDotsIcon {
    color: var(--qs-gray); }

  .threeDotsButton {
    margin-right: 12px;
    margin-left: 6px;
    width: 24px; }

  .addActivityModalInner {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 18px; }

  .titleWrapper,
  .bodyWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px; }

  .fieldName {
    color: var(--dt-qs-gray);
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    height: 32px;
    display: flex;
    align-items: center; }

  .addButton {
    color: white;
    height: 32px;
    padding: 6.4px 15px;
    background-color: var(--primary-color);

    &:hover {
      background-color: #ba344a !important;
      color: white !important; } } }

body[data-theme='dark'] {
  .categoryTitle {
    color: white; }

  .activity {
    background-color: #2D3743;

    &.inactive {
      border-color: #4c4c4c; } }

  .activityName,
  .activityId {
    color: white; } }
