$swatchGap: 12px;
$swatchWidth: 32px;
$swatchBorderWidth: 2px;

body[data-theme='light'],
body[data-theme='dark'] {
  .customizationLayout {
    padding: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background-color: var(--white);
    height: 91px;

    display: flex;
    align-items: center;
    justify-content: space-between; }

  .blockTitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    color: var(--qs-gray); }

  .splitter {
    background-color: #7279811A;
    width: 1px;
    height: 100%; }

  .blockWrapper {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-start;
    flex-direction: column;
    gap: 8px; }

  .colorPickerWrapper {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center; }

  .colorPicker {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer; } }

body[data-theme='dark'] {
  .blockTitle {
    color: var(--dt-qs-gray); }

  .partnerSelection {
    background-color: var(--dt-qs-dark); } }
