.date-picker {
  display: none;
  border-radius: 0.5em;
  box-shadow: 0 0 1.5em 0 rgba(0, 0, 0, 0.25);
  font-size: 1.2rem;
  position: absolute;
  background-color: white; }
  .date-picker__settings {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    border-radius: 0.5em 0.5em 0 0;
    justify-content: center;
    background-color: #FFF;
    padding: 1em; }
    .date-picker__settings--title {
      font-size: 1em;
      font-weight: bold;
      flex-grow: 2;
      text-align: center;
      margin: 0 1em; }
  .date-picker__month {
    position: relative; }
    .date-picker__month-head, .date-picker__month-body {
      position: relative;
      width: calc(2.7em * 7 + 2em);
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
    .date-picker__month-head {
      background-color: #FFF;
      padding: 0 1em;
      position: relative; }
      .date-picker__month-head:before {
        content: '';
        position: absolute;
        border-bottom: 1px solid #BBBBBB;
        left: 1rem;
        bottom: 0;
        width: calc(100% - 2rem); }
    .date-picker__month-body {
      padding: 0 1em 1em; }
    .date-picker__month-day {
      min-width: 2.7em;
      min-height: 2.7em;
      flex-grow: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: .166em;
      position: relative; }
      .date-picker__month-day:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba(244, 83, 110, 0.2);
        opacity: 0;
        width: 70%;
        height: 70%;
        border-radius: 20%; }
      .date-picker__month-day--week-end {
        position: relative; }
      .date-picker__month-day--prev-month, .date-picker__month-day--next-month {
        opacity: .5; }
      .date-picker__month-day--today {
        color: var(--primary-color);
        font-weight: bold; }
      .date-picker__month-day--chosen:before {
        width: calc(90% - 3px);
        height: calc(90% - 3px);
        opacity: 1; }
    .date-picker__month:before {
      content: '';
      position: absolute;
      right: 6.4em;
      top: 0;
      height: calc(100% - 1rem);
      z-index: 1;
      border-left: 1px dashed #BBBBBB; }
  .date-picker--showed {
    display: inline-block;
    z-index: 100;
    top: calc(100% + 5px);
    left: 50%;
    transform: translateX(-50%); }
  .date-picker--top.date-picker--showed {
    top: calc(-100% + 30px);
    left: 50%;
    transform: translate(-50%, -100%); }

.button {
  background: none;
  box-shadow: none;
  border: none;
  padding: 0;
  position: relative;
  width: 100%;
  height: 100%;
  transition: .25s ease; }
  .button:before {
    content: '';
    width: 70%;
    height: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 20%;
    opacity: 0;
    background-color: var(--primary-color);
    transition: .25s ease; }
  .button:hover {
    color: white; }
    .button:hover:before {
      width: 90%;
      height: 90%;
      opacity: 1; }
  .button__main-element {
    position: relative;
    z-index: 1;
    line-height: .86;
    top: 1px; }
  .button--control-button {
    width: auto;
    height: auto;
    min-width: 3em;
    min-height: 3em; }
    .button--control-button:hover {
      color: inherit; }
    .button--control-button:before {
      background-color: #eee; }
