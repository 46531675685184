body[data-theme='light'],
body[data-theme='dark'] {
  .employeesListWrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 24px; }

  .employeesListTitle {
    font-size: 48px;
    font-family: Gilroy, Helvetica Neue;
    color: var(--font-black); }

  .employeesList {
    height: 100%;
    width: 500px;
    background-color: #fff; }

  .employeesItem {
    width: auto !important; }

  .employeesListWrapper .employeesList {
    padding: 10px 0 0 10px; } }

body[data-theme='dark'] {
  .employeesList {
    background-color: var(--dt-second-background-color); } }


