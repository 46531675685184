body[data-theme='light'] .qs-table,
body[data-theme='dark'] .qs-table {
  width: 100%;
  max-width: 100%;
  margin: 15px 0;
  border-collapse: collapse;
  position: relative;
  color: #333; }
  body[data-theme='light'] .qs-table--hidden,
  body[data-theme='dark'] .qs-table--hidden {
    display: none; }
  body[data-theme='light'] .qs-table--hidden,
  body[data-theme='dark'] .qs-table--hidden {
    display: none; }
  body[data-theme='light'] .qs-table--is-loading,
  body[data-theme='dark'] .qs-table--is-loading {
    position: relative;
    overflow: hidden; }
    body[data-theme='light'] .qs-table--is-loading .qs-table__head-preloader:before,
    body[data-theme='dark'] .qs-table--is-loading .qs-table__head-preloader:before {
      opacity: 1;
      animation: preloader-animation 2s infinite ease-in-out; }
    body[data-theme='light'] .qs-table--is-loading .qs-table__head-preloader:after,
    body[data-theme='dark'] .qs-table--is-loading .qs-table__head-preloader:after {
      opacity: 1;
      animation: preloader-animation 3s infinite ease-in-out; }
  body[data-theme='light'] .qs-table--highlighted .qs-table__row--disabled,
  body[data-theme='dark'] .qs-table--highlighted .qs-table__row--disabled {
    background: rgba(0, 0, 0, 0.07);
    cursor: default; }
  body[data-theme='light'] .qs-table--highlighted .qs-table__row:hover,
  body[data-theme='dark'] .qs-table--highlighted .qs-table__row:hover {
    background: rgba(0, 0, 0, 0.05);
    cursor: pointer; }
  body[data-theme='light'] .qs-table__pagination,
  body[data-theme='dark'] .qs-table__pagination {
    margin-top: 2rem;
    margin-bottom: 2rem; }
    body[data-theme='light'] .qs-table__pagination > *,
    body[data-theme='dark'] .qs-table__pagination > * {
      border-radius: 0; }
      body[data-theme='light'] .qs-table__pagination > *:not(:first-child),
      body[data-theme='dark'] .qs-table__pagination > *:not(:first-child) {
        margin-left: -1px; }
      body[data-theme='light'] .qs-table__pagination > *:first-child,
      body[data-theme='dark'] .qs-table__pagination > *:first-child {
        border-radius: 4px 0 0 4px; }
      body[data-theme='light'] .qs-table__pagination > *:last-child,
      body[data-theme='dark'] .qs-table__pagination > *:last-child {
        border-radius: 0 4px 4px 0; }
    body[data-theme='light'] .qs-table__pagination-thumb + .qs-table__pagination-thumb,
    body[data-theme='dark'] .qs-table__pagination-thumb + .qs-table__pagination-thumb {
      display: none; }
  body[data-theme='light'] .qs-table__body-cell,
  body[data-theme='dark'] .qs-table__body-cell {
    min-height: 40px;
    height: 40px;
    background: transparent;
    border: 1px solid #d1d0d0;
    border-top: none;
    border-bottom: none;
    text-overflow: ellipsis;
    padding: 0 10px;
    vertical-align: middle;
    color: var(--font-black); }
    body[data-theme='light'] .qs-table__body-cell-numbers,
    body[data-theme='dark'] .qs-table__body-cell-numbers {
      padding-right: 10px;
      text-align: right; }
    body[data-theme='light'] .qs-table__body-cell-text,
    body[data-theme='dark'] .qs-table__body-cell-text {
      padding-left: 10px;
      text-align: left; }
    body[data-theme='light'] .qs-table__body-cell--with-context-menu,
    body[data-theme='dark'] .qs-table__body-cell--with-context-menu {
      position: relative; }
    body[data-theme='light'] .qs-table__body-cell--disabled,
    body[data-theme='dark'] .qs-table__body-cell--disabled {
      opacity: 0.5; }
    body[data-theme='light'] .qs-table__body-cell--with-context-menu,
    body[data-theme='dark'] .qs-table__body-cell--with-context-menu {
      position: relative; }
    body[data-theme='light'] .qs-table__body-cell--show-button-on-hover button,
    body[data-theme='dark'] .qs-table__body-cell--show-button-on-hover button {
      opacity: 0;
      transition: .25s ease; }
    body[data-theme='light'] .qs-table__body-cell--show-button-on-hover:hover button,
    body[data-theme='dark'] .qs-table__body-cell--show-button-on-hover:hover button {
      opacity: 1; }
  body[data-theme='light'] .qs-table__head,
  body[data-theme='dark'] .qs-table__head {
    width: 100%; }
    body[data-theme='light'] .qs-table__head-preloader,
    body[data-theme='dark'] .qs-table__head-preloader {
      position: absolute;
      width: 100%; }
      body[data-theme='light'] .qs-table__head-preloader:after, body[data-theme='light'] .qs-table__head-preloader:before,
      body[data-theme='dark'] .qs-table__head-preloader:after,
      body[data-theme='dark'] .qs-table__head-preloader:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 6px;
        z-index: 10; }
      body[data-theme='light'] .qs-table__head-preloader:before,
      body[data-theme='dark'] .qs-table__head-preloader:before {
        background-color: #d6dadd; }
      body[data-theme='light'] .qs-table__head-preloader:after,
      body[data-theme='dark'] .qs-table__head-preloader:after {
        background-color: #68747f; }
    body[data-theme='light'] .qs-table__head-cell,
    body[data-theme='dark'] .qs-table__head-cell {
      background: #f5f5f5;
      color: #909090;
      height: 65px;
      border: 1px solid #d1d0d0;
      border-bottom: 6px solid #9ea7af;
      font-weight: normal;
      padding: 10px;
      vertical-align: middle;
      cursor: default;
      position: relative; }
      body[data-theme='light'] .qs-table__head-cell-content,
      body[data-theme='dark'] .qs-table__head-cell-content {
        display: flex;
        align-items: center; }
      body[data-theme='light'] .qs-table__head-cell-numbers,
      body[data-theme='dark'] .qs-table__head-cell-numbers {
        text-align: right; }
        body[data-theme='light'] .qs-table__head-cell-numbers .qs-table__head-cell-content,
        body[data-theme='dark'] .qs-table__head-cell-numbers .qs-table__head-cell-content {
          justify-content: flex-end; }
      body[data-theme='light'] .qs-table__head-cell-text,
      body[data-theme='dark'] .qs-table__head-cell-text {
        text-align: left; }
        body[data-theme='light'] .qs-table__head-cell-text .qs-table__head-cell-content,
        body[data-theme='dark'] .qs-table__head-cell-text .qs-table__head-cell-content {
          justify-content: space-between; }
      body[data-theme='light'] .qs-table__head-cell--sortable,
      body[data-theme='dark'] .qs-table__head-cell--sortable {
        cursor: pointer; }
      body[data-theme='light'] .qs-table__head-cell--selected,
      body[data-theme='dark'] .qs-table__head-cell--selected {
        color: #000000; }
  body[data-theme='light'] .qs-table__row,
  body[data-theme='dark'] .qs-table__row {
    width: 100%;
    border: 1px solid #d1d0d0;
    border-right: none;
    border-left: none; }
    body[data-theme='light'] .qs-table__row:hover .table-cell-context-menu__wrapper,
    body[data-theme='dark'] .qs-table__row:hover .table-cell-context-menu__wrapper {
      opacity: 1; }
    body[data-theme='light'] .qs-table__row--disabled,
    body[data-theme='dark'] .qs-table__row--disabled {
      opacity: 0.5; }
    body[data-theme='light'] .qs-table__row--one-line .qs-table__cell:first-child,
    body[data-theme='dark'] .qs-table__row--one-line .qs-table__cell:first-child {
      border: none;
      border-left: 1px solid #d1d0d0; }
    body[data-theme='light'] .qs-table__row--one-line .qs-table__cell:last-child,
    body[data-theme='dark'] .qs-table__row--one-line .qs-table__cell:last-child {
      border: none;
      border-right: 1px solid #d1d0d0; }
    body[data-theme='light'] .qs-table__row--selectable,
    body[data-theme='dark'] .qs-table__row--selectable {
      cursor: pointer; }
    body[data-theme='light'] .qs-table__row--unclickable,
    body[data-theme='dark'] .qs-table__row--unclickable {
      color: inherit; }
  body[data-theme='light'] .qs-table__sort-wrapper,
  body[data-theme='dark'] .qs-table__sort-wrapper {
    display: inline-block;
    margin: 0; }
  body[data-theme='light'] .qs-table__sort-arrow,
  body[data-theme='dark'] .qs-table__sort-arrow {
    display: block;
    margin-left: 5px;
    width: 0;
    height: 0;
    border: 5px solid transparent; }
    body[data-theme='light'] .qs-table__sort-arrow--ascending,
    body[data-theme='dark'] .qs-table__sort-arrow--ascending {
      margin-bottom: 3px;
      border-bottom-color: #909090; }
      body[data-theme='light'] .qs-table__sort-arrow--ascending-selected,
      body[data-theme='dark'] .qs-table__sort-arrow--ascending-selected {
        border-bottom-color: #000000; }
    body[data-theme='light'] .qs-table__sort-arrow--descending,
    body[data-theme='dark'] .qs-table__sort-arrow--descending {
      border-top-color: #909090; }
      body[data-theme='light'] .qs-table__sort-arrow--descending-selected,
      body[data-theme='dark'] .qs-table__sort-arrow--descending-selected {
        border-top-color: #000000; }

body[data-theme='light'] .table-cell-context-menu__wrapper,
body[data-theme='dark'] .table-cell-context-menu__wrapper {
  position: absolute;
  right: 5px;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  opacity: 0;
  transition: .25s ease; }
  body[data-theme='light'] .table-cell-context-menu__wrapper--context-menu-is-showed,
  body[data-theme='dark'] .table-cell-context-menu__wrapper--context-menu-is-showed {
    opacity: 1; }
    body[data-theme='light'] .table-cell-context-menu__wrapper--context-menu-is-showed .table-cell-context-menu,
    body[data-theme='dark'] .table-cell-context-menu__wrapper--context-menu-is-showed .table-cell-context-menu {
      opacity: 1;
      pointer-events: all;
      top: 100%; }

body[data-theme='light'] .table-cell-context-menu__trigger,
body[data-theme='dark'] .table-cell-context-menu__trigger {
  border: none;
  background: none;
  cursor: pointer;
  box-shadow: none;
  border-radius: 4px; }
  body[data-theme='light'] .table-cell-context-menu__trigger svg,
  body[data-theme='dark'] .table-cell-context-menu__trigger svg {
    opacity: .7; }
  body[data-theme='light'] .table-cell-context-menu__trigger:hover,
  body[data-theme='dark'] .table-cell-context-menu__trigger:hover {
    background-color: rgba(102, 175, 233, 0.25); }

body[data-theme='light'] .table-cell-context-menu,
body[data-theme='dark'] .table-cell-context-menu {
  position: absolute;
  left: 50%;
  top: calc(100% - 20px);
  transform: translate(-50%, 0);
  background-color: white;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.25);
  z-index: 2;
  padding: 5px 0;
  min-width: 100px;
  font-size: 14px;
  border-radius: 4px;
  opacity: 0;
  pointer-events: none;
  transition: .25s ease; }
  body[data-theme='light'] .table-cell-context-menu:after,
  body[data-theme='dark'] .table-cell-context-menu:after {
    content: '';
    position: absolute;
    left: 50%;
    top: -5px;
    transform: translateX(-50%);
    border-bottom: 10px solid white;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    z-index: 1; }

body[data-theme='light'] .table-cell-context-menu__element,
body[data-theme='dark'] .table-cell-context-menu__element {
  padding: 5px 21px;
  transition: .25s ease;
  white-space: nowrap; }
  body[data-theme='light'] .table-cell-context-menu__element:hover,
  body[data-theme='dark'] .table-cell-context-menu__element:hover {
    background-color: #eee; }

@keyframes cell-animation {
  from {
    left: -100%; }
  to {
    left: 150%; } }

@keyframes preloader-animation {
  from {
    width: 0;
    left: 0; }
  33.333% {
    width: 100%;
    left: 50%; }
  to {
    width: 100%;
    left: 100%; } }

body[data-theme='dark'] .qs-table__row {
  border-color: #9BA3AC33; }

body[data-theme='dark'] .qs-table__body-cell {
  background-color: #232E39;
  border-color: #9BA3AC33; }

body[data-theme='dark'] .qs-table__head-cell {
  border-color: #9BA3AC33;
  background-color: var(--dt-second-background-color); }

body[data-theme='dark'] .qs-table__head-cell--selected {
  color: var(--dt-qs-gray); }

body[data-theme='dark'] .qs-table__sort-arrow--ascending-selected {
  border-bottom-color: var(--primary-color); }

body[data-theme='dark'] .qs-table__sort-arrow--descending-selected {
  border-top-color: var(--primary-color); }
