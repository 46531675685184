@import '../../Month/helpers/styles.module.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .day {
    height: calc(#{$cellWidth - 1px});
    width: calc(#{$cellWidth - 1px});
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    background-color: white !important;
    border: none;


    &.DAY_OFF,
    &.HOLIDAY,
    &.SUGGESTED_HOLIDAY {
      color: var(--primary-color); }

    &.REGULAR {
      color: var(--font-black); }

    &.fake {
      background-color: #F8F9FA !important;

      .label {
        opacity: .5; }

      &.interactive:after {
        background-color: white; } }

    &.disabled {
      background: transparent !important;
      cursor: not-allowed;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }

    &.interactive {
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 0;
        height: 0;
        z-index: 0;
        background-color: #f4f4f4;
        border-radius: 50%;
        transition: .25s ease; }

      &:hover::after {
        width: 200%;
        height: 200%; } } }

  .label {
    position: relative;
    z-index: 1; } }

body[data-theme='dark'] {
  .day {
    background-color: var(--dt-qs-dark) !important;

    &.interactive {
      &:after {
        background-color: var(--dt-qs-gray-20); } }

    &.fake {
      background-color: var(--dt-qs-dark) !important;

      &.interactive:after {
        background-color: var(--dt-qs-gray-20); } } } }
