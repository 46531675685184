body[data-theme='light'],
body[data-theme='dark'] {
  .monthInfoBodyWrapper {
    display: flex;
    flex-direction: column;
    gap: 24px; }

  .monthInfoSelectWrapper {
    width: 100%; }

  .monthInfoSelect {
    width: 100%; }

  .replaceActivityAlert {
    padding: 24px;
    background-color: #F4536E1A;
    color: var(--primary-color);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    gap: 8px;

    font-size: 15px;
    line-height: 18px;
    border-radius: 5px; }

  .replaceActivityAlertIcon {
    fill: var(--primary-color); } }
