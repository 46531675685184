body[data-theme='light'],
body[data-theme='dark'] {
  .pagesSidebar {
    left: var(--menu-width-thick);
    position: fixed;
    z-index: 16;
    height: 100%;
    width: var(--sidebar-width);
    background-color: #fff;
    border-right: 1px solid var(--background-gray);
    box-shadow: 10px 0 15px 0 rgba(0, 0, 0, 0.02); }

  .collapsedPagesSidebar {
    width: var(--sidebar-collapsed);

    .sidebarHeader,
    .sidebarList {
      opacity: 0;
      visibility: hidden; } }

  .hiddenPagesSidebar {
    opacity: 0;
    visibility: hidden; } }

body[data-theme='dark'] {
  .pagesSidebar {
    width: calc(var(--sidebar-width) + 2px);
    background-color: var(--dt-second-background-color);
    border-right: 2px solid var(--dt-qs-gray-20); }

  .collapsedPagesSidebar {
    width: calc(var(--sidebar-collapsed) + 2px); } }
