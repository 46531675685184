body[data-theme='light'],
body[data-theme='dark'] {
  .partnerListItem {
    padding-left: 20px;
    padding-right: 10px;
    cursor: pointer;
    transition: 0.3s ease;
    color: var(--font-black);
    user-select: none;

    &:last-of-type {
      border-bottom: 1px solid var(--gray-4) !important; }

    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);

      .partnerListItemDescription {
        color: var(--primary-color); }

      .removePartnerButton {
        opacity: 1;
        visibility: visible; } }

     :global {
      .ant-list-item-meta-avatar {
        margin-right: 6px; } } }

  .editPartnerListItem {
    cursor: grab; }

  .activePartnerListItem {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);

    .partnerListItemDescription {
      color: var(--primary-color); } }

  .dragIndicator {
    fill: var(--qs-gray); }

  .partnerLoadingIcon {
    margin-left: 50px; }

  .removePartnerButton {
    line-height: 0;
    opacity: 0;
    visibility: hidden; }

  .removePartnerIcon {
    &:hover {
      fill: var(--primary-color); } }

  .partnerListItemMeta {
    display: flex;
    align-items: center; }

  .partnerListItemDescription {
    color: var(--font-black);
    font-size: 12px;
    line-height: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center; }

  .descriptionTextWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.3s ease; }

  .descriptionText {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  .chevronPartnerButton {
    line-height: 0;
    padding: 4px 6px;
    color: var(--qs-gray);
    margin-right: 5px;

    &:active,
    &:hover,
    &:focus {
      outline: none;
      background-color: transparent;
      color: var(--qs-gray); } }

  .emptyActivityOption {
    padding: 16px; } }

body[data-theme='light'] {
  .activePartnerListItem {
    background-color: #fff; }

  .editPartnerListItem {
    background-color: var(--tracker-gray); }

  .partnerListItem {
    &:hover {
      background-color: #fff; } } }

body[data-theme='dark'] {
  .partnerListItem {
    &:last-of-type {
      border-bottom: 1px solid var(--dt-qs-gray-20) !important; } } }
