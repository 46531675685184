body[data-theme='light'],
body[data-theme='dark'] {
  .selectionCountButton {
    margin-left: 8px;
    width: 38px;
    height: 40px;
    border-radius: 5px;

    &.open {
      background-color: var(--primary-color);
      border-radius: 5px 5px 0 0;
      color: #fff;

      &:hover {
        background-color: darken(#F4536E, 5%); } } }

  .selectionCountPopover {
     :global {
      .ant-popover-arrow {
        display: none; } } }

  .selectionPopover {
    width: 369px; }

  .selectionPopoverBody {
    max-height: 240px;
    overflow-y: auto; }

  .selectionPopoverHeader {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    padding: 0 0 8px 0;
    color: var(--qs-gray);

    .clearButton {
      cursor: pointer;
      color: var(--qs-gray);
      font-size: 13px;
      font-weight: 500;
      line-height: 15px;

      .clearButtonWrapper {
        display: flex;
        align-items: center;
        gap: 4px; }

      .clearWrapperIcon {
        fill: var(--qs-gray); }

      &:hover {
        color: darken(#727981, 15%);

        .clearWrapperIcon {
          fill: darken(#727981, 15%); } } } } }

body[data-theme='light'] {
  .selectionCountButton {
    background-color: #E5EAEE;

    &:hover {
      background-color: darken(#E5EAEE, 5%); } } }

body[data-theme='dark'] {
  .selectionPopoverItem {
    border-color: var(--dt-qs-gray-20); }

  .selectionCountButton {
    background-color: var(--dt-qs-gray-20);

    &:hover {
      background-color: var(--primary-color); } } }
