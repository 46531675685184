@import './base64EnglishIcon.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .skillset_wrapper {
    position: relative;
    padding: 24px 34px 34px 34px;
    min-width: 1200px;

    &--isLoading {
      img {
        opacity: 0; }

      .skillset_english-knowledge-icon {
        background-color: var(--break-gray); }

       :global {
        .preloaded-gray-box {
          background-color: var(--break-gray) !important;
          border: none !important;

          span:before {
            display: none; } }

        .ant-btn,
        .ant-btn div {
          background-color: var(--break-gray);
          color: var(--break-gray);
          transition: none;
          text-shadow: none;
          pointer-events: none; }

        .ant-select-selector {
          span {
            &:before {
              content: none; }
            & > span {
              background-color: var(--break-gray) !important; } } } }

      h5,
      h4,
      h3,
      p,
      span,
      button {
        position: relative;
        color: transparent;
        overflow: hidden;
        border-color: transparent;
        pointer-events: none;

        &:before {
          content: '';
          background-color: var(--break-gray);
          border-radius: .25rem;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          min-height: 2rem; } } } }

  .skillset {
    &_skills-section {
      display: flex;
      flex-direction: column;
      gap: 34px; }

    &_category {
      position: relative;
      // transition: .25s ease

      &::before,
      &::after {
        content: '';
        position: absolute;
        left: -39px; // qs-profile-page__tab-content padding-left
        height: 0;
        width: calc(100% + 73px); // qs-profile-page__tab-content padding-left and right
 }        // transition: .25s ease

      &::before {
        top: 0;
        background: linear-gradient(0deg, #000000 -310.34%, rgba(0, 0, 0, 0) 25%); }

      &::after {
        bottom: 0;
        background: linear-gradient(#000000 -310.34%, rgba(0, 0, 0, 0) 25%); }


      .no-skills-wrapper {

        &_label {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 15px;
          line-height: 14px;
          color: var(--qs-gray); } }

      &--is-editing-now {
        padding-top: 18px;

        &::before {
          top: -77px;
          height: 77px; }

        &::after {
          bottom: -77px;
          height: 77px; }

        .skillset_category-body {
          padding-bottom: 1px; }

        .skillset_category-body-row {
          border: 0;
          margin-bottom: 21px;

          & + .skillset_category-body-row {
            margin: 14px 0 0; } }

        .skillset_category-more-desc {
          color: transparent;
          font-size: 0;
          pointer-events: none; }

        .skillset_experience--left-column {
          width: 0;
          max-width: 0;
          min-width: 0;
          padding: 0; }

        .skillset_category-sum,
        .skillset_category-desc {
          font-size: 0;
          color: transparent; }

        .skillset_category-isolated-buttons {
          width: 0;
          min-width: 0;
          max-width: 0;
          opacity: 0;

          button {
            pointer-events: none; } }

        .skillset_category-editing-buttons {
          width: auto;
          display: flex;
          min-width: auto;
          max-width: 100%;
          opacity: 1;

          button {
            pointer-events: all; } } }

      &--is-isolated-now {
        opacity: .3;
        pointer-events: none; }

      &--is-empty {
        display: flex;
        align-items: center;
        gap: 8px;

        .skillset_category-header {
          margin: 0; } }

      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px; }

      &-more-desc,
      &-hide {
        transition: .25s ease;
        border: 0;
        padding: 0;
        background: none;
        box-shadow: none;
        cursor: pointer; }

      &-hide {
        font-size: 12px;
        color: var(--primary-color);
        font-weight: 600;
        font-family: Gilroy;
        margin-top: 5px;
        text-align: left; }

      &-title {
        margin: 0;
        font-family: Gilroy;
        font-size: 20px;
        font-weight: 400;
        line-height: .86;
        display: block; }

      &-sum {
        @extend .skillset_category-title;

        margin-bottom: 12px;
        transition: .25s ease; }

      &-body-row {
        border-top: 1px solid var(--grey-background-break-gray);
        border-bottom: 1px solid var(--grey-background-break-gray);
        display: flex;
        flex-direction: row;

        &--not-in-use {
          border-top: 0; }

        &--hidden {
          height: 0;
          border: 0;
          overflow: hidden;
          pointer-events: none; } }

      &-desc,
      &-more-desc {
        color: var(--icon-gray);
        font-family: Gilroy;
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        margin: 0; }

      &-desc {
        transition: .25s ease; }

      &-more-desc {
        margin-top: 14px; }

      &-buttons {
        display: flex;
        align-items: center; }

      &-isolated-buttons {
        overflow: hidden;
        transition: .25s ease; }

      &-editing-buttons button + button {
        margin-left: 8px; }

      &-error {
        color: var(--primary-color); } }

    &_experience {
      position: relative;
      padding: 14px 13px 15px;
      flex: 2 2 25%;
      max-width: 25%;
      min-width: 25%;
      display: flex;
      flex-direction: column;

      &-english {
        flex: 2 2 20%;
        max-width: 20%;
        min-width: 20%; }

      &-header {
        position: relative;
        height: 14px;

        &-row {
          display: flex;
          align-items: center;
          justify-content: space-between; } }

      &-body {
        margin: 11px -5px -5px;
        flex-grow: 2;

        &-inner {
          border-radius: 5px;
          padding: 0 !important;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          &--is-dragging-over {
            background-color: #E8EDF1; } } }

      &-title {
        @extend .skillset_category-desc; }

      &-row-desc {
        color: var(--icon-gray);
        opacity: .5;
        font-family: Gilroy;
        font-size: 10px;
        line-height: .86px;
        font-weight: 700;
        transition: .25s ease; }

      &-desc {
        font-size: 0;
        color: transparent; }

      &-english {
        &:not(:first-of-type) {
          border-left: 1px solid var(--grey-background-break-gray); }

        &:not(.skillset_experience-english--active) {
          cursor: pointer; }

        &--active {
          .skillset_english-knowledge-icon {
            background-color: #263341;
            background-size: 26px; } } }

      &--editing-in-process {}


      &--left-column {
        padding-left: 0;
        min-width: 110px;
        max-width: 110px; } } }

  .skillset_english-knowledge {
    &-description {
      @extend .skillset_category-desc; }

    &-title {
      @extend .skillset_category-title;

      margin-bottom: 8px;
      transition: .25s ease; }

    &-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 30px;
      border-radius: 15px;
      box-sizing: border-box;
      border: 1px solid rgba(#727981, .3);
      margin-bottom: 16px; } }

  .skillset_language-section {
    margin-top: 34px; }

  .skillset_users-skill-wrapper {
    margin: 5px;
    display: inline-block;
    border-radius: 15px;
    outline: none;
 }    // transition: .25s ease

  .edit-button {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: var(--primary-color);
    padding-left: 0;

    &:focus {
      background-color: transparent; }

    &:hover {
      color: var(--primary-color); } }

  .category-row {
    border-top: 1px solid var(--grey-background-break-gray);
    border-bottom: 1px solid var(--grey-background-break-gray);

    &.edit {
      border-top: none;
      border-bottom: none;
      padding-bottom: 21px; } }

  .category-skills-row {
    height: 100%; }

  .category-left-col {
    padding: 9px 9px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.edit {
      display: none; }

    &_sum {
      display: block;
      margin-bottom: 12px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: var(--font-black); }

    &_desc {
      display: inline-block;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: var(--qs-gray); } }

  .category-use-row {
    border-bottom: 1px solid var(--grey-background-break-gray);

    &.edit {
      border-bottom: none; } }

  .category-use-col {
    border-left: 1px solid var(--grey-background-break-gray);
    padding: 14px 13px;
    display: block;

    &.edit {
      display: none; }

    .skillset_category-desc {
      padding-bottom: 16px; } }

  .dndHover {
    border-radius: 4px;
    background-color: rgba(#D1DBE3, .3); }

  .currently-in-use-section {
    min-height: 40px;

    .skill-element_users-skill--currently-in-use {
      opacity: 1; } }

  .skillset-categories {
    min-height: 112px;
    margin-top: 32px;
    flex-wrap: nowrap;

    &.edited {
      opacity: 0.3;
      pointer-events: none; }

    &_preloader {
       :global {
        .ant-skeleton-content .ant-skeleton-paragraph > li {
          background-color: var(--break-gray); } } }

    &_item-wrapper {
      transition: all 0.3s ease;
      position: relative;
      bottom: 0; }

    &_text {
      width: 90px; }

    &_title {
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 14px;

      max-width: 106px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 14px;
      color: var(--qs-gray); }

    &_layout {
      display: flex;
      flex-wrap: wrap;
      gap: 20px; }

    &_item-title {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 21px;
      color: var(--font-black); }

    &_add-button {
      transition: all 0.3s ease;
      position: absolute;
      bottom: 14px;
      left: 20px;
      visibility: hidden;
      opacity: 0; }

    &_item {
      min-height: 112px;
      position: relative;
      transition: all 0.3s ease;
      width: 215px;
      padding: 18px;
      border: 1px solid #D9E2EC;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 21px;

      &:hover {
        border: 1px solid #fff;
        background-color: #fff;

        .skillset-categories_item-wrapper {
          bottom: 20px; }

        .skillset-categories_add-button {
          opacity: 1;
          visibility: visible; } } } } }

body[data-theme='light'] {
  .skillset_experience-english {
    &:not(.skillset_experience-english--active) {
      cursor: pointer;
      .skillset_english-knowledge-title,
      .skillset_english-knowledge-description {
        color: rgba(#333, .5); } } } }


body[data-theme='dark'] {
  .skillset_wrapper {
    &--isLoading {
      h5,
      h4,
      h3,
      p,
      span,
      button {
        &:before {
          background-color: var(--dt-qs-gray-20); } }

      .skillset_english-knowledge-icon {
        background-color: var(--dt-qs-gray-20) !important; }

       :global {
        .ant-skeleton-content .ant-skeleton-title {
          background-color: var(--dt-qs-gray-20); }
        .preloaded-gray-box {
          background-color: var(--dt-qs-gray-20); }

        .ant-btn,
        .ant-btn div {
          background-color: var(--dt-qs-gray-20);
          color: transparent; }

        .ant-select-selector {
          span {
            &:before {
              content: none; }
            & > span {
              background-color: var(--dt-qs-gray-20) !important; } } } } } }

  .category-row {
    border-top: 1px solid var(--dt-qs-gray-20);
    border-bottom: 1px solid var(--dt-qs-gray-20); }

  .skillset-categories {
    &_preloader {
         :global {
          .ant-skeleton-content .ant-skeleton-paragraph > li {
            background-color: var(--dt-qs-gray-20); } } } }

  .skillset-categories_add-button {
    background-color: var(--dt-qs-gray-20); }

  .skillset-categories_item {
    border: 1px solid var(--dt-qs-gray-20);

    &:hover {
      border: 1px solid var(--border-gray-30);
      background-color: transparent; } } }


