@import '../../../../../helpers/styles.module.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .cardButton {
    & + .cardButton {
      margin-left: 10px; } }

  .find-specialists-table {
    padding-left: 22px;
    width: 100%;

     :global {
      .ant-table-row {
        cursor: pointer; } }

    .name-cell {
      display: flex;
      align-items: center; }

    .author-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;

      &--qs {
        width: 28px;
        height: 28px;
        border-radius: 0; } }

    &_total {
      @extend .specialists_table_total; }

    &_table {
      @extend .specialists_table;
      width: 100%;

      height: 300px; } } }


