body[data-theme='light'],
body[data-theme='dark'] {
  .deleteModal {
     :global {
      .ant-modal-footer {
        display: flex;
        align-items: center; }

      .ant-modal-body {
        padding: 0; }

      .ant-modal-close {
        display: none; }

      .ant-modal-header {
        padding: 25px 0 25px 20px;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid rgba(114, 121, 129, 0.1);

        .ant-modal-title {
          font-size: 20px;
          font-weight: normal;
          line-height: 23px; } }

      .ant-modal-body {
        min-height: 136px; } } }

  .checkbox {
    flex-grow: 2; }

  .okButton {
    background-color: #F4536E; }

  .modalText {
    font-size: 15px;
    padding: 38px 22px 42px;
    color: var(--font-black); }

  .content {
    padding: 30px 20px;

    ul {
      padding: 0; }

    li {
      list-style: none;
      margin-left: 5px;
      margin-top: 5px;
      display: flex;
      align-items: center;

      .dot {
        color: var(--primary-color);
        margin-right: 5px; } } } }
