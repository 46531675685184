body[data-theme='light'],
body[data-theme='dark'] {
  .tableHighlightWrapper {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 11;
    opacity: .1;
    pointer-events: none;
    overflow: hidden;

    .tableHighlightColumn,
    .tableHighlightRow {
      position: absolute; }

    .tableHighlightColumn {
      height: 100%;
      bottom: 0; }

    .tableHighlightRow {
      width: 100%;
      left: 0; } } }

body[data-theme='light'] {
  .tableHighlightColumn,
  .tableHighlightRow {
    background-color: black; } }

body[data-theme='dark'] {
  .tableHighlightColumn,
  .tableHighlightRow {
    background-color: white; } }
