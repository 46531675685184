@import '../../../../../../../helpers/styles.module.sass';

body[data-theme='light'],
body[data-theme='dark'] {

  .request-detail-table {
    @extend .specialists_table;

    padding-left: 22px;
    width: 100%;

    &_table.no-data {
       :global {
        .ant-table-cell-fix-left-last {
          &:after {
            background: none;
            width: 30px; } } } }

     :global {
      .ant-table-cell-fix-left-last {
        &:after {
          background: linear-gradient(90deg, #000000 -310.34%, rgba(0, 0, 0, 0) 25%);
          width: 278px; } } }

    .star-select {
      color: #D9DFE6;
      transition: hover 0.25s ease;
      cursor: pointer;

      &--loading {
        color: #F4536E; }

      &:hover {
        color: #F4536E; } }

     :global {
      .ant-table-row {
        cursor: pointer; } }

    .name-cell {
      display: flex;
      align-items: center; }

    .author-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;

      &--qs {
        width: 28px;
        height: 28px;
        border-radius: 0; } }

    &_total {
      @extend .specialists_table_total; } } }


