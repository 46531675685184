body[data-theme='light'],
body[data-theme='dark'] {
  .fieldTitle {
    font-size: 13px;
    line-height: 15px;
    color: #727981;
    margin-top: 0;
    font-weight: 400;
    margin-bottom: 12px;
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .fieldInner {
    display: flex;
    gap: 10px;
    align-items: center; }

  .colorPicker {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    cursor: pointer; }

  .fieldDescription {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4; }

  .primaryColorPicker {
    width: 32px;
    height: 32px;
    margin-top: 1px;
    background: #eee;
    border-radius: 50%;
    cursor: pointer;

    &.disabled {
      cursor: not-allowed; } }

  .appearanceCard {
    position: relative; }

  .withVerticalSeparator {
    position: static;

    .fieldTitle,
    .fieldInner {
      padding-left: 20px; }

    &::before {
      content: '';
      position: absolute;
      border-right: 1px solid #727981;
      opacity: 0.1;
      height: 113px;
      top: 0; } }

  .partnerMaskPicker {
    display: flex;
    align-items: center;
    border: none;
    padding: 0;
    gap: 7px;
    border-radius: 5px;
    height: 40px;
    width: 40px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    &.editable {
      width: 70px;

      .arrowIcon {
        opacity: 1; } } }

  .partnerMaskWrapper {
    border-radius: 5px;
    height: 40px;
    width: 40px;
    min-width: 40px; }

  .randomizeButton {
    color: var(--primary-color);
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    font-size: 12px;
    padding: 2px 14px;
    gap: 4px;
    border-radius: 5px;
    box-shadow: none;
    transition: .25s ease;

    &:hover {
      background-color: var(--primary-color);
      color: white;

      svg {
        fill: white; } }

    svg {
      color: var(--primary-color);
      transition: .25s ease;
      width: 15px; } }

  .partnerMask {
    min-width: 24px;
    min-height: 24px; }

  .arrowIcon {
    opacity: 0;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    fill: var(--primary-color); } }

body[data-theme='light'] {
  .randomizeButton {
    background-color: #eee; } }

body[data-theme='dark'] {
  .fieldDescription {
    color: white; }

  .randomizeButton {
    background-color: #334151; } }
