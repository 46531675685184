body[data-theme='light'],
body[data-theme='dark'] {
  .pvfWrapper {
    border-bottom: 1px solid rgba(#D9E3EC, .3);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 12px;
    transition: visibility 0s, opacity 0.2s ease, margin 0.3s ease, width 0.3s ease; }

  .pvfNameTitle {
    text-align: center;
    margin-top: 15px;
    font-size: 20px;
    margin-bottom: 0;
    font-family: 'Gilroy';
    font-weight: 600;
    text-overflow: ellipsis;
    max-width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden; }

  .pvfCustomTitle {
    font-size: 13px;
    color: var(--qs-gray); } }

body[data-theme='dark'] {
  .pvfWrapper {
    border-color: var(--dt-qs-gray-20); }
  .pvfCustomTitle {
    color: var(--dt-qs-gray); } }
