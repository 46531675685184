body[data-theme='light'] .qs-button,
body[data-theme='dark'] .qs-button {
  outline: none;
  display: inline-block;
  padding: .42em .85em;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  transition: .25s ease; }
  body[data-theme='light'] .qs-button--inner-wrapper,
  body[data-theme='dark'] .qs-button--inner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center; }
  body[data-theme='light'] .qs-button--preloader,
  body[data-theme='dark'] .qs-button--preloader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(0);
    transition: .25s ease; }
  body[data-theme='light'] .qs-button--loading .qs-button--inner-wrapper,
  body[data-theme='dark'] .qs-button--loading .qs-button--inner-wrapper {
    transform: translateY(2.27em); }
  body[data-theme='light'] .qs-button--default,
  body[data-theme='dark'] .qs-button--default {
    color: #333;
    background-color: #fff;
    border-color: #ccc; }
    body[data-theme='light'] .qs-button--default:not(.qs-button--disabled):hover,
    body[data-theme='dark'] .qs-button--default:not(.qs-button--disabled):hover {
      background-color: #eee;
      color: #262626; }
    body[data-theme='light'] .qs-button--default:active,
    body[data-theme='dark'] .qs-button--default:active {
      background-color: #cacaca; }
  body[data-theme='light'] .qs-button--primary,
  body[data-theme='dark'] .qs-button--primary {
    color: white;
    background-color: var(--primary-color);
    border-color: var(--primary-color); }
    body[data-theme='light'] .qs-button--primary:not(.qs-button--disabled):hover,
    body[data-theme='dark'] .qs-button--primary:not(.qs-button--disabled):hover {
      background-color: #af394d;
      border-color: #af394d;
      color: white; }
    body[data-theme='light'] .qs-button--primary:active,
    body[data-theme='dark'] .qs-button--primary:active {
      background-color: #af394d;
      border-color: #af394d; }
  body[data-theme='light'] .qs-button--success,
  body[data-theme='dark'] .qs-button--success {
    color: white;
    background-color: #339933;
    border-color: #339933; }
    body[data-theme='light'] .qs-button--success:not(.qs-button--disabled):hover,
    body[data-theme='dark'] .qs-button--success:not(.qs-button--disabled):hover {
      background-color: #247024;
      border-color: #247024;
      color: white; }
    body[data-theme='light'] .qs-button--success:active,
    body[data-theme='dark'] .qs-button--success:active {
      background-color: #247024;
      border-color: #247024; }
  body[data-theme='light'] .qs-button--disabled,
  body[data-theme='dark'] .qs-button--disabled {
    opacity: .5;
    cursor: no-drop; }
  body[data-theme='light'] .qs-button--size-bg,
  body[data-theme='dark'] .qs-button--size-bg {
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 5px; }
  body[data-theme='light'] .qs-button--size-sm,
  body[data-theme='dark'] .qs-button--size-sm {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px; }
    body[data-theme='light'] .qs-button--size-sm svg,
    body[data-theme='dark'] .qs-button--size-sm svg {
      font-size: 1.13em; }
  body[data-theme='light'] .qs-button:focus,
  body[data-theme='dark'] .qs-button:focus {
    border-color: var(--blue);
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  body[data-theme='light'] .qs-button:not(.qs-button--disabled):hover,
  body[data-theme='dark'] .qs-button:not(.qs-button--disabled):hover {
    text-decoration: none;
    opacity: 1; }

body[data-theme='dark'] .qs-button {
  border-color: var(--dt-qs-gray-20);
  color: #fff;
  background-color: #232E39; }
  body[data-theme='dark'] .qs-button:not(.qs-button--disabled):hover {
    background-color: var(--primary-color);
    color: #fff; }

body[data-theme='dark'] .qs-button--primary {
  background-color: var(--primary-color); }
