body[data-theme='light'],
body[data-theme='dark'] {
  .selectionBoxWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &.disabled {
      pointer-events: none; } }

  .popoverOverlay {}

  .selectionBox {
    position: absolute;
    opacity: 0;
    padding: 1px 1px 1px 2px;

    &.visible {
      opacity: 1; } }

  .selectionBoxInner {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px dashed #23354A;
    overflow: hidden;
    border-radius: calc(100vw / 250);
    background-color: #23354A26;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100px;
      background: linear-gradient(#FFFFFF66, #FFFFFF00); } }

  .selector {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    transition: .25s ease; }

  .hoursMarker {
    font-family: Roboto;
    white-space: nowrap;
    font-weight: 900;
    line-height: 1.7;
    color: rgba(0, 0, 0, .8);
    transition: .25s ease;
    position: relative;
    z-index: 1;
    font-size: 14px;

    &.lessThenHour {
      font-size: 12px; } } }

body[data-theme='dark'] {
  .hoursMarker {
    color: var(--primary-color); }

  .selectionBoxInner {
    border: 1px dashed var(--primary-color);

    &::before {
      background: linear-gradient(#120b0b80, #00000000); } } }
