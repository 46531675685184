body[data-theme='light'],
body[data-theme='dark'] {
  .partnerListWrapper {
    margin: 8px 0 0 0;
    opacity: 1; }

  .collapsedPartnerList {
    display: none; }

  .partnerList {
    padding-top: 4px; }

  .listTitle {
    padding-left: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    opacity: 0.75; }

  .partnerListTitle {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between; } }

body[data-theme='light'] {
  .listTitle {
    color: var(--qs-gray); } }
