body[data-theme='light'],
body[data-theme='dark'] {
  .flexRow {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    span {
      flex-grow: 2; } }

  .partner {
    display: flex;
    flex-direction: row;
    align-items: center; }

  .maskWrapper {
    margin-right: 10px; }

  .user {
    display: flex;
    flex-direction: row;
    align-items: center; }

  .roles {
 }    // text-transform: capitalize

  .threeDotsButton {
    padding: 0;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    transition: .25s ease;
    border-radius: 2px; }

  .employeesTable {
     :global {
      tr {
        cursor: pointer;

        &:hover .three-dots-button {
          opacity: 1; } } } } }
