body[data-theme='light'],
body[data-theme='dark'] {
  .partnerActivityListItem {
    padding-left: 54px;
    height: 48px;
    cursor: pointer;
    position: relative;
    display: flex;
    border: none;
    background-color: none;
    box-shadow: none;
    align-items: center;

    &::after {
      display: none !important; }

    &::before {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc(100% - 54px);
      border-bottom: 1px solid rgba(217, 227, 236, .3); } }

  .partnerMaskWrapper {
    margin-right: 5px; }

  .partnerActivityDescription {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 2;
    margin: 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;


    color: var(--font-black); } }

body[data-theme='dark'] {
  .partnerActivityListItem {
    &::before {
      border-bottom: 1px solid var(--dt-qs-gray-20); }

    &:hover {
      background-color: #222932; } } }
