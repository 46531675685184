@import '../../../../../helpers/tableVariables.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .workedHours {
    position: absolute;
    width: 100%;
    height: 19px;
    top: -19px;
    z-index: 10;
    text-align: center;
    font-size: 12px; }

  .monthMarkerWrapper {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    gap: 5px;
    color: #727981;
    background: white; }


  .workload,
  .monthMarker {
    text-transform: uppercase;
    font-family: Gilroy, Helvetica Neue;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0em;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5; }

  .workload {
    white-space: nowrap;
    font-size: 12px;
    text-transform: lowercase; }

  .column {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, .05);
      pointer-events: none; }

    &:not(.closedMonth):hover {
      &:before {
        opacity: 1§; }

       :global {
        .workedHoursWrapper {
          background-color: #23354A;
          width: calc(100% - 4px);

          span {
            color: white; } } } }

    &.notInteractive {
      pointer-events: none;
      cursor: not-allowed; } }

  @keyframes glitter-1 {
    0% {
      opacity: 0; }

    45% {
      opacity: 0; }

    55% {
      opacity: 1; }

    100% {
      opacity: 1; } }

  @keyframes glitter-2 {
    0% {
      opacity: 1; }

    45% {
      opacity: 1; }

    55% {
      opacity: 0; }

    100% {
      opacity: 0; } }

  .withWorkload {
    position: relative;

    .workload {
      animation: glitter-1 10s ease infinite alternate; }

    .monthMarker {
      animation: glitter-2 10s ease infinite alternate;
      position: absolute;
      left: 50%;
      transform: translateX(-50%); } } }

body[data-theme='dark'] {
  .workedHours {
    color: white;
    background-color: var(--dt-ts-bg-gray); }

  .monthMarkerWrapper {
    color: #bec3c9;
    background: #20272e; }

  .column {
    &:before {
      background-color: rgba(255, 255, 255, .05); } }

  .column:not(.closedMonth):hover {
    &:before {
      opacity: 1; }

     :global {
      .workedHoursWrapper {
        background-color: #9BA3AC;
        width: calc(100% - 4px);

        span {
          color: #1B2228; } } } } }
