.deactivatedLabel {
  height: 24px;
  border-radius: 12px;
  padding: 0px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: white;
  font-size: 14px; }
