body[data-theme='light'],
body[data-theme='dark'] {
  .userHoursListPanel {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative; }

  .preloaderWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

  .listWrapper {
    position: relative;
    flex-grow: 2;
    overflow-y: hidden;
    padding-top: 43px; }

  .addTimeButtonPlaceholder {
    height: 80px; }

  .addTimeButton {
    position: absolute;
    right: 16px;
    bottom: 16px;
    border-radius: 50%;
    box-shadow: none;
    transition: .25s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus,
    &:active {
      border-color: transparent; }

    &:hover,
    &:visited {
      background-color: #3f3f3f;
      border-color: #3f3f3f;
      color: white; }

    &:after,
    &:before {
      content: none; }

    width: 48px;
    height: 48px;
    color: white;
    border-color: black;
    background-color: black;
    transition: .25s ease;

    &.locked {
      transform: scale(0); }

    svg {
      width: 22px;
      height: 22px;
      stroke: white; }

    &:disabled {
      svg {
        stroke: #9BA3AC; } } }

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .25s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; }

  .timesheet {
    display: flex;
    align-items: center;
    flex-direction: row;
    position: relative;
    padding: 10px 0;

    margin: 0;
    width: 100%;
    height: auto;
    border: 1px solid transparent;

    background: none;
    box-shadow: none;
    outline: none;

    &:after {
      content: '';
      position: absolute;
      left: 56px;
      bottom: 0;
      width: calc(100% - 56px);
      opacity: 0.05;
      border-bottom: 1px solid #000000; } }

  .timeRange,
  .hours {
    flex: 1 1 56px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column; }

  .timeRange span {
    display: block;
    text-align: center;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #9BA3AC; }

  .hours {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: right;

    color: #F4536E; }

  .activity {
    flex: 1 1 calc(100% - 112px); }

  .activity,
  .descriptionTextWrapper {
    display: flex;
    align-items: flex-start; }

  .descriptionTextWrapper {
    margin: 0;
    flex-grow: 2;
    justify-content: space-between;
    color: inherit;
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    color: #232E39;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px; }

  .activityIconWrapper {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    margin-right: 6px;
    border-radius: 5px; }

  .commentsList {}

  .comment {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    margin-top: 15px;
    white-space: pre-line;
    text-align: left;

    color: #9BA3AC; }

  .moreCommentsLabel {
    opacity: .6;
    font-size: 12px;
    display: block; }

  .notItemsText {
    padding: 0 20px;
    color: var(--font-black); } }

body[data-theme='dark'] {
  .addTimeButton {
    background-color: var(--dt-gray-button);
    border: 1px solid transparent; }

  .descriptionTextWrapper {
    color: #ffffffD9; }

  .timesheet {
    border-bottom-color: var(--dt-qs-gray-20);
    &:after {
      border-bottom: 1px solid var(--border-gray-30); } } }
