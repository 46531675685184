body[data-theme='light'],
body[data-theme='dark'] {
  .create-request {
    padding-top: 0;

    &_request-button {
      height: 39px;

      &--popover-visible {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0; } } }

  .createRequestSelect {
    width: 369px; } }

