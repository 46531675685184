body[data-theme='light'],
body[data-theme='dark'] {
  .partnerInfoWrapper {
    height: 100%;

     :global {
      .ant-spin {
        max-height: none; } } }

  .partnerIntoContent {
    margin-left: var(--sidebar-width);
    height: 100%; } }
