@import '../../../../../helpers/styles/mixins.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .locationPageWrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    overflow: auto;
    padding: 32px 0 32px 32px;
    width: 620px;
    height: 100%;
    background: no-repeat -20px 140px  url("./../../../../../helpers/images/location-image.svg"), no-repeat center 0  url("./../../../../../helpers/images/location-ripple.svg") var(--white); }

  .trackerLogo {
    position: absolute;
    top: 32px;
    left: 32px; }

  .buttonGroupWrapper {
    display: flex;
    gap: 32px;
    flex-wrap: wrap;
    align-items: center; }

  .locationTitle {
    font-family: Gilroy;
    max-width: 300px;
    line-height: 38px; }

  .primaryText {
    font-family: Gilroy;
    font-size: 15px;
    color: var(--font-black); }

  .textWrapper {
    width: 391px; }

  .allowButton {
    font-size: 15px;
    width: 284px;
    font-weight: bold;
    line-height: 12px;
    justify-content: center; }

  .splitter {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background-color: var(--dt-qs-gray-20); }

  .cancelButton {
    font-weight: bold;
    font-size: 15px;
    line-height: 12px;
    color: var(--primary-color);
    padding-left: 0;
    padding-right: 0;

    &:hover {
      color: red;
      opacity: 0.5;
      background-color: transparent; } }

  @include mq('tablet-wide') {
    .locationPageWrapper {
      background: no-repeat 0 118px / 100%  url("./../../../../../helpers/images/location-image.svg"), no-repeat center -50px url("./../../../../../helpers/images/location-ripple.svg") var(--white);
      padding: 32px;
      width: auto; }

    .locationTitle {
      font-size: 32px; }

    .textWrapper {
      width: auto; }

    .allowButton {
      width: auto;
      flex-grow: 1; } } }

body[data-theme='dark'] {
  .locationPageWrapper {
    background: no-repeat 0 118px / 100%  url("./../../../../../helpers/images/location-image.svg"), no-repeat center -50px url("./../../../../../helpers/images/location-ripple-black.svg") var(--white); }

  .primaryText {
    color: var(--dt-qs-gray); } }
