body[data-theme='light'],
body[data-theme='dark'] {
  .vacationReportSettingsWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; }

  .settingsLeftCol {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap; }

  .vacationSelect {
    width: 100px;

     :global {
      .ant-select-selection-item {
        text-align: center;
        padding-right: 0 !important;
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: var(--font-black); } } }

  .vacationSelect:global(.ant-select-disabled) {
    opacity: 0.3; }

  .disabledYearButton {
    fill: var(--qs-gray);
    opacity: 0.3; }

  .vacationInput {
    width: 400px; } }

body[data-theme='light'] {
  .vacationInput {
    background-color: #fff;

     :global {
      .ant-input {
        background-color: #fff; } } } }
