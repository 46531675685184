@font-face {
  font-family: 'SF Pro Display';
  src: url("SFProDisplay-Regular.woff2") format("woff2");
  src: url("SFProDisplay-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'SF Pro Display';
  src: url("SFProDisplay-Black.woff2") format("woff2");
  src: url("SFProDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("RobotoRegular.eot");
  src: url("RobotoRegular.eot") format("embedded-opentype");
  src: url("RobotoRegular.woff2") format("woff2");
  src: url("RobotoRegular.woff") format("woff");
  src: url("RobotoRegular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("Roboto-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("GilroyBold.eot");
  src: url("GilroyBold.eot") format("embedded-opentype");
  src: url("GilroyBold.woff2") format("woff2");
  src: url("GilroyBold.woff") format("woff");
  src: url("GilroyBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'Gilroy';
  src: url("Gilroy-Regular.eot");
  src: url("Gilroy-Regular.eot") format("embedded-opentype");
  src: url("Gilroy-Regular.woff2") format("woff2");
  src: url("Gilroy-Regular.woff") format("woff");
  src: url("Gilroy-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }
