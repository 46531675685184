body[data-theme='light'],
body[data-theme='dark'] {
  .manage-skill-tags {
    padding-left: 34px;
    padding-right: 24px;
    min-width: 1200px;

     :global {
      .clickable-row {
        cursor: pointer;

        &:hover {
          .action-column-button {
            opacity: 1; } } }

      .ant-table-selection-column {
        cursor: default; } }

    &_one-line-text {
      text-overflow: ellipsis;
      max-width: 300px;
      white-space: nowrap;
      overflow: hidden;
      display: block; }

    &_panel-wrapper {
      box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset;
      top: 0;
      z-index: 10;
      background-color: var(--background-gray);
      position: sticky;
      padding-top: 16px;
      min-height: 58px; }

    .status-icon {
      color: var(--light-green); }

     :global {
      .borderless {
        border-right: 1px solid transparent !important; }

      .action-column {
        padding: 0; }

      .action-column,
      .ant-table-cell-scrollbar {
        box-shadow: none; } } } }
