body,
html,
#app {
  height: 100%;
  min-height: 100%;
  font-family: Roboto;
  background-color: var(--background-gray); }

body[data-theme='dark'] {
  body,
  html,
  #app {
    background-color: var(--primary-backround-black); } }

html {
  font-size: 10px; }

.input-group .input-group-addon + span input.form-control {
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0; }


.btn-corporate {
  background-color: var(--primary-color);
  border-color: #af394d;
  color: white;
  transition: ease .15s;

  &:hover {
    background-color: #af394d;
    color: white; } }

/*
  Base class for Antd Modal.confirm
 */
.qs-confirm-antd-modal {
  font-family: Roboto;
  font-style: normal;
  font-size: 15px;
  line-height: 18px;

  &_cancel-btn {
    padding-left: 21px;
    padding-right: 21px;
    margin-right: 15px;

    font-weight: 500;
    font-size: 15px;
    text-align: center;
    color: #82888F; }

  &_ok-btn {
    width: 170px;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    color: #FFFFFF; }

   :global {
    .ant-modal-body {
      padding: 0; }

    .ant-modal-content {
      border-radius: 5px; }

    .ant-modal-confirm-content {
      margin: 0;
      min-height: 138px;
      padding: 38px 22px 38px 22px;
      border-bottom: 1px solid rgba(#727981, 0.1);
      font-weight: normal;
      font-size: 15px;
      color: #000000; }

    .ant-modal-confirm-btns {
      padding: 10px 15px;
      margin: 0; }

    .ant-modal-confirm-title {
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      padding: 25px 0 25px 20px;
      border-bottom: 1px solid rgba(#727981, 0.1); } } }

/*
  Base class for Antd Modal
 */
.qs-basic-antd-modal {
   :global {
    .ant-modal-content {
      border-radius: 5px; }

    .ant-modal-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #000000; }

    .ant-modal-body {
      padding: 10px 0 10px 22px; }

    .ant-modal-header {
      padding: 25px 20px; } }


  &_cancel-btn {
    padding-left: 21px;
    padding-right: 21px;
    margin-right: 15px;

    font-weight: 500;
    font-size: 15px;
    height: 45px;
    text-align: center;
    color: #82888F; }

  &_ok-btn {
    width: 170px;
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    color: #FFFFFF; } }


.qsBasicModal {
   :global {
    .ant-modal-content {
      border-radius: 5px; }

    .ant-modal-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #000000; }

    .ant-modal-body {
      padding: 10px 0 10px 22px; }

    .ant-modal-header {
      padding: 25px 20px; } }


  &_cancel-btn {
    padding-left: 21px;
    padding-right: 21px;
    margin-right: 15px;

    font-weight: 500;
    font-size: 15px;
    height: 45px;
    text-align: center;
    color: #82888F; }

  &_ok-btn {
    width: 170px;
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    color: #FFFFFF; } }


