@import '../../../helpers/tableVariables.sass';

$closedGradientColor1: lighten(#20272e, 2);
$closedGradientColor2: lighten($tableGray, 5);

body[data-theme='light'],
body[data-theme='dark'] {
  .tableBodyWrapper {
    min-width: $minTableWidth;
    min-height: $minTableHeight;
    position: relative;
    height: 100%;
    display: flex; }

  .tableBody {
    display: flex;
    border-bottom: 1px solid $grayBorderColor;
    flex: 1 1 0px;
    height: 100%;
    background: #F2F4F6;

    &.closedMonth {
      background: linear-gradient(55deg, #f2f4f6 12.50%, rgba(223, 231, 239, 0.25) 12.50%, rgba(223, 231, 239, 0.25) 50%, #f2f4f6 50%, #f2f4f6 62.50%, rgba(223, 231, 239, 0.25) 62.50%, rgba(223, 231, 239, 0.25) 100%) !important;
      background-size: 19.53px 27.90px !important; }

    @media screen and (max-width: 1440px) {
      overflow: hidden; } }

  .tableModalInner {
    // use this inner as container for any popover inside of the table
    position: absolute;
    z-index: 1031; }

  .columnsGroup {
    display: flex;
    position: relative;
    padding-top: 26px;
    border-top: 1px solid #D1DBE380;
    z-index: 0;

    &:hover {
 }      // z-index: 1

    .gradient {
      position: absolute;
      height: 100%;
      top: 0;
      z-index: 11;
      pointer-events: none;
      background: linear-gradient(90deg, rgba(5, 17, 46, 0.05) 0%, rgba(2, 14, 46, 0) 100%);
      left: 0; }

    &.currentMonth {
      .groupMarker {
        &:after {
          content: none; }

        justify-content: center; } }

    &.cutGroup {
      &:before {
        content: '';
        width: 100%;
        height: calc(100% - 100px);
        left: 0;
        top: 100px;
        position: absolute;
        background: linear-gradient(55deg, #f5f3d3 9.38%, #f8f7e0 9.38%, #f8f7e0 50%, #f5f3d3 50%, #f5f3d3 59.38%, #f8f7e0 59.38%, #f8f7e0 100%) !important;
        background-size: 19.53px 27.90px !important;
        pointer-events: none; }

      .groupMarker:after {
        background-color: #eae386; } }

    &.stickToRight {
      z-index: 1;

      .gradient {
        background: linear-gradient(-90deg, rgba(5, 17, 46, 0.05) 0%, rgba(2, 14, 46, 0) 100%);
        right: -1px;
        left: auto; }

      .groupMarker {
        justify-content: flex-end;
        width: calc(100% + 1px); } } }

  .groupMarker {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    height: 26px;
    width: 100%;
    background-color: white;
    z-index: 10;

    .markWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        width: 16px; }

      .monthName {
        text-transform: uppercase;
        font-family: Gilroy, Helvetica Neue;
        font-size: 10px;
        font-style: normal;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0em;
        height: 27px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #727981;
        opacity: 0.5; } }

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 3px;
      background-color: var(--primary-color); } }

  .sideColumnWrapper {
    background-color: white;
    user-select: none;
    width: $sideColumnWidth;
    flex: 0 0 $sideColumnWidth;
    left: 0;
    min-height: $minTableHeight;
    border-bottom: .5px solid $grayBorderColor;
    z-index: 0;

    @media screen and (max-width: 1440px) {
      position: sticky;
      z-index: 2; }

    .sideColumn {
      display: flex;
      flex-direction: column;
      height: 100%; } }

  .sideColumnHeader {
    flex: 0 0 104px;
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .sideColumnMarker {
      height: 28px;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      border-bottom: 1px solid $grayBorderColor;
      border-top: 1px solid $grayBorderColor;

      &.cut:after {
        background-color: #eae386; }

      &.prevMonth:after {
        background-color: var(--primary-color); }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 3px; } }

    .sideColumnLabel {
      width: 100%;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #727981;

      font-family: Gilroy;
      text-transform: uppercase;
      font-size: 10px;
      font-style: normal;
      font-weight: 700;
      line-height: 10px;
      letter-spacing: 0; } }

  .sideColumnBody {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    border-top: 1px solid #DFE7EF;

    & > * {
      user-select: none; }

    .sideColumnHour {
      position: relative;
      flex: 1 1 0px;

      &:nth-child(2n):not(:last-child) {
        box-shadow: 0px 1px 0px 0px $grayBorderColor; }

      .sideColumnHourTitle {
        text-align: right;
        position: absolute;
        bottom: -1px;
        right: 0;
        left: 0;
        margin: auto;
        opacity: 1;
        color: #a1a6ab;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(50%);
        height: 100%;
        width: calc(100% - 15px);
        background-color: white;

        font-family: Roboto;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 11px;
        text-align: right;
        letter-spacing: 0.05em;
        text-transform: uppercase; } } } }

body[data-theme='dark'] {
  .sideColumnWrapper {
    background-color: var(--dt-ts-bg-gray);

    .sideColumnHeader {
      background-color: var(--dt-ts-bg-gray);

      .sideColumnMarker {
        border-color: var(--dt-ts-br-light-gray-1);

        &.cut:after {
          background-color: var(--dt-qs-gray); } } }

    .sideColumnBody {
      border-top-color: var(--dt-ts-br-light-gray-1); }

    .sideColumnHour {
      &:nth-child(2n):not(:last-child) {
        box-shadow: 0px 1px 0px 0px var(--dt-ts-br-gray); }

      .sideColumnHourTitle {
        background-color: var(--dt-ts-bg-gray); } } }

  .columnsGroup {
    &.stickToRight {
      .gradient {
        background: linear-gradient(-90deg, rgba(1, 4, 10, 0.25) 0%, rgba(2, 14, 46, 0) 100%); } }

    .gradient {
      background: linear-gradient(90deg, rgba(1, 4, 10, 0.25) 0%, rgba(2, 14, 46, 0) 100%); } }

  .tableBody {
    background-color: var(--dt-ts-bg-dark-gray);

    &.closedMonth {
      background: linear-gradient(55deg, $closedGradientColor2 12.50%, $closedGradientColor1 12.50%, $closedGradientColor1 50%, $closedGradientColor2 50%, $closedGradientColor2 62.50%, $closedGradientColor1 62.50%, $closedGradientColor1 100%) !important;
      background-size: 19.53px 27.90px !important; } }

  .groupMarker {
    background-color: var(--dt-ts-bg-gray);

    .markWrapper {
      .monthName {
        color: #bec3c9; } } }

  .columnsGroup {
    border-top-color: var(--dt-ts-br-light-gray-1);

    &.cutGroup {
      .groupMarker:after {
        background-color: var(--dt-qs-gray); }

      &:before {
        background: linear-gradient(55deg, var(--dt-ts-cut-day-gradient-1) 9.38%, var(--dt-ts-cut-day-gradient-2) 9.38%, var(--dt-ts-cut-day-gradient-2) 50%, var(--dt-ts-cut-day-gradient-1) 50%, var(--dt-ts-cut-day-gradient-1) 59.38%, var(--dt-ts-cut-day-gradient-2) 59.38%, var(--dt-ts-cut-day-gradient-2) 100%) !important;
        background-size: 19.53px 27.90px !important; } } } }
