body[data-theme='light'],
body[data-theme='dark'] {
  .manage-tag-panel {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;

    &--hidden {
      display: none; }

    &_popover-search {
      width: 220px;
      color: #000;

       :global {
        .ant-popover-arrow {
          display: none; }

        .ant-popover-inner {
          border-radius: 0 0 5px 5px;
          background-color: var(--background-gray);
          box-shadow: 0 3px 10px -4px rgba(0,0,0, 0.16); }

        .ant-popover-inner-content {
          padding: 6px 14px; } } }

     :global {
      .ant-popover-open {
        border-radius: 5px 5px 0 0; }

      .ant-input-affix-wrapper-focused {
        box-shadow: none !important; } }

    &_search-wrapper {
      padding-top: 4px;
      padding-bottom: 14px; } }

  .manageSkillsInput {
    width: 220px; }

  .manageSkillsSelect {
    width: 232px; }

  .skillTagsRadio {
    padding-left: 0; } }

body[data-theme='light'] {
  .manageSkillsInput {
    background: #FFFFFF;
    border: 1px solid #fff;

     :global {
      .ant-input {
        background-color: #fff; } } }

  .manageSkillsSelect {
    background-color: #fff; } }
