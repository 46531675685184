body[data-theme='light'],
body[data-theme='dark'] {

  .rootMenu {
    position: fixed;
    width: var(--menu-width-thick);
    height: 100%;
    font-family: 'SF Pro Display', sans-serif;
    font-size: 15px;
    z-index: 17;
    padding-bottom: 5px;
    background-color: #fff;
    color: var(--coal-black);
    border-right: 2px solid var(--background-gray);
    opacity: 1;
    visibility: visible; }

  .menuLoading {
    opacity: 0;
    visibility: hidden;
    pointer-events: none; }

  .menuInner {
    position: fixed;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start; }

  .menuLinkButton {
    height: 54px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    transition: .25s ease;
    width: 100%;

    span {
      margin: 0 17px;
      z-index: 1; }

    svg {
      fill: var(--icon-gray);
      color: var(--icon-gray); }

    &:hover {
      background-color: #eee; }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 0;
      transition: .25s ease;
      background-color: var(--primary-color);
      z-index: 0; } }

  .activeMenuLinkButton {
    &:before {
      width: 68px; }

    svg {
      fill: white; } }

  .menuLogoLink {
    margin: 19px 0 0;
    font-weight: 900;

    &:hover {
      background-color: transparent; } }

  .menuIconWrapper {
    width: 68px;
    height: 54px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1; }

  .menuLogo {
    transition: 0.3s ease;
    width: 28px;
    height: 28px; }

  .menu {
    flex-grow: 5;
    width: 100%; }

  .menuList {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none; }

  .menuListCommon {
    border-bottom: 1px solid var(--background-gray);
    padding-bottom: 20px;
    padding-top: 20px; }

  .menuListSpecial {
    padding-top: 20px; }

  .menuProfile {
    display: flex;
    margin: 10px 0 19px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    gap: 42px;

    &--active {
      box-shadow: 0 0 4px 0 rgba(#F4536E, 1);
      border-radius: 21px; } }

  .qs-menu__profile-wrapper {
    display: flex;
    align-items: center; }

  .logoutButton {
    background-color: transparent !important;
    svg {
      fill: var(--qs-gray); } }

  .menuLinkItem {
    text-decoration: none !important; }

  .changeThemeIcon {
    position: relative;
    left: 2px;
    color: var(--icon-gray);
    fill: var(--icon-gray);
    cursor: pointer;

    &:hover {
      color: var(--primary-color);
      fill: var(--primary-color); } } }

body[data-theme='dark'] {
  .menuListCommon {
    border-color: var(--dt-qs-gray-20); }

  .menuLinkButton {
    &:hover {
      background-color: var(--dt-qs-gray-20); } }

  .rootMenu {
    background-color: var(--dt-second-background-color);
    color: var(--coal-black);
    border-right: 2px solid var(--dt-qs-gray-20); } }
