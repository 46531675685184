body[data-theme='light'],
body[data-theme='dark'] {
  .settingsButton {
    position: absolute;
    left: 10px;
    top: 25px;

    &:hover {
      background: transparent; } }

  .settingsIcon {
    fill: var(--qs-gray);
    transition: 0.3s ease;

    &:hover {
      fill: var(--primary-color); } }

  .settingsIconOpened {
    fill: var(--primary-color); }

  .settingsPopoverLayout {
    width: auto;

     :global {
      .ant-popover-inner-content {
        padding: 16px; } } }

  .settingsHeader {
    flex-basis: 100%;
    min-height: 61px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid var(--grey-background-break-gray);
    margin-bottom: 28px;

    h1 {
      margin: 0;
      font-family: Gilroy;
      font-size: 20px;
      line-height: 24px;
      color: var(--font-black); } }

  .logoutButton {
    font-size: 14px;

    svg {
      fill: #fff; } }

  .loginPageFooter {
    width: 100%;

    a {
      display: inline-block;
      width: 135px;
      height: 40px; }

    .googlePlay,
    .googleStoreLogo,
    .appleStore {
      cursor: pointer; } } }

body[data-theme='dark'] {
  .settingsIcon {
    fill: var(--dt-qs-gray); }

  .settingsIconOpened {
    fill: var(--primary-color); }

  .profileSettings {
    .profileHeader {
      border-bottom: 1px solid var(--dt-qs-gray-20); } } }
