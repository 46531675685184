body[data-theme='light'],
body[data-theme='dark'] {
  .error-message {
    color: red;
    padding-bottom: 8px; }

  .manage-tag-panel_add-button {
    height: 39px;

    &--popover-visible {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0; } }

  .add-new-popover {
    padding-top: 0;

     :global {
      .ant-popover-arrow {
        display: none; } }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .button-annotation {
        font-size: 12px;
        padding: 0;
        margin: 0;
        color: rgba(#000, 0.5);

        b {
          margin: 0 4px; } } }

     :global {
      .ant-divider {
        margin: 0; }

      .ant-popover-inner {
        border-radius: 5px 0 5px 5px; } }

    .add-new-input {
      width: 340px;
      background-color: var(--background-gray);
      border: none;

       :global {
        .ant-input {
          background-color: var(--background-gray);

          &::placeholder {
            font-size: 15px; } } } } }


  .manageSkillsNewTag {
    width: 220px;
    margin-right: 30px; } }

body[data-theme='dark'] {
  .add-new-popover {
    .button-wrapper {
      .button-annotation {
        color: var(--font-white-85); } } } }
