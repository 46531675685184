body[data-theme='light'],
body[data-theme='dark'] {
  .set-experience-wrapper {
    padding: 10px 0;
    transition: background-color 0.25s ease;
    flex-grow: 2;
    height: 80px;
    border: 1px solid #E8EDF1;
    border-radius: 5px;
    background-color: var(--background-gray);
    position: relative;

    &.focused {
      background-color: #fff;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; }

    &:not(:last-child) {
      margin-right: 14px; }

    &--unknown-exp {
      .set-experience-wrapper_button {
        top: 4px; } }

    &_placeholder {
      font-family: Roboto;
      display: block;
      font-size: 14px;
      opacity: .35;
      padding: 6px 15px;
      margin-top: 3px; }

    &_select {
      line-height: normal;
      font-size: 13px;
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--qs-gray);

       :global {
        .ant-select-arrow {
          top: 5px;
          line-height: 8px; } } }

    &_button {
      border: none;
      position: absolute;
      right: 7px;
      top: 7px;
      box-shadow: none;
      background: none;
      outline: none;
      padding: 0 5px;
      margin: 0;
      cursor: pointer;
      font-size: 13px; }

    &_title {
      padding-right: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 16px;
      font-size: 13px; }

    &_label {
      font-family: Gilroy;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 0.02em;
      text-transform: uppercase;
      color: var(--qs-gray);
      opacity: 0.5;
      padding: 13px 0px 9px 15px;
      display: flex;
      align-items: center;

      svg {
        margin-left: 10px; }

      &--active {
        opacity: 1; } } }

  .specify-experience {
    &_unknown-wrapper {
      cursor: pointer;
      font-size: 13px;
      line-height: 16px; }

    &_dropdown {
      margin-bottom: -13px;
      padding-bottom: 13px;
      cursor: pointer; }

    &_overlay {
       :global {
        .ant-popover-arrow {
          display: none; } } }

    &_list {
      margin: 3px -16px; } }

  .specify-experience_list-item,
  .set-experience-wrapper_item {
    display: block;
    width: 100%;
    border: none;
    background: none;
    box-shadow: none;
    font-size: 13px;
    padding: 16px 18px;
    text-align: left;
    cursor: pointer;
    position: relative;
    transition: .25s ease;

    text-transform: capitalize;
    font-family: Roboto;
    font-weight: 400;
    white-space: nowrap;

    &--disabled {
      opacity: .5;
      cursor: not-alowed;
      pointer-events: none; }

    &:not(.specify-experience-list-item--disabled):hover {
      background-color: #eee; }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: calc(100% - 16px);
      left: 16px;
      border-bottom: 1px solid #D9E3EC;
      opacity: .3; } } }

body[data-theme='dark'] {
  .set-experience-wrapper {
    border: 1px solid var(--dt-qs-gray-20);
    background-color: var(--dt-qs-gray-20);

    &.focused {
      background-color: var(--primary-backround-black); }

    &_select {
      color: var(--font-white-85); }

    &_select:global(.ant-select-disabled) {
       :global {
        .ant-select-selector {
          color: var(--font-white-85); } } } } }
