body[data-theme='light'],
body[data-theme='dark'] {
  .singleDatepicker {
    border-radius: 5px;
    border: none; }

  .singleDatepicker:global(.ant-picker-disabled) {
     :global {
      .ant-picker-suffix svg {
        opacity: 0.3; } } }

  .datepickerSplitter {
    color: var(--font-black); } }


body[data-theme='dark'] {
  .singleDatepicker {
    background-color: var(--dt-qs-dark); } }
