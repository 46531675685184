body[data-theme='light'],
body[data-theme='dark'] {
  .profileSelect {
    width: 100%; }

  .profileSelect:global(.ant-select-disabled) {
     :global {
      .ant-select-selector {
        padding-left: 0 !important; } } }

  .noSkillsWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    margin: 0; } }
