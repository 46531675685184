$confirmWrapperWidth: 322px;

body[data-theme='light'],
body[data-theme='dark'] {

  .confirmButtonWrapper {
    display: flex;
    align-items: center; }

  .confirmNotificationTitleWrapper {
    display: flex;
    align-items: center;
    gap: 4px; }

  .confirmOkayButtonWrapper {
    z-index: 11;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    background-color: transparent;
    border: 1px solid #D9E2EC;
    padding: 9px 14px;
    border-radius: 5px;

    color: var(--qs-gray);
    font-size: 12px;
    line-height: 15px;
    font-weight: bold; }

  .notificationIcon {
    font-size: 18px; }

  .confirmNotificationStickyWrapper {
    position: absolute;
    top: 8px;
    right: 8px;
    height: calc(100% - 8px); }

  .confirmNotificationWrapper {
    overflow: hidden;
    width: $confirmWrapperWidth;
    height: 44px;
    transition: all 0.3s ease;
    z-index: 11;
    position: sticky;
    top: 0;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    padding: 8px 14px;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(118, 15, 32, 0.15);

    color: #fff;
    font-size: 12px;
    line-height: 15px;
    font-weight: bold; }

  .confirmNotificationTitle {
    white-space: nowrap; }

  .collapsedNotificationWrapper {
    border-radius: 0 0 5px 5px;
    transition: all 0.3s ease;
    width: 50px;

    &:hover {
      width: $confirmWrapperWidth;

      .confirmNotificationButton {
        width: 148px;
        display: block;
        opacity: 1;
        visibility: visible; }

      .confirmNotificationTitle {
        width: auto;
        display: flex;
        opacity: 1;
        visibility: visible; } }

    .confirmNotificationButton,
    .confirmNotificationTitle {
      width: 0;
      opacity: 0;
      visibility: hidden; } }

  .warningWrapper {
    background-color: var(--warning-color); }

  .isReadOnlyMode {
    width: 200px; }

  .errorWrapper {
    background-color: var(--primary-color); }

  .confirmNotificationButton {
    height: 28px;
    width: 100px;
    color: #fff;
    border-radius: 5px;
    border-color: #fff;
    font-family: Roboto;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px; }

  .warningButton {
    color: var(--warning-color);

    &:hover {
      color: lighten(#F19013, 10%);
      border-color: transparent; }

    &:focus,
    &:active {
      color: var(--warning-color);
      border-color: transparent; } }

  .errorButton {
    color: var(--primary-color);
    background-color: #fff;

    &:hover {
      border-color: transparent; } }

  .confirmModal {
     :global {
      .ant-modal-confirm-content {
        min-height: auto; } } } }

body[data-theme='dark'] {
  .confirmNotificationButton {
    background: #fff;

    &:active,
    &:focus {
      background: #fff; } }

  .errorButton,
  .warningButton {
    &:hover {
      background-color: lighten(#fff, 0.5); } } }
