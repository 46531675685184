$popoverBackgroundColor: #222222;

body[data-theme='light'],
body[data-theme='dark'] {
  .cardButton {
    & + .cardButton {
      margin-left: 10px; } }

  .employeesTable {
    color: #000;

    .employeeRow {
      cursor: pointer;

      &:hover {
        .buttonsRow {
          opacity: 1; } } }

    .noMainSkill {
      color: #9EA7AE;
      background-color: transparent;
      border: 1px solid #DEDEDE;
      padding: 0 15px; }

    .mainSkillTag {
      padding: 4px 15px; }

    .oneLineText {
      text-overflow: ellipsis;
      max-width: 280px;
      white-space: nowrap;
      overflow: hidden;
      display: block; } }

  .buttonsRow {
    opacity: 0;
    transition: .25s ease;
    display: flex;
    align-items: center;
    margin-right: 5px;

    .viewUserTimeSheet {
      padding: 0;
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      transition: .25s ease;
      border-radius: 2px;

      &:hover {
        background-color: rgba(0, 0, 0, 0.018); } } }

  .tooltip {
    &_inner {
      background-color: $popoverBackgroundColor;
      border-radius: 10px;
      display: flex;
      flex-direction: column; }

    &_tongue {
      position: absolute;
      height: 9px;
      fill: $popoverBackgroundColor;
      transform: translate(-10px, -50%) rotate(90deg);
      top: 50%;
      left: 0; }

    &_overlay {
       :global {
        .ant-tooltip-inner {
          padding: 12px 20px;
          border-radius: 10px;
          background-color: #222222; }

        .ant-tooltip-arrow {
          display: none; } } } }

  .expandableCell {
    color: var(--primary-color);
    font-weight: bold; } }
