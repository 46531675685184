body[data-theme='light'],
body[data-theme='dark'] {
  .workedHours {
    border-bottom: 1px solid #d9e3ec4d;
    padding: 24px 0;

    span {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 21px;

      color: var(--font-black); } }

  .comments {
    padding: 0;
    margin: 0;
    overflow-y: auto;

    .comment {
      list-style: none;
      margin: 0;
      padding: 24px 0;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      white-space: pre-line;
      text-align: left;
      color: var(--font-black);

      &:not(:last-child) {
        border-bottom: 1px solid #d9e3ec4d; } } }

  .actions {
    margin: 0 -24px -24px;
    padding: 18px 24px 24px;
    background: #f2f4f680;
    border-radius: 0 0 20px 20px;

    .label {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #727981;
      opacity: 0.75; } }

  .buttons {
    display: flex;
    flex-direction: column; }

  .button {
    height: 46px;
    padding: 14px 0;
    text-align: left;
    border: none;
    outline: none;
    background: none;
    box-shadow: none;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: var(--font-black);

    &.replaceButton {
      display: flex;
      justify-content: space-between;
      color: var(--font-black);

      svg {
        fill: var(--font-black); } }

    &:after,
    &:before {
      content: none; }

    &:active {
      background-color: #e1e1e1; }

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none; } } }

body[data-theme='dark'] {
  .workedHours {
    border-color: var(--dt-qs-gray-20); }

  .actions {
    background: var(--second-backround-black); }

  .comments {
    .comment {
      &:not(:last-child) {
        border-bottom: 1px solid var(--dt-qs-gray-20); } } } }
