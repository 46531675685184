body[data-theme='light'],
body[data-theme='dark'] {
  .qsSelect {
    border-radius: 5px;
    font-weight: normal;
    background-color: var(--background-gray);
    font-size: 14px;
    transition: 0.3s ease;

     :global {
      .ant-select-clear {
        background: var(--background-gray);
        transition: 0.3s ease;
        border-radius: 50%;
        right: 7px; }

      .ant-select-selection-search {
        left: 8px !important; }

      .ant-select-selector {
        padding: 0 8px 0 8px !important;

        .ant-select-selection-item {
          padding-right: 8px;
          line-height: 40px !important; } }

      .ant-select-arrow {
        right: 12px;
        top: 50%;
        transform: translate(0, -50%); }

      .ant-select-arrow {
        svg {
          fill: var(--primary-color); } }

      .ant-select-selection-placeholder {
        font-size: 14px;
        line-height: 40px !important; } } }

  .qsSelect:global(.ant-select-open) {
    border-radius: 5px;
    background-color: var(--primary-color);
    font-weight: normal;
    font-size: 14px;
    transition: 0.3s ease;
    color: #fff;

     :global {
      .ant-select-clear {
        background: var(--primary-color);
        color: #fff;
        border-radius: 50%; }

      .ant-select-selector {
        .ant-select-selection-item {
          color: #ffffffBF;
          line-height: 40px !important; } }

      .ant-select-arrow {
        top: 50%; }

      .ant-select-selection-placeholder {
        font-size: 14px;
        color: #fff;
        line-height: 40px !important; }

      .ant-select-arrow {
        svg {
          fill: #fff; } } } }

  .qsSelect:global(.ant-select-disabled) {
    background-color: transparent;
    font-weight: normal;

     :global {
      .ant-select-selector {
        color: var(--font-black); }

      .ant-select-arrow {
        display: none; } } }

  .selectDropdown {
    padding: 0;
    border-radius: 5px;

     :global {
      .ant-select-item {
        font-size: 13px;
        line-height: 15px;
        padding: 12px 16px;
        border-bottom: 1px solid var(--dt-qs-gray-20); }

      .ant-select-item-group {
        border-bottom: none;
        padding-left: 8px;
        font-size: 12px;

        &:last-of-type {
          border-bottom: none; } } } }

  .selectDropdownWide {
    @extend .selectDropdown;
    border-radius: 5px; } }

body[data-theme='light'] {
  .qsSelect {
     :global {
      .ant-select-selector {
        .ant-select-selection-item {
          color: #000; } } } } }

body[data-theme='dark'] {
  .qsSelect {
    background-color: var(--dt-qs-dark);

     :global {
      .ant-select-clear {
        background: var(--dt-qs-dark); } } } }
