body[data-theme='light'],
body[data-theme='dark'] {
  .skillTag {
    max-width: 100%;
    min-height: 30px;
    display: inline-flex;
    padding: 0 10px;
    margin: 2px 2px;
    background: #263341;
    font-size: 13px;
    border-radius: 100px;
    color: #fff;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    align-items: center;

     :global {
      .delete-icon {
        margin-left: 8px;
        color: white;
        cursor: pointer;
        opacity: .5;
        transition: .25s ease;

        &:hover {
          opacity: 1; } } } }

  .skillTagSpan {
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block;

     :global {
      .truncated-text {
        max-width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        display: block; } } }

  .skillTagTooltip {
     :global {
      .ant-tooltip-inner {
        border-radius: 10px;
        text-align: center; } } }

  .deleteLoading {
    margin-left: 8px;
    color: var(--primary-color); } }

