body[data-theme='light'],
body[data-theme='dark'] {
  .userAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px; }

  .companyAvatar {
    width: 28px;
    height: 28px;
    border-radius: 0;
    margin-right: 8px; } }
