body[data-theme='light'],
body[data-theme='dark'] {
  .requestTableConfirmModal {

     :global {
      .ant-modal-content {
        border-radius: 2px; }

      .ant-modal-body {
        padding: 0px; } } }

  .requests-table-popover {
    &_select {
      flex-grow: 2;

      &:not(:last-child) {
        margin-right: 8px; } }

    &_select-wrapper {
      display: flex; }

    &_emp-row {
      display: flex;
      justify-content: center;

      .emp-row_icon {
        color: #727981;
        opacity: 0.7;
        cursor: pointer; } }

    &_popover-menu {
      z-index: 999;

       :global {
        .ant-popover-inner {
          border-radius: 5px; }

        .ant-popover-inner-content {
          padding: 0; } }

      &-content {
        width: 224px;

        &_loading {
          margin-right: 4px; }

        &_item {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 13px;
          line-height: 15px;
          color: var(--font-black);
          cursor: pointer;
          padding: 9px 0 14px 16px;
          display: block;
          width: 100%;
          text-align: left;
          height: auto !important;
          border-color: transparent;

          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 16px;
            width: calc(100% - 16px);
            border-bottom: 1px solid rgba(#82888F, 0.1); }

          &:hover {
            color: var(--primary-color);
            background-color: white; }

          &:active,
          &:focus {
            background-color: white; }

          &:first-of-type {
            padding-top: 18px; }

          &:last-of-type {
            padding-bottom: 18px;
            border-bottom: none; } } } } }

  .requestTableConfirmModal {

    .skill-tag {
      padding: 6px 10px;
      margin: 0 5px;
      background: #263341;
      font-size: 13px;
      border-radius: 100px;
      color: #fff; } } }

body[data-theme='dark'] {
  .requests-table-popover {

    &_popover-menu {
      .requests-table-popover_popover-menu-content_item {
        &:hover,
        &:active,
        &:focus {
          background-color: var(--dt-qs-gray-20); } } } } }
