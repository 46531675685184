body[data-theme='light'],
body[data-theme='dark'] {

  .employee {
    padding-left: 34px;
    padding-top: 16px;
    padding-right: 24px;
    min-width: 1200px;

     :global {
      .borderless {
        border-right: 1px solid transparent !important; }

      .action-column {
        padding: 0; } } }

  .employeeFilters {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; } }


