body[data-theme='light'],
body[data-theme='dark'] {
  .drawerSkillTag {
    margin: 0 5px;
    background: #263341;
    color: white;

    &.multiple {
      max-width: 100%;
      min-height: 30px;
      display: inline-flex;
      padding: 0 10px;
      font-size: 13px;
      border-radius: 100px;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      line-height: 15px;
      align-items: center; } }

  .selection-skill-panel {
    position: relative;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 2px;
      z-index: 1;
      background-color: var(--grey-background-break-gray); }

    .selection-count {
      &_button {
        margin-left: 8px;
        width: 38px;
        height: 40px;
        background-color: #E5EAEE;
        border-radius: 5px;
        &:hover {
          background-color: darken(#E5EAEE, 5%); }

        &--open {
          background-color: var(--primary-color);
          border-radius: 5px 5px 0 0;
          color: #fff;
          &:hover {
            background-color: darken(#F4536E, 5%); } } }

      &_popover {
         :global {
          .ant-popover-arrow {
            display: none; } } } }


    &_selection-count {
      display: flex;
      align-items: center;

      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      color: #000000; } }

  .qs-confirm-antd-modal {
    font-family: Roboto;
    font-style: normal;
    font-size: 15px;
    line-height: 18px; }

   :global {
    .ant-modal-body {
      padding: 0; }

    .ant-modal-content {
      border-radius: 5px; }

    .ant-modal-confirm-content {
      margin: 0;
      min-height: 138px;
      padding: 38px 22px 38px 22px;
      border-bottom: 1px solid rgba(#727981, 0.1);
      font-weight: normal;
      font-size: 15px;
      color: #000000; }

    .ant-modal-confirm-btns {
      padding: 10px 0;
      margin: 0; }

    .ant-modal-confirm-title {
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      color: #000000;
      padding: 25px 0 25px 20px;
      border-bottom: 1px solid rgba(#727981, 0.1); }


    .skill-tag {
      padding: 6px 10px;
      margin: 2px 4px;
      background: #263341;
      font-size: 13px;
      border-radius: 100px;
      color: #fff; }

    .skill-tag-wrapper {
      display: flex;
      flex-wrap: wrap; } }

  .skill-list_popover {
    max-width: 300px;

     :global {
      .ant-popover-inner {
        background-color: #222222;
        border-radius: 10px;

        .ant-popover-inner-content {
          color: white; } } }

    &--left {
       :global {
        .ant-popover-content > .ant-popover-arrow {
          border-top-color: #222222;
          border-right-color: #222222; } } }

    &--right {
       :global {
        .ant-popover-content > .ant-popover-arrow {
          border-bottom-color: #222222;
          border-left-color: #222222; } } } }

  &_cancel-btn {
    padding-left: 21px;
    padding-right: 21px;
    margin-right: 15px;

    font-weight: 500;
    font-size: 15px;
    height: 45px;
    text-align: center;
    color: #82888F; }

  &_ok-btn {
    width: 170px;
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    color: #FFFFFF; }

  .skill-list {
    max-height: 225px;
    overflow: auto;
    display: flex;
    flex-direction: column;

    &_buttons-row {
      display: flex;
      margin: 15px 0; }

    &_button-text {
      color: #727981;
      font-family: Roboto;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: 13px;
      letter-spacing: 0em;
      text-align: left;
      margin-top: 4px; }

    &_button {
      border-width: 0px !important;
      width: calc(100%/2 - 7px);
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      font-family: Roboto;
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      height: 91px;
      border-radius: 5px;
      padding: 19px 11px;
      transition: .25s ease;

      &:not(:disabled) {
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); }

      &:disabled {
        background-color: #F2F4F6; }

      svg {
        margin-top: -3px; }

      div {
        white-space: normal;
        margin-left: 10px;

        span {
          display: block; } }

      &:not(:last-child) {
        margin-right: 14px; }

      &:not(:disabled):hover {
        background-color: white;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15); }

      &--active {
        border-color: var(--primary-color); }

      &--not-default {
        height: 56px; } }

    .skill-list-item {
      padding: 10px 0;
      border-bottom: 1px solid rgba(#727981, 0.1); }

    .skill-list-checkbox {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #000000; }

    .skill-tag-panel_skill-tag-select {
      width: 100%; } }

  .selection-count_popover {
    padding-top: 0;

    &--open {
      .selection-count_button {
        background-color: var(--primary-color); } }

     :global {
      .ant-popover-inner-content {
        padding: 12px 0 12px 17px; }

      .ant-popover-arrow {
        display: none; } }

    .selection-popover {
      width: 369px;

      &.ant-popover-open {
        background-color: #fff; }

      &_body {
        max-height: 240px;
        overflow-y: auto; } } }


  .skill-tag-panel {
    &_input,
    &_category-select,
    &_select {
      background: #F2F4F6;
      border-radius: 5px;
      font-size: 15px;
      width: 100%;

      &:hover,
      &:active,
      &:focus {
        background: #F2F4F6; } }

    &_select {
       :global {
        .ant-select-arrow {
          width: 24px;
          height: 24px;
          top: 16px;
          right: 3px; } } }

    &_category-select {
      margin: 0px 8px 0 0 !important; }

    &_row {
      display: flex;
      margin-top: 8px;
      align-items: center; } }

  .mergeSelect {
    width: 100%; } }

body[data-theme='dark'] {
  .drawerSkillTag {
    background: var(--background-gray);
    color: white; }

  .skill-list_button:not(:disabled) {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-color: #262d34 !important;

    &:hover {
      background: inherit;
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.55); } }

  .skill-list_button:disabled {
    border-color: #262d34 !important;
    background: inherit; } }
