body[data-theme='light'],
body[data-theme='dark'] {
  .groupPopover {
    width: 250px;
    z-index: 999;
    padding: 12px 20px;
    margin: -12px -16px;

     :global {
      .ant-popover-inner {
        border-radius: 5px;

        &-content {
          padding: 5px;

          li {
            padding: 14px;

            &:hover {
              background-color: white !important; }

            &::after {
              display: none; } } } } } }

  .editingButtons {
    padding: 0 20px;
    display: flex;
    justify-content: flex-end;
    gap: 8px; }

  .cancelButton {
    background-color: transparent; }

  .withBottomLine {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: calc(100% + 10px);
      left: 0;
      bottom: 0;
      border-bottom: 1px solid #D9E3EC;
      opacity: 0.3; } }

  .groupPopoverHeader {
    display: flex;
    align-items: center;
    padding-bottom: 14px; }

  .partnerMaskWrapper {
    height: 24px;
    width: 24px;
    flex: 24px 0 0;
    margin-right: 6px; }

  .partnerMask {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #AEB9C7;
    border: 1px solid;
    border-radius: 5px;
    position: relative;

    svg {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); } }

  .partnerAndActivityName {
    color: var(--font-black);
    flex: 158px 1 1;
    width: 158px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

  .groupPopoverHoursSpent {
    display: flex;
    align-items: center;
    padding: 8px 0;

    &.nonInteractive {
      .hoursAmount {
        background: none;
        font-size: 12px;
        color: var(--font-black);
        width: auto;
        flex-basis: auto; }

      .hoursLabel {
        color: var(--font-black); } } }

  .hoursAmount {
    width: 48px;
    height: 32px;
    flex: 48px 0 0;
    display: inline-block;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;
    background: #F2F4F6;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center; }

  .hoursLabel {
    color: rgba(0, 0, 0, .5);
    flex-grow: 2;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; }

  .groupPopoverComments {
    margin-right: -14px;
    width: calc(100% + 14px);

    .groupPopoverCommentsInner {
      padding: 8px 0;
      overflow-x: visible; }

    .commentsCustomScrollBar {}

    &:last-child {
      // when month is closed -- this section becomes a footer and shouldn't have padding bottom
      padding-bottom: 0; }

    &.nonInteractive {
      .comment {
        background: none;
        padding: 0; } } }

  .groupPopoverFooter {
    padding-top: 5px;

    &.withoutLabel {
      padding-top: 7px; } }

  .groupPopoverFooterLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: var(--qs-gray);
    opacity: 0.75; }

  .popoverActionButtons {
    padding-top: 2px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% + 40px);
    margin: 0 -20px; }

  .popoverButton {
    border: none;
    padding: 14px 20px 13px;
    height: 41px;
    border-radius: 0;
    text-align: left;
    transition: .25s ease;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    &::after {
      display: none; }

    &:hover {
      background: #F2F4F6; }

    &.withBottomLine:before {
      left: 20px;
      width: calc(100% - 30px); } } }

body[data-theme='dark'] {
  .hoursLabel {
    color: var(--font-white-85); }

  .hoursAmount {
    background-color: var(--second-backround-black); }

  .popoverButton {
    &:hover {
      background-color: var(--second-backround-black); } } }
