@import '../../../../helpers/styles/mixins.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .flexRow {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    span {
      flex-grow: 2; } }

  .expandedIcon {
    transition: 0.3s ease;
    float: left;
    fill: var(--font-black);

    &.expanded {
      transform: rotate(90deg); } }

  .expandedRow {
    color: red; }

  .parentRow {
    cursor: pointer; }

  .expandedRowActivity {
    padding-left: 32px; }

  .summaryRow {
     :global {
      .ant-table-cell {
        color: #727981;
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        padding: 16px;
        box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; } } }

  .totalRow {
     :global {
      .ant-table-cell {
        font-size: 16px;
        color: var(--font-black); } } }

  .summaryHoliday {
     :global {
      .ant-table-cell {
        border-top: 2px solid var(--grey-background-break-gray);
        color: var(--primary-color);
        box-shadow: none; } } }

  .summaryHolidayTitle {
    display: flex;
    align-items: center;
    gap: 8px; }

  .summaryHolidayIcon {
    fill: var(--primary-color); }

  .paymentField {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.04em;
    color: var(--qs-gray); }

  .salaryFieldWrapper {
    padding-left: 10px !important; }

  .totalRowSalaryField {
    font-size: 16px; }

  .mobileSummaryRow {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 10px !important; }

  .mobileHolidayTotal {
    text-align: right; }

  /* MOBILE STYLES */

  @include mq('tablet-wide') {
    .parentRow {
      span {
        font-size: 14px; } }

    .totalRow {
       :global {
        .ant-table-cell {
          font-size: 14px; } } }

    .summaryRow {
       :global {
        .ant-table-cell {
            font-size: 14px;
            padding: 12px 16px; } } }

    .totalRowSalaryField {
      font-size: 14px;
      margin-right: -6px; }

    .reportsPage {
       :global {
        .ant-table-row,
        .ant-table-summary {
          .ant-table-cell {
            padding: 6px 16px; }

          .ant-table-column-sort {
            padding-left: 4px; } }

        .ant-table-row-level-1 {
          background-color: #fafafaBF; } } } } }

body[data-theme='light'] {
  .reportHourTable {
     :global {
      .ant-table-row-level-1 .ant-table-cell {
        background-color: #fafafaBF; } } } }
