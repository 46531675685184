body[data-theme='light'],
body[data-theme='dark'] {
  .monthsSelectorPanel {
    padding: 31px 39px 20px 39px;
    border-bottom: 1px solid var(--grey-background-break-gray);
    background-color: var(--background-gray);
    z-index: 1;

    &.sticky {
      position: sticky;
      top: 0; } }

  .radio {
    width: 54px;
    vertical-align: text-bottom;
    text-align: center; }

  .firstRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: center; }

  .monthsSelectorTitle {
    font-family: Gilroy;
    font-size: 20px;
    color: #000000; }

  .modeWrapper {
    display: flex;
    align-items: center;
    gap: 12px; }

  .modeText {
    font-family: Gilroy;
    font-size: 20px;
    color: #000000; }

  .modeIconBlock {
    font-size: 24px; }

  .radioWrapper {
    background-color: #E5EAEE;
    border-radius: 10px !important;

     :global {
      .ant-radio-button-wrapper-checked {
        border: none;
        background-color: #fff; } } }

  .radioItem {
    background-color: transparent;
    border: none;
    border-radius: 10px !important; } }

body[data-theme='dark'] {
  .radioGroup {
    background-color: var(--dt-qs-dark); } }

