body[data-theme='dark'],
body[data-theme='light'] {
  .select {
    height: 40px;
    border-radius: 5px;
    display: flex;
    align-items: center; } }

body[data-theme='light'] {
  .select {
    background-color: #E5EAEE; } }

body[data-theme='dark'] {
  .select {
    background-color: var(--dt-qs-dark); } }
