body[data-theme='light'],
body[data-theme='dark'] {
  .drawer {
    height: 100%;

     :global {
      .ant-spin-nested-loading > div > .ant-spin {
        max-height: none;
        min-height: 600px; }

      .ant-drawer-body {
        padding: 34px 0 0 32px; }

      .ant-drawer-footer {
        display: flex;
        justify-content: flex-end; } } }

  .drawerContent {
    margin-top: -34px;
    margin-left: -32px;
    height: calc(100% + 34px);
    width: calc(100% + 32px);
    position: relative; }

  .monthWrapper {
 } }    // position: absolute
