/* DT - Dark Theme */
/* LT - Light Theme */

 :root {
  --primary-color: #F4536E;
  --success-color: #91BF9D;
  --warning-color: #F19013;
  --primary-backround-black: #1B2228;
  --second-backround-black: #2c2c2e;
  --primary-background-white: #F2F4F6;

  --font-white: #fff;
  --font-white-85: #FFFFFFD9;
  --font-black: #000;

  --white: #fff;
  --black-constant: #000;

  --green: #46A650;
  --green-2: #51C199;
  --orange: #FF8265;
  --blue: #66afe9;
  --light-green: #1ABE9B;
  --tracker-gray: #F7F9FB;
  --ant-warning-color: #faad14;


  --dt-second-background-color: #232b32;
  --dt-qs-gray: #9BA3AC;
  --dt-qs-gray-20: #9BA3AC33;
  --dt-qs-dark: #232E39;
  --dt-gray-button: #2D3743;

  --gray-1: #ffffff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #f0f0f0;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #595959;
  --gray-9: #434343;
  --gray-10: #262626;
  --gray-11: #1f1f1f;
  --gray-12: #141414;

  /* BORDERS */
  --border-gray-30: #d9e3ec4d;
  --list-border-10: #82888F1A;

  --background-gray: #F2F4F6;
  --break-gray: #D9E3EC;
  --grey-background-break-gray: #D1DBE3;
  --icon-gray: #727981;
  --qs-gray: #727981;
  --button-hover-gray: #DADADA;

  --main-table-width: 750px;

  --coal-black: #222222;

  --menu-width-thick: 70px;
  --sidebar-width: 240px;
  --sidebar-collapsed: 40px;

  --menu-width-wide: 310px;

  --base-transition: width 0.3s;

  /* TIMESHEET */

  --dt-primary-color: #FF8B9E;
  --dt-ts-bg-gray: #20272E;
  --dt-ts-bg-dark-gray: #1b2228;
  --dt-ts-br-gray: #2D3339;
  --dt-ts-br-light-gray-1: #2f363d;
  --dt-ts-br-light-gray-2: #2f363d;
  --dt-ts-cut-day-gradient-1: #2e363e;
  --dt-ts-cut-day-gradient-2: #242b32; }
