body[data-theme='light'],
body[data-theme='dark'] {
  .rootWrapper {
    height: 100vh;
    min-height: 100vh; }

  .contentWrapper {
    margin-left: var(--menu-width-thick);
    height: 100%; }

  .contentWrapper.isSidebar {
    margin-left: calc(var(--menu-width-thick) + var(--sidebar-width));
    height: 100%; }

  .isSidebar.collapsed {
    margin-left: calc(var(--menu-width-thick) + var(--sidebar-collapsed)); } }
