@import '../../../../../../../helpers/tableVariables.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .columnBody {
    flex: 1 1 0px;
    display: flex;
    flex-direction: column;
    position: relative;
    user-select: none;

    &:after {
      content: '';
      border-left: 1px solid $grayBorderColor;
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      pointer-events: none; }

    &.closedMonth {
      border-left-color: #dfe7ef73;

      &.cutDay,
      &.afterCut {
        border-left-color: #EEE9AC80 !important; }

      &.weekend,
      &.afterWeekend {
        border-left-color: #f4536e14 !important; } }

    &:before {
      content: '';
      top: 0;
      width: 100%;
      height: 100%;
      position: absolute; }

    &.afterWeekend:after {
      border-left-color: $redBorderColor; }

    &.weekend {
      background-color: rgba(244, 83, 110, 0.05);

      &:after {
        border-left-color: $redBorderColor; } }

    &.cutDay,
    &.afterCut {
      &:after {
        border-left-color: #EEE9AC !important; } }

    &.endOfPrevMonth:before,
    &.lastBeforeOriginal:before {
      right: -1px;
      background: linear-gradient(-90deg, rgba(5, 17, 46, 0.05) 0%, rgba(2, 14, 46, 0) 100%); }

    &.startOfNextMonth:before,
    &.firstAfterOriginal:before {
      left: 0;
      background: linear-gradient(90deg, rgba(5, 17, 46, 0.05) 0%, rgba(2, 14, 46, 0) 100%); } } }

body[data-theme='dark'] {
  .columnBody {
    &:after {
      border-left-color: var(--dt-ts-br-gray); }

    &.closedMonth {
      &:after {
        border-left-color: var(--dt-ts-br-gray); }

      &.cutDay,
      &.afterCut {
        &:after {
          border-left-color: var(--dt-ts-br-gray) !important; } }

      &.weekend,
      &.afterWeekend {
        &:after {
          border-left-color: #61464a !important; } } }

    &.cutDay,
    &.afterCut {
      &:after {
        border-left-color: var(--dt-ts-br-gray) !important; } }

    &.weekend,
    &.afterWeekend {
      &:after {
        border-left-color: #61464a; } } } }
