body[data-theme='light'],
body[data-theme='dark'] {
  .popover {
     :global {
      .ant-popover-inner {
        border-radius: 5px; } } }

  .activeStatusPopoverTrigger,
  .activeStatus {
    width: 105px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 8px 12px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    background-color: #E5EAEE; }

  .activeStatusPopoverTrigger {
    cursor: pointer;
    transition: .25s ease;

    svg {
      transition: .25s ease;
      min-width: 8px;
      height: 5px; }

    &:hover {
      background-color: var(--primary-color);
      color: white;

      svg {
        fill: white; } } }

  .activeStatus {
    justify-content: space-between;
    width: 85px; }

  .activeStatusMark {
    width: 10px;
    min-width: 10px;
    height: 10px;
    border-radius: 5px;
    background: #bbb;

    &.active {
      background-color: #60BF7A; } }

  .activeStatusText {
    flex-grow: 2; }

  .popoverInner {
    width: 224px;
    border-radius: 5px; }

  .activeStatusButton {
    border: none;
    display: flex;
    align-items: baseline;
    flex-direction: row;
    gap: 6px;
    padding-left: 0;

    .activeStatusMark {
      background: var(--primary-color);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); } }

  .activeStatusDescription {
    margin: 0;
    padding-top: 14px;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 16px);
      border-bottom: 1px solid #82888F;
      opacity: 0.1; } }

  .optionFieldWrapper {
    display: flex;
    flex-direction: column;
    gap: 11px;
    min-width: 200px; }

  .optionField {
    display: flex;
    justify-content: space-between; }

  .fieldTitle {
    font-size: 13px;
    line-height: 15px;
    color: var(--qs-gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-items: flex-start; }

  .optionLayout {
    display: flex;
    gap: 20px; }

  .optionFieldTitle {
    margin-bottom: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: #00000080; }

  .optionsDescription {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;
    color: var(--qs-gray); }

  .partnerTextArea {
    resize: none;
    padding: 6px 12px;
    border-radius: 5px;
    color: var(--font-black);
    -webkit-text-fill-color: var(--font-black); // black text render for Safari
    border: 1px solid var(--background-gray);
    background-color: var(--background-gray);

    &:disabled {
      cursor: default;
      padding-left: 0;
      background: transparent;
      color: var(--font-black);
      border: 1px solid transparent;

      &:hover {
        border: 1px solid transparent; } } } }

body[data-theme='dark'] {
  .optionFieldTitle {
    color: white; }

  .activeStatus,
  .activeStatusPopoverTrigger {
    background: #334151;
    color: white; } }

body[data-theme='light'] {
  .optionFieldTitle {
    color: var(--dt-qs-gray); }

  .activeTitle {
    color: var(--font-black); }

  .fieldTitle {
    color: var(--dt-qs-gray); }

  .activeStatusButton .activeStatusMark {
    background: var(--primary-color); } }
