body[data-theme='light'],
body[data-theme='dark'] {
  .businessCalendar {
    display: flex;
    flex-direction: column;
    height: 100%; }

  .swiperSlide {
    width: auto; }

  .testCollapse {
     :global {
      .ant-collapse-content {
        background-color: #f2f4f6; } } }

  .monthsView {
    white-space: nowrap;
    scroll-behavior: smooth;
    overflow-x: hidden;
    flex-grow: 2;
    padding: 0px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

     :global {
      .carousel {
        width: 372px;
        margin: auto;

        .slick-list {
          overflow: visible; } } } }

  .monthViewWrapper {
    margin-top: 34px;
    width: 100%;
    flex-grow: 2;
    display: flex;
    flex-direction: column; }

  .monthDrawerButton {
    padding-left: 30px;
    padding-right: 30px; } }
