@import 'src/helpers/styles/mixins.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .reportSettingsLayout {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset;

    @include mq('tablet-wide') {
      justify-content: center; } }

  .reportMonthPicker {
    margin: 0; }

  .datepickerDividerLoading {
    opacity: 0.2; } }

