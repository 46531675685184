@import "../../helpers/styles/mixins.sass";
body[data-theme='light'],
body[data-theme='dark'] {
  .rootSizeOverlay {
    padding: 24px;
    position: fixed;
    flex-wrap: wrap;
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.9);
    z-index: 999;
    text-align: center;

    @include mq("tablet-wide", max) {
      &.desktop {
        display: flex; } }

    @include mq("tablet-wide", min) {
      &.mobile {
        display: flex; } } }

  .rootSizeOverlayDesc {
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    flex-direction: column;
    flex-wrap: wrap; }

  .overlayDescription {
    max-width: 300px;
    padding-bottom: 16px; } }
