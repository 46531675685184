@import '../../../../../../../helpers/styles/commonStyles.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .add-new-modal {
    &_wrapper {
      display: flex;
      flex-direction: column; }

    &_select,
    &_input {
      margin-bottom: 8px; } }

  .error-message {
    color: red;
    padding-left: 8px;
    padding-bottom: 8px; } }

