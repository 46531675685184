body[data-theme='light'],
body[data-theme='dark'] {
  .sidebarList {
    margin: 0;
    padding: 0;
    list-style: none; }

  .searchModeArrow {
    fill: var(--qs-gray);
    transform: rotate(180deg); }

  .sidebarListItem {
    height: 58px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: calc(100% - 25px);
      left: 25px;
      bottom: 0;
      border-bottom: #D9E3EC4D; } }

  .activeSidebarList {
    background-color: #DADADA4D; }

  .sidebarHeader {
    margin-bottom: 7px; }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 32px; }

  .sidebarListItemLink {
    display: flex;
    align-items: center;
    cursor: pointer;
    width: 100%;
    padding-left: 25px;
    text-decoration: none;
    height: 100%;
    border: none;
    box-shadow: none;
    outline: none;
    background: none;

    &:hover {
      color: var(--primary-color); } }

  .sidebarLayout {
    transition: background-color 0.3s ease;
    height: 100%; }

  .isActivitySelected {
    background-color: var(--tracker-gray); }

  .sidebarContentWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.collapsed {
      .sidebarHeader,
      .sidebarList {
        opacity: 0;
        width: var(--sidebar-collapsed);
        visibility: hidden; } } }

  .thumbVertical {
    width: 6px !important;
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.2); }

  .activitiesWrapper {
    opacity: 1;
    visibility: visible;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 10px 0 20px; }

  .collapsedActivities {
    opacity: 0;
    visibility: hidden; }

  .activitiesTitle {
    font-family: Gilroy;
    font-size: 14px;
    line-height: 16px; }

  .editButton {
    line-height: 0;
    padding: 2px 3px;
    border-radius: 5px;
    width: 26px;
    height: 24px;
    background: #7279811a;

    &:disabled {
      opacity: 0.5; }

    svg {
      &:hover {
        background-color: #DADADA4D; }

      &:hover,
      &:focus,
      &:active,
      &:visited {
        .sidebar_tab-link {
          text-decoration: none; } } } }

  .partnersButton {
    position: absolute;
    left: 8px;
    bottom: 4px;

    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    padding: 4px 12px;
    border-radius: 10px;
    background-color: #000000CC !important;
    font-weight: 500;
    font-size: 10px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #fff !important;

    visibility: visible;
    opacity: 1;

    &:hover,
    &:focus {
      background-color: #000000CC;
      color: #fff; } }

  .hiddenPartnerButton {
    visibility: hidden;
    opacity: 0; }

  .goBackButton {
    font-size: 16px;
    height: 58px;
    width: 100%;
    border-radius: 0;
    justify-content: center;
    gap: 8px; }

  .sidebarActivities {
    height: 100%;

    &.hidden {
      display: none; } } }

body[data-theme='light'] {
  .sidebarListItemLink {
    color: var(--qs-gray); }

  .sidebarListItem {
    color: var(--icon-gray); }

  .activeSidebarList {
    .sidebarListItemLink {
      color: var(--font-black); } } }

body[data-theme='dark'] {
  .activeSidebarList {
    background-color: var(--dt-qs-gray-20);

    &:hover {
      background-color: var(--dt-qs-gray-20); } }

  .sidebarListItemLink {
    color: #ffffffD9; }

  .isActivitySelected {
    background-color: #1c1c1e26; }

  .activitiesTitle {
    color: #fff; } }






