@import '../../../helpers/tableVariables.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .timelineWrapper {
    position: absolute;
    top: 100px;
    left: 0px;
    width: 100%;
    height: calc(100% - 100px);
    pointer-events: none;
    overflow: hidden;
    z-index: 2; }

  .timeline {
    position: absolute;
    left: 0;
    height: 1px;
    width: 100%;
    opacity: 1;
    transition: .25s ease;
    padding-left: $sideColumnWidth;
    display: flex;

    &.isLoading {
      opacity: 0;

      &:before {
        width: 2px;
        height: 2px; } }

    &:before {
      content: '';
      width: 6.5px;
      height: 13px;
      border-radius: 0px 6.5px 6.5px 0px;
      background-color: var(--primary-color);
      transition: .25s ease;
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(-50%); } }

  .timelineInner {
    display: flex;
    width: 100%; }

  .middle {
    flex-grow: 0;
    flex-shrink: 0; }

  .redLine {
    border-bottom: 1px dashed var(--primary-color);
    height: 1px;
    position: relative;
    flex-grow: 0;
    flex-shrink: 0;

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 1px;
      height: 5px;
      transform: translateY(-50%);
      border: 0px solid var(--primary-color); }

    &.left:after {
      right: 0;
      border-right-width: 1px; }

    &.right:after {
      left: 0;
      border-left-width: 1px; } } }

body[data-theme='dark'] {
  .redLine {
    border-bottom: 1px solid var(--primary-color); } }
