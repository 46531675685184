body[data-theme='light'],
body[data-theme='dark'] {
  .profileSection {
    padding: 4px 16px 16px;
    margin-top: -66px;
    width: calc(100% - 56px); }

  .panelBox {
    display: flex;
    align-items: center;
    gap: 8px; }

  .spinner {
    color: #F4536E; }

  .avatar {
    width: 48px;
    height: 48px;
    display: block;
    position: relative; }

  .avatarImg {
    width: 100%;
    height: 100%;
    border-radius: 50%; }

  .infoBox {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% + 56px);
    margin-top: 15px; }

  .hoursWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    transition: .25s ease;
    pointer-events: none;

    &.hidden {
      opacity: 0;
      margin-top: -25px; } }

  .hours {
    font-family: Gilroy, Helvetica Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;

    display: flex;
    align-items: center;

    color: #9BA3AC;

    & + .hours {
      margin-left: 3px; } }

  .clockIcon {
    width: 15px;
    height: 15px;
    fill: #9BA3AC;
    margin-top: -1px;
    margin-right: 4px; } }
