body[data-theme='light'],
body[data-theme='dark'] {
  .drawPopover {
     :global {
      .ant-popover-inner {
        border-radius: 5px; } } }

  .drawModeSwitcherWrapper {
    display: flex;
    align-items: center;
    margin-left: 4px;

    &.collapsed {
      margin-left: 6px; } }

  .drawModeButton {
    background-color: #f4536e1a;
    color: #F4536E;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    padding: 3px 7px;
    border-radius: 5px;
    border: none;
    box-shadow: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 24px;

    span {
      display: flex;
      align-items: center; }

    svg {
      margin-right: 4px;
      fill: var(--primary-color); }

    &:hover,
    &:active,
    &:focus {
      background: #f4536e33;
      color: #F4536E; } }

  .drawModeSwitchedContent {
    display: flex;
    flex-direction: column; }

  .contentTitle {
    line-height: 14px;
    margin-bottom: 15px;
    opacity: .5;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px; }

  .descriptionButton {
    display: flex;
    flex-direction: row;
    height: auto;
    width: 254px;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    background: transparent;
    text-align: left;
    padding: 10px;

    & + .descriptionButton {
      margin-top: 4px; }

    &:active,
    &:focus {
      color: inherit;
      background: inherit; }

    &:hover {
      color: black;
      background-color: #eee; }

    &.active {
      background-color: #F4536E1a;

      svg {
        fill: var(--primary-color); }

      .title {
        color: var(--primary-color); }

      &:hover {
        color: inherit;
        background-color: #F4536E1a; } } }

  .iconWrapper {
    margin-right: 5px;

    svg {
      fill: #1B2228; }

    .brushIcon {
      width: 20px;
      height: 19px; }

    .mouseIcon {
      width: 16px;
      height: 22px; } }

  .descriptionWrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 4px;

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 8px; }

    .text {
      white-space: normal;
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 125%;

      color: #1B2228; } } }

body[data-theme='dark'] {
  .drawModeButton {
    svg {
      fill: var(--primary-color); } }

  .descriptionButton {
    .brushIcon,
    .mouseIcon {
      fill: #fff; }

    .text {
      color: white; }

    &:hover {
      background-color: #242d37;
      color: white;

      .title {
        color: white; } }

    &.active {
      .brushIcon,
      .mouseIcon {
        fill: var(--primary-color); }

      .title,
      .text {
        color: var(--primary-color); } } } }
