body[data-theme='light'],
body[data-theme='dark'] {
  .edit-modal {
    &_wrapper {
      display: flex; }

    &_select {
      border-radius: 5px;
      background-color: #F2F4F6;

      &:not(:last-child) {
        flex-grow: 2;
        margin-right: 8px; } } } }
