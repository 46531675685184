body[data-theme='light'],
body[data-theme='dark'] {
  .partnerListItemWrapper {
    background-color: transparent;

    svg {
      fill: white; }

     :global {
      .ant-collapse-item {
        border: none;

        .ant-collapse-header {
          padding-top: 0;
          padding-left: 0;
          padding-bottom: 0; } }

      .ant-collapse-content-box {
        padding: 0 !important; } } }

  .partnerListItem {
    cursor: pointer;
    position: relative;
    border-bottom-color: transparent !important;
    width: 100%;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc(100% - 24px);
      border-bottom: 1px solid rgba(217, 227, 236, .3); } }

  .partnerListItemDescription {
    color: white; }

  .partnerListItemDescription,
  .descriptionTextWrapper {
    display: flex;
    align-items: center; }

  .partnerListItemDescription {
    padding: 0px 24px; }

  .descriptionTextWrapper {
    flex-grow: 2;
    justify-content: space-between;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; }

  .partnerMaskWrapper {
    margin-right: 5px; } }

body[data-theme='dark'] {
  .partnerListItem {
    &::after {
      border-bottom: 1px solid var(--dt-qs-gray-20); } } }
