body[data-theme='light'],
body[data-theme='dark'] {
  .mobileErrorModal {
     :global {
      .ant-message-notice-content {
        border-radius: 20px; } } }

  .desktopErrorModal {
     :global {
      .ant-message-notice-content {
        border-radius: 5px; } } } }
