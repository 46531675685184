body[data-theme='light'],
body[data-theme='dark'] {
  .linkRow {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start; }

  .curatorsWrapper {
    display: flex;
    flex-direction: row;
    width: 100%; }

  .spinner {
    font-size: 14px !important;
    top: 6px;
    position: relative; }

  .curatorsField {
    max-width: calc(50% - 10px);
    flex: 0 0 calc(50% - 10px);
    position: relative; }

  .curatorsListTitle {
    position: absolute;
    width: 200px;
    right: -220px; }

  .curatorListWrapper {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #F2F4F6;
    display: flex;
    flex-wrap: wrap;
    gap: 8px; }

  .curatorsList {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-top: 90px;

    .list {
      margin-top: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: flex-start; } }

  .curatorsCustomScrollbar > div {
    display: flex;
    gap: 20px; }

  .curatorSceletonName {
    height: 15px;
    width: 70px;

    & > span {
      width: 100% !important;
      height: 100% !important; } }

  .curatorItem {
    padding: 12px;
    color: var(--font-black);
    border: 1px solid #F2F4F6; } }

body[data-theme='dark'] {
  .curatorListWrapper {
    border-top: 1px solid var(--dt-qs-gray-20); }

  .curatorItem {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--dt-qs-gray-20); } }
