body[data-theme='light'],
body[data-theme='dark'] {
  .flexRow {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    span {
      flex-grow: 2; } }

  .hoverUserReportRow {
    .actionsButtons {
      opacity: 0;
      transition: .25s ease; }

    &:hover {
      .actionsButtons {
        opacity: 1; } } }

  .expandedIcon {
    transition: 0.3s ease;
    float: left;
    fill: var(--font-black);

    &.expanded {
      transform: rotate(90deg); } }

  .expandedRowPartner {
    padding-left: 24px; }

  .expandedRowActivity {
    padding-left: 88px; }

  .clickableRow {
    cursor: pointer; }

  .lockedMonthIcon {
    fill: var(--primary-color); }

  .openedMonthIcon {
    fill: var(--green-2); }

  .userTitle {
    color: var(--primary-color); } }

