body[data-theme='light'],
body[data-theme='dark'] {
  .generalSearchPopover {
     :global {
      .ant-popover-inner-content {
        padding-left: 0; }

      .ant-popover-inner {
        border-radius: 5px; } } }

  .generalSearchButton {
    width: 150px;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    span {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      color: var(--font-black); } }

  .generalSearchButtonOpened {
    color: #fff;
    background-color: var(--primary-color) !important;

    &:hover {
      color: #fff;
      background-color: var(--primary-color);
      opacity: 0.8; }

    &:focus {
      color: #fff;
      background-color: var(--primary-color); }

    span {
      color: #fff; } }

  .generalSearchPopoverContent {
    display: flex;
    width: 700px;
    min-height: 400px;

    gap: 16px; }

  .generalSearchPopoverHeader {
    padding-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 16px;
    padding-left: 16px; }

  .filteredBlockTitle {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;

    color: var(--qs-gray); }

  .searchButton {
    line-height: 24px;
    svg {
      fill: #fff; } }

  .filteredBlockContent {
    display: flex;
    gap: 8px;
    flex-wrap: wrap; }

  .settingsBlock {
    flex-basis: 60%;
    max-width: 450px; }

  .popoverBlock {
    display: flex;
    flex-direction: column;
    flex-basis: 50%; }

  .searchSettingsHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

  .searchSettingsClearButton {
    display: inline-flex;
    align-items: center; }

  .clearButton {
    cursor: pointer;
    color: var(--qs-gray);
    font-size: 13px;
    font-weight: 500;
    line-height: 15px;

    display: flex;
    align-items: center;

    svg {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        svg {
          opacity: 0.5; } } } }

  .filteredItemsWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px; }

  .generalSearchInput {
    border-radius: 5px; }

  .userItemWrapper {
    display: flex;
    align-items: center;
    padding: 4px 0;
    gap: 8px;

    &:hover {
      color: var(--primary-color); } }

  .userItemWrapper {
    border-bottom: 1px solid #82888F1A; }

  .searchTagItem {
    display: inline-flex;
    align-items: center;
    border: none;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;

    &:hover {
       :global {
        .ant-tag-close-icon svg {
          fill: var(--primary-color); } } }

     :global {
      .ant-tag-close-icon {
        display: flex;
        align-items: center; } } }

  .employeeTagItemLayout {
    display: inline-flex;
    align-items: center;
    border: none; } }

body[data-theme='light'] {
  .searchTagItem {
    background-color: #fff;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05); } }

body[data-theme='dark'] {
  .filteredBlockTitle {
    color: var(--dt-qs-gray); } }

