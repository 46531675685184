body[data-theme='light'],
body[data-theme='dark'] {
  .vacationPage {
    padding-top: 20px; }

  .vacationChartLayout {
    display: flex;
    align-items: center;
    justify-content: center; }

  .vacationYearChartsLayout {
    display: flex;
    align-items: center; }

  .vacationAllTimeChartsLayout {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px; } }

