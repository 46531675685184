body[data-theme='light'],
body[data-theme='dark'] {
  .permissionCounter {
    margin-left: 5px;
    border-radius: 50%;
    color: white;
    background-color: var(--primary-color);
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 10px;
    transition: .25s ease;
    padding-top: 2px; }

  .permissionsSelectOverlay {
     :global {
      .ant-popover-content {
        .ant-popover-arrow {
          display: none; }

        .ant-popover-inner {
          border-radius: 5px; } } } }

  .permissionsSelectWrapper {
    position: relative;

     :global {
      .ant-popover-open {
        background-color: var(--primary-color) !important;
        color: white;

        .permissionCounter {
          background-color: white;
          color: rgba(0, 0, 0, 0.85); }

        svg {
          fill: white; } } } }

  .permissionsSelect {
    border-radius: 5px;
    background-color: #E5EAEE;
    display: flex;
    align-items: center;
    font-size: 14px;
    height: 40px;
    padding: 0px 8px;
    color: rgba(0, 0, 0, 0.85);
    cursor: pointer;
    transition: .25s ease;

    svg {
      position: relative;
      left: 8px; } }

  .employeeSelect {
    width: 150px;
    //background-color: #E5EAEE

    &.skills {
      width: 114px; }
    &.overdue {
      width: 128px; }

     :global {
      .filterSkillsWrapper {
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        line-height: 15px;
        justify-content: center; }

      .filterSkillsWrapper_desc {
        font-size: 10px; }

      //.ant-select-clear
 } }      //  background: #E5EAEE

  .employeeInput {
    width: 220px;
    //background-color: #fff

    //\:global
    //  .ant-input
 }    //  //background-color: #fff

  .selectDescription {
    display: inline-block;
    padding: 18px 0 12px 16px;
    font-size: 10px;
    line-height: 12px;
    color: #00000080; }

  .selectCheckboxFooter {
    padding: 12px 16px;
    background-color: var(--background-gray);
    border-top: 1px solid #82888F4D;

    .mainSkillCheckbox {
      font-size: 13px;
      color: #000; } }

  .filtersWrapper {
    padding-bottom: 14px;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    flex-wrap: wrap; }

  .addButton {
    padding: 6.4px 12px; }

  .filter {
    margin-left: 5px;
    display: flex;
    align-items: center; }

  .filterIcon {
    margin-right: 5px;
    opacity: .7;

    &.fontAwesomeIcon {
      font-size: 14px;
      color: var(--font-black); }

    &.svgIcon {
      fill: var(--font-black);
      width: 14px; } } }

body[data-theme='light'] {
  .employeeSelect {
    background-color: #E5EAEE;

     :global {}
    .ant-select-clear {
      background: #E5EAEE; } }

  .employeeInput {
    background-color: #fff;

     :global {
      .ant-input {
        background-color: #fff; } } } }

body[data-theme='dark'] {
  .permissionsSelect {
    background-color: var(--dt-qs-dark);
    color: white; }

  .selectDescription {
    color: #ffffff80; } }
