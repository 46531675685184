body[data-theme="dark"],
body[data-theme="light"] {
  .confirmModal {
     :global {
      .ant-drawer-body {
        padding: 0;
        position: relative; }

      .ant-modal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center; } }

    .checkbox {
      position: absolute;
      left: 22px; }

    .content {
      ul {
        padding: 0; }

      li {
        list-style: none;
        margin-left: 5px;
        margin-top: 5px;
        display: flex;
        align-items: center;

        .dot {
          color: var(--primary-color);
          margin-right: 5px; } } } } }
