body[data-theme='light'],
body[data-theme='dark'] {
  .hslPicker {
    background-color: black;
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px; }

  .hexField {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .hexTitle {
    color: white;
    font-size: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center; }

  .hexInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background: #1E1E1E;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: white; }

  .hexInputRect {
    width: 32px;
    height: 32px;
    background: #eee;
    border-radius: 4px;
    margin-right: 10px; }

  .prefix {
    color: #888888; }

  .hexInput {
    background: none;
    border: none;
    width: 70px; }

  .slider {
    position: relative;
    height: 40px;
    width: calc(100% + 40px);
    display: flex;
    align-items: center;
    margin-left: -20px;
    margin-right: -20px; }

  .sliderInput {
    -webkit-appearance: none;
    appearance: none;
    height: 20px;
    width: 100%;

    &.hueSelector {
      background: -moz-linear-gradient(90deg, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
      background: -ms-linear-gradient(90deg, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
      background: -o-linear-gradient(90deg, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
      background: -webkit-linear-gradient(90deg, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
      background: linear-gradient(90deg, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%); }

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 24px;
      border-radius: 8px;
      border: 3px solid #FFFFFF;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .4);
      cursor: pointer; }

    &::-moz-range-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 24px;
      border-radius: 8px;
      border: 3px solid #FFFFFF;
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, .2);
      cursor: pointer; } } }

