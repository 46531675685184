body[data-theme='light'],
body[data-theme='dark'] {
  .timeSelect {
    width: 200px;

    &.skills {
      width: 114px; }
    &.overdue {
      width: 128px; }

     :global {
      .filterSkillsWrapper {
        padding: 5px 0;
        display: flex;
        flex-direction: column;
        line-height: 15px;
        justify-content: center; }

      .filterSkillsWrapper_desc {
        font-size: 10px; } } }

  .selectDescription {
    display: inline-block;
    padding: 18px 0 12px 16px;
    font-size: 10px;
    line-height: 12px;
    color: #00000080; } }

body[data-theme='light'] {
  .timeSelect {
    background-color: #E5EAEE; } }

body[data-theme='dark'] {
  .selectDescription {
    color: white; } }
