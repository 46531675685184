body[data-theme='light'],
body[data-theme='dark'] {
  .specialistsRadio {
    position: relative;
    padding: 16px 0 0 20px;
    box-shadow: 0px -2px 0px var(--background-gray) inset;

     :global {
      .ant-radio {
        display: none; }

      .ant-radio-wrapper-checked {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color); } }

    &_radio-label {
      display: inline-block;
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      margin-right: 20px;
      margin-bottom: 0;
      padding-bottom: 27px;
      cursor: pointer;
      color: rgba(#000, 0.3);
      border-bottom: 2px solid transparent;

      &:hover {
        transition: 0.25s ease;
        color: var(--primary-color); } } } }

body[data-theme='dark'] {
  .specialistsRadio {
    box-shadow: 0px -2px 0px var(--dt-qs-gray-20) inset; } }
