body[data-theme='light'],
body[data-theme='dark'] {
  .skill-element_users-skill {
    max-width: calc(100% - 10px);
    min-width: 20px;
    min-height: 30px;
    font-size: 13px;
    color: white;
    width: auto;
    position: relative;
    transition: .25s ease;
    margin: 5px;
    display: inline-block;
    outline: none;
    background-color: transparent !important;
    border-radius: 15px;
    transform: translate(0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &--is-draggable {
      cursor: grab; }

    &--is-dragging,
    &--is-loading {
      .skill-element_users-skill-inner {
        color: transparent;
        background-color: white !important;

        .skill-element_preloader-icon {
          opacity: 1; }

        & > span,
        .skill-element_attitudes-icons,
        .skill-element_attitudes-icons > * {
          background-color: var(--break-gray);
          border-radius: 4px;
          display: inline-block;
          height: 18px; }

        .skill-element_attitudes-icons > * {
          opacity: 0; } } }

    &--is-dragging .skill-element_users-skill-inner {
      background-color: var(--break-gray) !important; }

    &--isReadOnlyMode {
      cursor: default;

      .attitudes-placeholder {
        cursor: default; } }

    &--isReadOnlyMode.skill-element_users-skill--empty {
      .skill-element_users-skill-inner {
        padding: 5px 10px; }

      .skill-element_label {
        max-width: none; } }

    &--disabled {
      opacity: 0.5;
      cursor: default;

      &:hover {
        cursor: grab;
        opacity: 1; } }

    &--popover-visible {
      .attitudes-placeholder {
        width: 18px;
        height: 18px;
        cursor: pointer;
        opacity: 1;
        transition: 0.25s ease; } } }

  .skill-element_users-skill-inner {
    border-radius: 15px;
    position: relative;
    align-items: center;
    padding: 5px 6px 5px 10px;
    background-color: var(--break-gray);
    display: flex;
    min-width: 50px;
    justify-content: center;
    height: 100%;
    transition: .25s ease; }

  .skill-element_event-icons,
  .skill-element_attitudes-icons {
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer; }

  .skill-element_attitudes-icons--non-interactive {
    cursor: default; }

  .skill-element_button {
    border: none;
    background: none;
    box-shadow: none;
    outline: none;
    padding: 0;
    margin: 0; }

   :global {
    @keyframes user-skill-preloader-animation {
      from {
        transform: translate(-50%, -50%) rotate(0deg); }

      to {
        transform: translate(-50%, -50%) rotate(360deg); } } }

  .drag-indicator {
    transition: 0.25s ease;
    min-width: 9px;
    max-width: 9px;
    opacity: 0;
    cursor: grab; }

  .skill_delete-icon {
    opacity: 0.5;
    margin-left: 4px;
    margin-right: 5px;
    transition: opacity 0.3s ease;
    cursor: pointer; }

  .drag-indicator-placeholder {
    width: 9px;
    height: 9px; }

  .attitudesPlaceholder {
    min-width: 18px;
    max-width: 18px;
    height: 18px;
    cursor: pointer;
    opacity: 0.5;
    transition: 0.25s ease;

    &:hover {
      opacity: 1; } }

  .skill-element_users-skill {
    .skill-element_users-skill-inner {
      &:hover {
        .skill_delete-icon {
          opacity: 1; } } } }

  .skill-element_label {
    margin: 0 5px;
    text-overflow: ellipsis;
    font-weight: 500;
    line-height: 15px;
    max-width: calc(100% - 20px);
    white-space: nowrap;
    overflow: hidden;
    display: block; } }

body[data-theme='dark'] {
  .skill-element_users-skill {
    &--is-dragging .skill-element_users-skill-inner {
      background-color: var(--dt-qs-gray-20) !important; } } }
