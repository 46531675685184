body[data-theme='light'],
body[data-theme='dark'] {
  .dayComponent {
    flex: 0 0 calc(100% / 7);
    background: none;
    outline: none;
    border: none;
    box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, .2);
    color: white;
    font-weight: bold;
    padding: 6px;
    position: relative;
    background-size: 18.53px 24.9px;
    cursor: pointer;

    &:after {
      content: '';
      width: 0px;
      height: 0;
      opacity: 0;
      transition: .25s ease;
      position: absolute;
      bottom: 0px;
      right: 0px;
      border-right: 7px solid var(--primary-color);
      border-top: 7px solid transparent;
      background-color: {} }

    .inner {
      width: 100%;
      padding-bottom: 100%;
      border-radius: 50%;
      position: relative;
      color: black; }

    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%); }

    &.edited:after {
      opacity: 1; }

    &.holiday {
      .inner {
        color: var(--primary-color); } }

    &.recorded {
      .inner {
        opacity: 1; } }

    &.highlighted {
      background-color: #eee !important;

      .inner {
        opacity: 1; } }

    &.cutDay {
      background-color: rgb(242, 244, 246);

      .inner {
        opacity: .35; } }

    &.statusChangingMode {
      &.cutDay {
        pointer-events: none; }

      &:not(.cutDay):hover {
        .date {
          animation: trembling .4s;
          display: block; } } }

    &.currentMonth {
      &.cutDay {
        background-image: linear-gradient(55deg, #f5f3d3 9.38%, #f8f7e0 9.38%, #f8f7e0 50%, #f5f3d3 50%, #f5f3d3 59.38%, #f8f7e0 59.38%, #f8f7e0 100%); }

      .inner {
        opacity: 1; } }

    &:not(.currentMonth) {
      &:not(.cutDay) {
        background-image: linear-gradient(55deg, #f5d5d3b5 9.38%, #f8e0e08f 9.38%, #f8e0e08f 50%, #f5d5d3b5 50%, #f5d5d3b5 59.38%, #f8e0e08f 59.38%, #f8e0e08f 100%);

        .inner {
          opacity: 1; } } } }

  @keyframes trembling {
    0% {
      transform: rotate(0deg); }

    30% {
      transform: rotate(10deg); }

    70% {
      transform: rotate(-10deg); }

    100% {
      transform: rotate(0deg); } } }

body[data-theme='dark'] {
  .dayComponent {
    box-shadow: inset 0px 0px 1px 0px rgba(255, 255, 255, .3);

    .inner {
      color: white; }

    &.cutDay {
      background-color: #00000033; }

    &.highlighted {
      background-color: #00000069 !important; }

    &.currentMonth {
      &.cutDay {
        background-image: linear-gradient(55deg, #574d34 9.38%, transparent 9.38%, transparent 50%, #574d34 50%, #574d34 59.38%, transparent 59.38%, transparent 100%);

        .inner {
          color: white; } }

      .inner {
        opacity: 1; } }

    &:not(.currentMonth) {
      &:not(.cutDay) {
        background-image: linear-gradient(55deg, #63403eb5 9.38%, transparent 9.38%, transparent 50%, #63403eb5 50%, #63403eb5 59.38%, transparent 59.38%, transparent 100%);

        .inner {
          color: white; } } } } }
