body[data-theme='light'],
body[data-theme='dark'] {
  .deleteConfirmModal {
    position: absolute;
    left: 8px;
    bottom: 0;
    top: auto;

     :global {
      .ant-modal-content {
        border-radius: 20px;
        padding: 2px 24px 24px; }

      .ant-modal-confirm-btns {
        display: flex;
        flex-direction: row; } } }

  .userHourModal {
    max-height: calc(100% - 15px);

     :global {
      .ant-modal-content {
        border-radius: 20px;
        padding: 24px; }

      .ant-modal-footer {
        display: none; } } }

  .titleWrapper {
    display: flex;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 24px;
    border-bottom: 1px solid #d9e3ec4d; }

  .title {
    margin: 0 0 0 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;

    color: #232E39; }

  .innerPanel {
    position: relative;
    overflow: hidden;
    margin: 0 -24px -24px;

    &.main {
      .mainPanel {
        position: relative; }

      .replacePanel {
        transform: translateX(100%); } }

    &.replace {
      height: calc(100vh - 92px);

      .mainPanel {
        transform: translateX(-100%); }

      .replacePanel {
        position: relative; } } }

  .mainPanel,
  .replacePanel {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
    padding: 0px 24px 24px;
    border-radius: 0px 0px 20px 20px;
    transition: .25s ease; }

  .replacePanel {
    color: white; } }

body[data-theme='light'] {
  .replacePanel {
    background-color: #232E39; } }

body[data-theme='dark'] {
  .titleWrapper {
    border-bottom: 1px solid var(--dt-qs-gray-20); }

  .title {
    color: var(--font-white-85); } }
