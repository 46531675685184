body[data-theme='light'],
body[data-theme='dark'] {
  .sidebarHamburger {
    opacity: 0;
    visibility: hidden;
    position: absolute;
    top: 108px;
    left: 0;
    padding: 2px 7px;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    transition: opacity 0.2s ease;

    &:hover {
      svg {
        fill: var(--primary-color); } }

    &:global(.ant-popover-open) {
      svg {
        fill: var(--primary-color); } } }

  .showHamburger {
    opacity: 1;
    visibility: visible; }

  .sidebarMenuTitle {
    white-space: nowrap;
    writing-mode: vertical-rl; } }

