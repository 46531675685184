body[data-theme='light'],
body[data-theme='dark'] {
  .partnerLogoWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
    margin-bottom: 24px;
    gap: 15px; }

  .partnerLogo {
    width: 90px !important;
    height: 90px !important;
    border-radius: 50% !important; }

  .partnerDescription {
    display: flex;
    flex-direction: column;
    gap: 8px; }

  .partnerTitle {
    font-family: Gilroy;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: var(--font-black); }

  .partnerId {
    font-family: Roboto;
    font-size: 13px;
    line-height: 15px;
    text-align: center;
    color: #727981; } }

body[data-theme='dark'] {
  .partnerId {
    color: var(--dt-qs-gray); } }
