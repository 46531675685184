body[data-theme='light'],
body[data-theme='dark'] {
  .specialists-settings_exp-select {
    width: 100%;
    height: 38px;

     :global {
      .ant-select-selector {
        height: 100%; } } }

  .experience-skill-tag {
    transition: opacity 0.25s ease;

    &.focused {
      opacity: 0.3; } } }

body[data-theme='light'] {
  .specialists-settings_dropdown {
    outline: 1px solid #E8EDF1; } }
