body[data-theme='light'],
body[data-theme='dark'] {
  .commentTextWrapper {
    background: #F2F4F6;
    border-radius: 5px;
    padding: 7px 8px;
    resize: none;
    border: 1px solid #F2F4F6;
    outline: none;
    width: 100%;
    transition: .25s ease;
    position: relative;
    cursor: pointer; }

  .commentText {
     :global {
      .wmde-markdown {
        background: none;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 14px;
        margin: 0;
        white-space: pre-line;
        overflow-wrap: break-word;

        .copied {
          display: none !important; }

        a {
          color: var(--primary-color); }

        .code-highlight {
          background-color: var(--button-hover-gray); } } }

    & + .commentText {
      margin-top: 5px; }

    &:focus {
      background: white; } }

  .comment {
    position: relative;
    width: calc(100% - 16px);
    padding-top: 18px;

    & + .comment {
      margin-top: 5px; }

    &.nonInteractive {
      .commentTextWrapper {
        cursor: default; } }

    .commentInfo {
      position: absolute;
      right: 6px;
      top: 0;
      display: flex;
      align-items: center;
      font-size: 12px;
      display: flex;
      justify-content: flex-end;
      color: rgba(0, 0, 0, .5);
      width: 100%; }

    .timeSelector {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      color: var(--font-black); }

    .actions {
      display: flex;
      transition: .25s ease;
      opacity: 0;
      z-index: 1;
      overflow: hidden;
      top: 25px;
      right: 0px;
      position: absolute;
      background-color: #F2F4F6; }

    .actionButton {
      border: none;
      color: var(--qs-gray);
      background: none;
      box-shadow: none;
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      pointer-events: none;
      transform: translateX(50px);
      transition: .25s ease;
      z-index: 1;
      display: flex;
      justify-content: center;
      // margin-bottom: 2px

      &::after,
      &::before {
        display: none; }

      svg {
        height: 18px; }

      & + .actionButton {
        margin-left: 5px; } }

    &:not(.nonInteractive):hover {
      .actions {
        opacity: 1; }

      .actionButton {
        pointer-events: all;
        transform: translateX(0px); }

      .commentText:after {
        opacity: 1; } }

    &.withoutButtonHide {
      .actionButton {
        opacity: 1;
        pointer-events: none; } } } }

body[data-theme='dark'] {
  .commentTextWrapper {
    background: var(--dt-qs-dark);
    border: 1px solid var(--dt-qs-gray-20); }

  .comment {
    .commentInfo {
      color: white; }

    .actions {
      background-color: var(--dt-qs-dark); }

    &:hover {
      .actionButton svg {
        fill: var(--primary-color); } } }

  .actionButton svg {
    fill: var(--dt-qs-gray); }

  .commentText {
     :global {
      pre {
        background: none; }

      .wmde-markdown {
        color: white;

        .code-highlight {
          color: white;
          background-color: var(--primary-backround-black); } } } } }
