body[data-theme='light'],
body[data-theme='dark'] {
  .hexField {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .hexTitle {
    color: white;
    font-size: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center; }

  .hexInputWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background: #1E1E1E;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: white;

    &.withHexError {
      box-shadow: inset 0 0 0px 1px rgb(255, 0, 0); } }

  .hexInputRect {
    width: 32px;
    height: 32px;
    background: #eee;
    border-radius: 4px;
    margin-right: 10px; }

  .prefix {
    color: #888888; }

  .hexInput {
    background: none;
    border: none;
    width: 70px; } }
