body[data-theme='light'],
body[data-theme='dark'] {
  .partnerInfoTab {
    padding: 16px 34px 34px 34px; }

  .partnerInfoTabHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 22px;
    margin-bottom: 28px;
    border-bottom: 1px solid var(--grey-background-break-gray); }

  .requesterSelectDisabled {
     :global {
      .ant-select-selector {
        padding-left: 0 !important; } } }

  .requesterSelect {
     :global {
      .ant-select-arrow svg {
        right: 10px; } } }

  .transition {
    transition: .25s ease; }

  .hiddenSection {
    opacity: .2;
    pointer-events: none; }

  .headerTitle {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: var(--font-black); }

  .fieldTitle {
    font-size: 13px;
    line-height: 15px;
    color: var(--qs-gray); }

  .primaryCard {
    min-height: 229px;
    display: flex;
    flex-direction: column;
    gap: 20px; }

  .titledCardWrapper {
    margin-top: 35px; }

  .titledCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 113px; }

  .fieldWrapper {
    width: 100%; }

  .partnerInput {
    width: 100%;
    color: var(--font-black);
    -webkit-text-fill-color: var(--font-black); // black text render for Safari
    padding: 11px 12px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid var(--background-gray);
    background-color: var(--background-gray);

    &:disabled {
      cursor: default;
      padding-left: 0;
      background: transparent;
      color: var(--font-black);
      border: 1px solid transparent;

      &:hover {
        border: 1px solid transparent; } } }

  .partnerSelect {
    width: 100%; }

  .popoverButton {
    height: auto;
    text-align: left;
    border: none;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid var(--dt-qs-gray-20); } } }

body[data-theme='dark'] {
  .fieldTitle {
    color: var(--dt-qs-gray); } }
