body[data-theme='light'],
body[data-theme='dark'] {
  .basic-table {
    width: auto;

     :global {
      .ant-table.ant-table-bordered > .ant-table-container,
      .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
      .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
      .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
      .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
        border-radius: 0; }

      .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
      .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th {
        border-bottom: 1px solid transparent; }

      .ant-table.ant-table-bordered > .ant-table-container {
        border-top: none; }

      .ant-table-column-sorter-inner {
        display: none; }

      .ant-table-column-has-sorters {
        &:hover {
          .placeholder-sort-icon {
            opacity: 1; } } }

      .borderless {
        border-right: 1px solid transparent !important; }

      .action-column {
        padding: 0; }

      .action-column,
      .ant-table-cell-scrollbar {
        box-shadow: none; }

      .ant-spin-nested-loading > div > .ant-spin {
        max-height: none; } }

    table {
      border-spacing: 0;

      tr {
        &:last-child {
          td {
            border-bottom: 0; } } }

      th,
      td {
        margin: 0; }

      th {
        font-family: Gilroy;
        font-style: normal;
        font-weight: bold;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        color: #727981; }

      td {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;

        &:last-child {
          border-right: 0; } } } } }

body[data-theme='light'] {
  .basic-table {
     :global {
      table {
        background: #fff;
        border-top: 1px solid #e8edf1; } } }
  table {
    th {
      background: #F2F4F6; }
    td {
        background-color: #fff; } }

   :global {
    .ant-table.ant-table-bordered > .ant-table-container,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
    .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td {
      border-color: #E8EDF1; }

    .ant-table-thead th.ant-table-column-sort {
      background-color: var(--background-gray); } } }


body[data-theme='dark'] {
  .basic-table {
     :global {
      .ant-table-content {
        table {
          background: #232e39;
          border-top: 1px solid rgba(155,163,172,.2); } }

      .ant-table-placeholder {
        color: var(--dt-qs-gray); }
      .ant-table-empty {
        .ant-table-cell {
          &:hover {
            background-color: var(--dt-qs-gray-20); } } }

      .ant-pagination-options {
        .ant-select-selector {
          background-color: transparent;
          border-color: #434343;
          &:hover {
            border-color: var(--primary-color); } } }

      th.ant-table-column-sort {
        svg {
          fill: var(--primary-color); } }

      td.ant-table-column-sort {
        background-color: transparent; } } } }
