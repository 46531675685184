body[data-theme='light'],
body[data-theme='dark'] {
  .entitiesSettings {
    padding-bottom: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; }

  .addEntitiesButton {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

  .newEntityFormItem {
    margin: 0;
    display: flex;
    justify-content: flex-end; }

  .addEntitiesPopover {
    padding-top: 0;

     :global {
      .ant-popover-arrow {
        display: none; }

      .ant-popover-inner {
        border-radius: 5px 0 5px 5px; } } }

  .openedNewEntityButton {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }

  .newEntityInput {
    width: 340px; } }
