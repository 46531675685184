body[data-theme='light'] .noAvatar,
body[data-theme='dark'] .noAvatar {
  transform: scale(2); }

body[data-theme='light'] .noAvatar,
body[data-theme='light'] .avatarImage,
body[data-theme='dark'] .noAvatar,
body[data-theme='dark'] .avatarImage {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto; }
  body[data-theme='light'] .noAvatar.hidden,
  body[data-theme='light'] .avatarImage.hidden,
  body[data-theme='dark'] .noAvatar.hidden,
  body[data-theme='dark'] .avatarImage.hidden {
    opacity: 0; }

body[data-theme='light'] .qsAvatarWrapper,
body[data-theme='dark'] .qsAvatarWrapper {
  border-radius: 50%;
  overflow: hidden;
  background-color: var(--background-gray);
  position: relative; }
  body[data-theme='light'] .qsAvatarWrapper.small,
  body[data-theme='dark'] .qsAvatarWrapper.small {
    min-width: 38px;
    max-width: 38px;
    height: 38px; }
  body[data-theme='light'] .qsAvatarWrapper.medium,
  body[data-theme='dark'] .qsAvatarWrapper.medium {
    min-width: 90px;
    max-width: 90px;
    height: 90px; }
    body[data-theme='light'] .qsAvatarWrapper.medium svg,
    body[data-theme='dark'] .qsAvatarWrapper.medium svg {
      font-size: 30px; }
  body[data-theme='light'] .qsAvatarWrapper img,
  body[data-theme='dark'] .qsAvatarWrapper img {
    display: block; }
  body[data-theme='light'] .qsAvatarWrapper svg,
  body[data-theme='dark'] .qsAvatarWrapper svg {
    color: var(--icon-gray); }
