body[data-theme='light'],
body[data-theme='dark'] {
  .profileListLayout {
    display: none;
    padding: 0 0 0 10px;
    flex-direction: column;
    flex-grow: 2; }

  .profileListLayoutVisible {
    display: flex; }

  .profileListWrapper {
    position: relative;
    padding: 10px 0 0 6px;
    flex-grow: 2; }

  .userListItem {
    display: flex;
    margin-right: 10px;
    justify-content: flex-start;
    gap: 4px;
    cursor: pointer;
    transition: color 0.3s ease;

    &:hover {
      color: var(--primary-color); } }

  .userAvatar,
  .companyAvatar {
    width: 24px;
    height: 24px;
    border-radius: 50%; }

  .userListName {
    width: 180px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  .employeeListSpinner {
    position: absolute;
    width: 100px;
    height: 100px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; }

  .searchEmployeeWrapper {
    padding-right: 10px; }

  .filledInput {
    border: 1px solid var(--break-gray);
    background-color: var(--white) !important;

     :global {
      .ant-input {
        background-color: var(--white); } } } }

body[data-theme='dark'] {
  .filledInput {
    border: 1px solid var(--border-gray-30); }
  .userListItem {
    border-color: var(--dt-qs-gray-20); } }
