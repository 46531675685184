$swatchGap: 12px;
$swatchWidth: 32px;
$swatchBorderWidth: 2px;

body[data-theme='light'],
body[data-theme='dark'] {
  .colorPickerPopover {
    margin: -8px; }

  .paletteColorContent {
    width: 200px; }

  .swatchesPicker {
    display: flex;
    flex-wrap: wrap;
    gap: $swatchGap;
    width: $swatchWidth * 5 + $swatchGap * 4; }

  .colorPickerTabs {
    background-color: #222222;
    border-radius: 5px 5px 0 0;
    padding: 8px; }

  .slider {
    width: 100%;
    position: relative; }

  .sliderInner {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 25px;
    background: #d3d3d3;
    outline: none;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 25px;
      height: 15px !important;
      background: #04AA6D;
      cursor: pointer; }

    &::-moz-range-thumb {
      width: 25px;
      height: 15px;
      border-radius: 50%;
      background: #04AA6D;
      cursor: pointer; } }

  .hueSatBrPickerWrapper {
    position: relative;
    background-color: black !important;
    border-radius: 0 !important;
    width: calc(100% + 40px) !important;
    margin: -20px;

    & * {
      user-select: none !important; }

    & > div:last-child > div:first-child > div:last-child > div {
      padding: 8px 0px !important; }

    & > div:last-child {
      padding: 15px 0px 0px !important;

       :global {
        .hue-horizontal > div > div {
          height: 18px !important;
          background: transparent !important;
          border: 3px solid white; } }

      & > div:first-child > div:first-child,
      & > div:last-child {
        display: none !important; } } }

  .colorPickerButton {
    font-size: 13px;
    border: none;
    box-shadow: none;
    padding: 2px 5px 3px;
    height: 25px;
    color: #909090;
    background: transparent;

    &.active {
      color: white; } }

  .colorPickerContent {
    background-color: #000000;
    border-radius: 0 0 5px 5px;
    padding: 20px; }

  .swatch {
    width: $swatchWidth;
    height: $swatchWidth;
    border-radius: 4px;
    position: relative;
    cursor: pointer;
    border: none;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:disabled {
      // opacity: .5
      cursor: not-allowed;
      position: relative;

      &::after,
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px; }

      &::after {
        background: repeating-linear-gradient(45deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4) 2px, rgba(0, 0, 0, 0) 2px, rgba(0, 0, 0, 0) 21px); }

      &::before {
        background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, .4), rgba(0, 0, 0, .4) 2px, rgba(0, 0, 0, 0) 2px, rgba(0, 0, 0, 0) 21px); } }

    .swatchInner {
      border-radius: 4px;
      width: calc(100% - 4px);
      height: calc(100% - 4px); } }

  .hueSatBrPickerHex {
    padding: 20px;
    margin: 20px -20px -20px;
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .hueSatBrPickerText {
    color: white;
    font-size: 24px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center; }

  .hueSatBrPickerHexDisplay {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px;
    background: #1E1E1E;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: white; }

  .hueSatBrPickerHexDisplay,
  .hexRectangle {
    border-radius: 4px; }

  .hexRectangle {
    width: 32px;
    height: 32px;
    background: #eee;
    border-radius: 4px;
    margin-right: 10px; }

  .hexTextWrapper {
    padding: 0 12px;
    font-size: 16px;
    color: black;
    margin: 0; }

  .hexInput {
    background: none;
    border: none;
    width: 70px; }

  .hexSymbol {
    opacity: .5; }

  .hexText {
    display: inline-block;
    width: 48px; }

  .customizationPopover {
    width: auto;
    z-index: 1001;

     :global {
      .ant-popover-inner-content {
        padding: 8px;
        background-color: #222222;
        border-radius: 5px; }

      .ant-popover-arrow-content {
        background-color: #222222; } } } }

body[data-theme='dark'] {
  .hueSatBrPickerText {
    color: white; }

  .hueSatBrPickerWrapper {
    & > div:last-child {
      background-color: black; } }

  .hexTextWrapper {
    color: white; } }

body[data-theme='light'] {
  .hexTextWrapper,
  .hueSatBrPickerText {
    color: white; } }
