body[data-theme='light'],
body[data-theme='dark'], {
  // hardcoded left position, instead of using antd variables.
  // this happened cuz antd switch styles couldn't win vs body[data-theme='dark'] selector
  // left: calc(100% - @switch-pin-size - @switch-padding);
  // FOR NORMAL SIZE.
  .trackerSwitch:global(.ant-switch-checked) {
     :global {

     .ant-switch-handle {
        left: calc(100% - 18px - 2px); } } } }
