
@import '../../../../../../../../../../_shared/SkillTag/helpers/styles.module.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .skill-searcher_dropdown {
    .add-new-skill {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 32px;
      padding: 5px 12px;
      color: rgba(0, 0, 0, 0.85);
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
      transition: background 0.3s ease;

      &:hover {
        background-color: #f5f5f5; }

      &_icon {
        font-size: 12px;
        color: rgba(0, 0, 0, 0.25); } }

     :global {
      .ant-select-item[label] {
        display: none; } } }

  .new-category-item {
    position: relative;
    display: block;
    min-height: 32px;
    padding: 5px 12px;
    color: rgba(0, 0, 0, 0.85);
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    cursor: pointer;
    transition: background 0.3s ease; }

  .skillset_skill-list {
    margin: -3px;
    height: 100%;

     :global {
      .ant-select-selector {
        height: 100%;
        min-height: 44px; } } }

  .skillset_skill-tag {
    padding: 7.5px 10px;

     :global {
      .delete-icon {
        color: #fff; } } } }

body[data-theme='dark'] {
  .skill-searcher_dropdown {
    .add-new-skill {
      span {
        color: white; }

      .add-new-skill_icon {
        color: white; }

      &:hover {
        background: rgba(255, 255, 255, 0.08); } } } }
