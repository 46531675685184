body[data-theme='light'],
body[data-theme='dark'] {
  .curatorCard {
    display: flex;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 14px 10px 18px;
    width: calc(50% - 10px);
    height: 64px;
    flex-shrink: 0;
    gap: 14px;

    img {
      margin: 0; }

    &:hover {
      .buttons {
        opacity: 1; } } }

  .curatorName {
    flex-grow: 2;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    text-align: left; }

  .buttons {
    opacity: 0;
    transition: .25s ease opacity;

    &.showed {
      opacity: 1; } }

  .curatorButton {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 10px; } }

body[data-theme='dark'] {
  .curatorCard {
    background: var(--dt-second-background-color);
    color: white; } }
