body[data-theme='light'],
body[data-theme='dark'] {
  .attitudes-cell {
    display: flex;

    &_exp {
      margin-right: 8px; }

    &_attitudes {
      svg {
        margin: 0 2px; } } } }
