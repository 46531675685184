body[data-theme='light'],
body[data-theme='dark'] {
  .qsButton {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    border-radius: 5px;
    border: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;

    &:hover {
      svg {
        fill: var(--primary-color); } } }

  .qsButtonPrimary {
    display: inline-flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    line-height: 15px;
    border-radius: 5px;
    border: none;
    font-weight: 500;

    &:focus {
      background-color: var(--primary-color); } } }

.iconTextWrapper {
  display: inline-flex;
  align-items: center;
  gap: 5px; }

body[data-theme='light'] {
  .qsButton {
    background-color: #E5EAEE;
    color: var(--qs-gray);

    &:focus {
      background-color: #E5EAEE;
      color: var(--qs-gray); }

    &:hover {
      background-color: #E5EAEE;
      color: var(--primary-color); } }

  .qsButton,
  .qsButtonPrimary {
    &:disabled {
      opacity: 0.5; } } }

body[data-theme='dark'] {
  .qsButton {
    background-color: var(--dt-qs-dark);

    svg {
      fill: white; }

    &:hover {
      background-color: var(--dt-qs-gray-20);
      color: var(--primary-color); } } }
