body[data-theme='light'],
body[data-theme='dark'] {
  .fullHeight {
    height: 100%; }

  .fieldCard {
    display: flex;
    flex-direction: column;
    gap: 15px; }

  .fieldCardBody {
    padding: 24px;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
    background-color: #fff; }

  .fieldCardTitle {
    font-family: Gilroy;
    font-size: 15px;
    line-height: 18px;
    height: 18px;
    color: var(--font-black); } }

body[data-theme='dark'] {
  .fieldCardBody {
    background-color: var(--dt-second-background-color); } }
