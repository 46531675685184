@import '../../../../MonthsSelectorPanel/components/YearSelector/helpers/styles.module.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .spinner {
    @extend .spinner;

    margin-right: 2px; }

  .infoPanel {
    display: flex;
    justify-content: center;
    margin: 0px 14px; }

  .infoPanelPart {
    display: flex;
    align-items: center;
    padding: 10px 0px; }

  .infoPanelLabel {
    font-family: Gilroy, Helvetica Neue;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 15px;
    color: #333333;
    margin: 0; }

  .infoPanelIcon {
    margin-right: 3px; }

  .resultLabel {
    color: var(--primary-color); }

  .total {
    padding-right: 14px;
    border-right: 1px solid #F2F4F6; }

  .holidays {
    padding-left: 15px; } }

body[data-theme='dark'] {
  .infoPanelIcon {
    fill: var(--dt-qs-gray); }

  .infoPanelLabel {
    color: var(--dt-qs-gray); }

  .total {
    border-color: var(--dt-qs-gray-20); } }
