body[data-theme="dark"],
body[data-theme="light"] {
  .modalButton {
    font-weight: 700; }

  .input {
    font-size: 15px;

    &.withError {
      box-shadow: 0px 0px 0px 1px red; } }

  .errorStr {
    font-size: 12px;
    color: red; }

  .confirmModal {
     :global {
      .ant-drawer-body {
        padding: 0;
        position: relative; }

      .ant-modal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center; } }

    .checkbox {
      position: absolute;
      left: 22px; }

    .content {
      &.withoutTopPadding {
        margin-top: -22px; }

      ul {
        padding: 0; }

      li {
        list-style: none;
        margin-left: 5px;
        margin-top: 5px;
        display: flex;
        align-items: center;

        .dot {
          color: var(--primary-color);
          margin-right: 5px; } } } } }
