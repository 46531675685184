@import 'src/helpers/styles/mixins.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .reportMonthPicker {
    margin: 0; }

  .userReportLayoutSettingsTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 18px;
    border-bottom: 1px solid var(--grey-background-break-gray);
    flex-wrap: wrap;
    gap: 8px; }

  .employeeHourReportSearch {
    width: 220px; }

  .customDatesWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap; }

  .datepickerDividerLoading {
    opacity: 0.2; }

  .replaceButton {
    transition: .125s ease;

    & * {
      transition: .125s ease; } } }

body[data-theme='light'] {
  .employeeHourReportSearch {
    background-color: #fff;

     :global {
      .ant-input {
        background-color: #fff; } } } }
