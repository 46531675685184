$infoBoxSize: 410px;

body[data-theme='light'],
body[data-theme='dark'] {
  .infoBox {
    display: flex;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s ease;
    width: $infoBoxSize;

    &:hover {
      background-color: #F4F8FC;

      .infoBoxSection:last-of-type {
        border-right-color: transparent; } } }

  .infoBoxOpened {
    transition: 0.3s ease;
    background-color: #F4F8FC;

    .infoBoxSection:last-of-type {
      border-right-color: transparent; } }

  .infoBoxSection {
    border-right: 1px solid #d1dbe380;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    height: 40px;

    svg {
      margin-right: 4px;
      fill: #727981; } }

  .infoBoxText,
  .infoBoxHours {
    font-family: Gilroy, Helvetica Neue;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap; }

  .infoBoxHours {
    color: var(--primary-color); }

  .infoBoxText {
    margin-right: 4px; }

  .infoPanel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    transition: opacity 0.3s ease; }

  .infoPanelDisabled {
    opacity: 0.2;
    pointer-events: none; }

  .closeMonthButton {
    line-height: 13px;
    margin-left: 20px; }

  .spinner {
    color: var(--primary-color); }


  .closeMonthPlaceholder {
    width: 125px;
    height: 32px;
    background-color: #72798133;
    margin-left: 20px; }

  .infoBoxOverlay {
    width: $infoBoxSize;

     :global {
      .ant-popover-arrow {
        display: none; } } } }

body[data-theme='light'] {
  .infoBoxSection {
    border-right: 1px solid #d1dbe380; }

  .infoBoxText {
    color: var(--qs-gray); }

  .closeMonthButton {
    &:disabled {
      background-color: #fff;
      border: 1px solid #72798180;
      cursor: default;

      &:hover {
        border: 1px solid #72798180;
        background-color: #fff; }

      svg {
        fill: #72798180; } } } }

body[data-theme='dark'] {
  .infoBoxSection {
    border-right: 1px solid var(--dt-qs-gray-20); }

  .infoBox {
    &:hover {
      background-color: var(--dt-qs-gray-20); } }

  .infoBoxOpened {
    background-color: var(--dt-qs-gray-20); }

  .infoBoxText {
    color: var(--dt-qs-gray); } }
