body[data-theme='light'] .spinner-bar-container,
body[data-theme='dark'] .spinner-bar-container {
  width: 100%;
  height: 100%;
  position: relative; }
  body[data-theme='light'] .spinner-bar-container--hidden,
  body[data-theme='dark'] .spinner-bar-container--hidden {
    display: none; }

@keyframes ripple-animation {
  0% {
    top: 96.47999999999999px;
    left: 96.47999999999999px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: 62.309999999999995px;
    left: 62.309999999999995px;
    width: 68.33999999999999px;
    height: 68.33999999999999px;
    opacity: 0; } }

body[data-theme='light'] .spinner-ripple div,
body[data-theme='dark'] .spinner-ripple div {
  position: absolute;
  border-width: 4.02px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ripple-animation 1.05263s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

body[data-theme='light'] .spinner-ripple div:nth-child(1),
body[data-theme='dark'] .spinner-ripple div:nth-child(1) {
  border-color: #f4536e;
  animation-delay: 0s; }

body[data-theme='light'] .spinner-ripple div:nth-child(2),
body[data-theme='dark'] .spinner-ripple div:nth-child(2) {
  border-color: #e83352;
  animation-delay: -0.5263157894736842s; }

body[data-theme='light'] .spinner-ripple-wrapper,
body[data-theme='dark'] .spinner-ripple-wrapper {
  width: 201px;
  height: 201px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  overflow: hidden;
  background: none; }

body[data-theme='light'] .spinner-ripple div,
body[data-theme='dark'] .spinner-ripple div {
  box-sizing: content-box; }

body[data-theme='light'] .spinner-ripple,
body[data-theme='dark'] .spinner-ripple {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden; }
  body[data-theme='light'] .spinner-ripple.small,
  body[data-theme='dark'] .spinner-ripple.small {
    transform: translateZ(0) scale(0.5); }

body[data-theme='light'] :global .ant-spin-sm :local .spinner-ripple,
body[data-theme='dark'] :global .ant-spin-sm :local .spinner-ripple {
  transform: translateZ(0) scale(0.5); }
