body[data-theme='light'],
body[data-theme='dark'] {
  .wrapper {
    position: relative; }

  .mobilePicker {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    -ms-overflow-style: none;  // IE and Edge
    scrollbar-width: none;  // Firefox

    &.disabled {
      overflow-x: hidden; }

    &::-webkit-scrollbar {
      display: none; } }

  .mobilePickerItem {
    display: flex;
    justify-content: center;
    align-items: center;
    scroll-snap-align: center; }

  .scrollSpace {
    height: 100%; } }
