body[data-theme='light'],
body[data-theme='dark'] {
  .panelWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }

  .lists {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 2; } }
