body[data-theme='light'],
body[data-theme='dark'] {
  .activityListWrapper {
    margin: 8px 0; }

  .collapsedActivitiesList {
    margin-top: 13px; }

  .activityListSpinner {
    margin-top: 3px;

     :global {
      .ant-list-empty-text {
        display: none; } } }

  .activityList {
     :global {
      .ant-list-empty-text {
        padding: 10px 0 12px 0;
        margin: 0 20px;
        text-align: left;
        border-bottom: 1px solid #D9E3EC4C; }

      .ant-popover-open {
        box-shadow: rgba(17, 17, 26, 0.05) 0 1px 0, rgba(17, 17, 26, 0.1) 0 0 8px; } } }

  .listTitle {
    padding-left: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: var(--qs-gray);
    opacity: 0.75; }

  .activitiesButton {
    padding: 4px 6px;
    color: var(--qs-gray);

    &:active,
    &:hover,
    &:focus {
      outline: none;
      background-color: transparent;
      color: var(--qs-gray); } }

  .collapsedActivityIcon {
    position: absolute;
    right: -7px;
    top: -7px; }

  .emptyActivitiesFooterTitle {
    font-size: 13px;
    color: #727981BF; }

  .emptyActivitiesFooterDesc {
    font-size: 10px;
    color: #727981BF; } }
