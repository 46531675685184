body[data-theme='light'],
body[data-theme='dark'] {
  .panel {
    flex: 2 2 40%;
    transition: .25s ease;
    position: relative;
    overflow-y: hidden;

    &.chooseActivityPanel,
    &.hourDetailsPanel,
    &.userHoursListPanel {
      flex-basis: 300%; }

    &.chooseActivityPanel,
    &.hourDetailsPanel {
      .panelInner {
        background-color: #232E39;
        color: white; }

      .marker {
        .panelArrow {
          fill: #232E39; } } }

    &.userHoursListPanel {
      .panelInner {
        background-color: #9ba3ac1a;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          border-top: 2px solid #F4536E; } }

      .marker {
        .panelArrow {
          fill: #F4536E; } } } }

  .marker {
    position: absolute;
    left: 50%;
    top: -7px;
    width: 12px;
    height: 8px;
    transform: translateX(-50%);

    .panelArrow {
      fill: #F4536E; } }

  .panelInner {
    height: calc(100% - 8px);
    margin-top: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; }

  .backButton {
    width: 40px;
    height: 40px;
    border: 1px solid transparent;
    background: white;
    box-shadow: none;
    position: absolute;
    z-index: 10;
    right: 8px;
    top: 8px;
    color: black;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after,
    &:before {
      content: none; }

    &:hover,
    &:active,
    &:focus {
      border-color: transparent;
      background: var(--white); } } }

body[data-theme='dark'] {
  .panel {
    &.hourDetailsPanel,
    &.chooseActivityPanel {
      .panelInner {
        background-color: var(--dt-qs-dark); } } }

  .backButton {
    background-color: var(--primary-backround-black);

    svg {
      fill: white; } } }
