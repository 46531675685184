body[data-theme='light'],
body[data-theme='dark'] {
  .activityListItem {
    cursor: pointer;
    transition: .25s ease;
    border-bottom-color: transparent !important;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 0;
      width: calc(100% - 24px);
      border-bottom: 1px solid rgba(217, 227, 236, .3); }

    &:hover {
      background-color: #1a1a1a; } }

  .activityListItemDescription {
    color: white; }

  .activityListItemDescription,
  .descriptionTextWrapper {
    display: flex;
    align-items: center; }

  .descriptionTextWrapper {
    margin: 0;
    flex-grow: 2;
    justify-content: space-between;
    color: inherit;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; }

  .activityIconWrapper {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    margin-right: 6px;
    border-radius: 5px; }

  .withPadding {
    padding-left: 24px;
    padding-right: 24px; } }

body[data-theme='dark'] {
  .activityListItem {
    &::after {
      border-bottom: 1px solid var(--dt-qs-gray-20); } } }
