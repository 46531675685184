body[data-theme='light'],
body[data-theme='dark'] {
  .vacationReportLayout {
    padding-left: 34px;
    padding-top: 16px;
    padding-right: 24px; }

  .nameWrapper {
    display: flex;

    .name {
      flex-grow: 2; } } }
