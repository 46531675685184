body[data-theme='light'],
body[data-theme='dark'] {
  .customScrollbar {
    ::-webkit-scrollbar {
      min-width: 1px;
      min-height: 1px; }

    & * {
      ::-webkit-scrollbar {
        min-width: 1px;
        min-height: 1px; } } }


  .thumbVertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background-color: rgba(0, 0, 0, 0.2); } }
