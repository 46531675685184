body[data-theme='light'],
body[data-theme='dark'] {
  .panelWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative; }

  .lists {
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    flex-grow: 2; }

  .backButton {
    height: 30px;
    padding: 14px 0;
    text-align: left;
    border: none;
    outline: none;
    background: none;
    box-shadow: none;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: absolute;
    right: 0;
    top: 14px;
    z-index: 1;

    color: #727981;

    svg {
      margin-right: 5px; }

    &:after,
    &:before {
      content: none; }

    &:active {
      background-color: #e1e1e1; }

    &:focus,
    &:hover {
      border: none;
      background: none;
      box-shadow: none; } } }
