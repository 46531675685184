body[data-theme='light'] .qs-search_element--disabled,
body[data-theme='dark'] .qs-search_element--disabled {
  cursor: default;
  background-color: #eee;
  color: rgba(0, 0, 0, 0.4); }

body[data-theme='light'] .qs-search_element--disabled,
body[data-theme='dark'] .qs-search_element--disabled {
  cursor: default;
  background-color: #eee;
  color: rgba(0, 0, 0, 0.4); }

body[data-theme='light'] .pagination,
body[data-theme='dark'] .pagination {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none; }
  body[data-theme='light'] .pagination__item,
  body[data-theme='dark'] .pagination__item {
    border: 1px solid #ccc;
    background: #fff;
    transition: .25s ease; }
    body[data-theme='light'] .pagination__item:not(.pagination__item--active):hover,
    body[data-theme='dark'] .pagination__item:not(.pagination__item--active):hover {
      background-color: #eee; }
    body[data-theme='light'] .pagination__item--active,
    body[data-theme='dark'] .pagination__item--active {
      background: var(--primary-color); }
      body[data-theme='light'] .pagination__item--active .pagination__link,
      body[data-theme='dark'] .pagination__item--active .pagination__link {
        color: white; }
  body[data-theme='light'] .pagination__link,
  body[data-theme='dark'] .pagination__link {
    border: none;
    padding: 6px 12px;
    display: block;
    color: #777;
    background: transparent;
    transition: .25s ease; }
  body[data-theme='light'] .pagination > *:not(:first-child),
  body[data-theme='dark'] .pagination > *:not(:first-child) {
    margin-left: -1px; }
  body[data-theme='light'] .pagination > *:first-child,
  body[data-theme='dark'] .pagination > *:first-child {
    border-radius: 4px 0 0 4px; }
  body[data-theme='light'] .pagination > *:last-child,
  body[data-theme='dark'] .pagination > *:last-child {
    border-radius: 0 4px 4px 0; }

body[data-theme='dark'] .qs-search_element--disabled {
  background-color: var(--dt-qs-dark);
  color: var(--dt-qs-gray-20);
  cursor: not-allowed; }

body[data-theme='dark'] .pagination__item {
  background-color: var(--dt-qs-dark);
  border-color: var(--dt-qs-gray-20); }
  body[data-theme='dark'] .pagination__item:not(.pagination__item--active):hover {
    background-color: var(--dt-gray-button); }

body[data-theme='dark'] .pagination__item--active {
  background-color: var(--primary-color); }

body[data-theme='dark'] .pagination__link {
  color: var(--dt-qs-gray); }
