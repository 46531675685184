body[data-theme='light'],
body[data-theme='dark'] {
  .replacePopover {
    width: 250px;
    z-index: 999;

     :global {
      .ant-popover-inner {
        border-radius: 5px;

        &-content {
          padding: 5px;

          li {
            padding: 14px;

            &:hover {
              background-color: white !important; }

            &::after {
              display: none; } } } } } }

  .activitiesCustomScrollbar {
    height: calc(100% - 44px) !important; }

  .moreActivities {
    height: 44px;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    border-top: 1px solid rgba(217, 227, 236, .3); }

  .moreActivitiesButton {
    width: 24px;
    height: 24px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #23354A;
    border: 1px solid #D9E3EC;
    margin-right: 6px;

    &:hover,
    &:focus,
    &:active {
      background: #72798142;
      color: black; }

    & + .replaceModeButton {
      margin-left: 4px; }

    &.active {
      background-color: #F4536E;
      color: white;

      &:hover,
      &:focus,
      &:active {
        background: #a23144; } } }

  .emptyListLabel {
    display: block;
    font-size: 13px;
    color: #727981BF; }

  .moreActivitiesDescription {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    color: #000000; }

  .groupPopover {
    width: 220px;
    padding: 0px 4px; }

  .pinnedActivities {
    height: 186px; } // 3 activities and a half one

  .activityInfo + .activityInfo {
    margin-top: 10px; }

  .withBottomLine {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: calc(100% + 10px);
      left: 0;
      bottom: 0;
      border-bottom: 1px solid #D9E3EC;
      opacity: 0.3; } }

  .activities {
    padding-top: 0;
    padding-bottom: 16px; }

  .groupPopoverFooterLabel,
  .activitiesLabel {
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;

    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: #727981;
    opacity: 0.75; }

  .showMoreButton {
    border: none;
    padding: 0;
    margin: 5px 0px;
    font-size: 12px;
    box-shadow: none;
    color: var(--primary-color); }

  .buttons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 5px; }

  .replaceModeButton {
    border: none;
    background: #7279811a;
    color: black;
    border: none;
    box-shadow: none;
    border-radius: 5px;
    padding: 3px 7px;
    display: flex;
    align-items: center;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;

    &:hover, {
      background: #72798142;
      color: black; }

    &:focus,
    &:active {
      color: inverit;
      background: inherit; }

    & + .replaceModeButton {
      margin-left: 4px; }

    &.active {
      background-color: #F4536E;
      color: white; } }

  .title {
    margin-bottom: 0;
    font-weight: normal;
    padding-bottom: 5px; }

  .activities.withoutFooter {
    margin-bottom: -15px; }

  button.pinnedActivity {
    margin: 0px -20px 0px -20px;
    padding: 5px 20px 5px 20px;
    width: calc(100% + 40px);
    display: flex;
    align-items: center;

    &:hover {
      background-color: #eee; } }

  .groupPopoverHeader {
    display: flex;
    align-items: center;
    padding-bottom: 14px; }

  .partnerMaskWrapper {
    height: 24px;
    width: 24px;
    flex: 24px 0 0;
    margin-right: 6px; }

  .partnerMask {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #AEB9C7;
    border: 1px solid;
    border-radius: 5px; }

  .partnerAndActivityName {
    color: var(--font-black);
    flex: 158px 1 1;
    width: 158px;

    font-family: Roboto;
    font-style: normal;
    font-size: 12px;
    line-height: 14px;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

  .groupPopoverHoursSpent {
    display: flex;
    align-items: center;
    padding: 8px 0; }

  .hoursAmount {
    width: 48px;
    height: 32px;
    flex: 48px 0 0;
    display: inline-block;
    border-radius: 5px;
    background: #F2F4F6;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 6px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center; }

  .hoursLabel {
    color: var(--font-black);
    flex-grow: 2;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; }

  .groupPopoverComments {
    padding: 8px 0; }

  .comment {
    background: #F2F4F6;
    border-radius: 5px;
    padding: 9px 8px; }

  .commentText {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    margin: 0; }

  .groupPopoverFooter {
    padding-top: 18px; }

  .popoverActionButtons {
    padding-top: 2px;
    padding-bottom: 3px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: calc(100% + 40px);
    margin: 0 -20px; }

  .popoverButton {
    border: none;
    padding: 14px 20px 13px;
    height: 41px;
    border-radius: 0;
    text-align: left;
    transition: .25s ease;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    &::after {
      display: none; }

    &:hover {
      background: #F2F4F6; }

    &.withBottomLine:before {
      left: 20px;
      width: calc(100% - 30px); }

    .groupPopoverHeader {
      padding: 0; } } }

body[data-theme="dark"] {
  .popoverButton,
  .popoverButton.pinnedActivity {
    &:hover {
      background-color: #222932; } }

  .replaceModeButton:not(.active) {
    color: white; }

  .moreActivities {
    border-top: 1px solid var(--dt-qs-gray-20); } }
