body[data-theme='light'],
body[data-theme='dark'] {
  .month {
    width: 450px;
    margin: 15px;
    background-color: white;
    padding: 30px 30px 30px 40px;
    border-radius: 5px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
    transition: .25s ease;

    &:hover {
      .headerButtons {
        opacity: 1; } }

    &.disabled {
      opacity: .25; }

    &.editing {
      box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);

      .headerButtons {
        opacity: 1; } } }

  .monthHeader {
    display: flex;
    flex-direction: column; }

  .headerRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;

    & + .headerRow {
      margin-top: 10px; } }

  .headerButtons {
    display: flex;
    align-items: center;
    transition: .25s ease;
    opacity: 0;

    button + button {
      margin-left: 10px; } }

  .daysLabels {
    display: flex;
    align-items: center;
    padding: 5px 0;
    border: 1px solid #7279811a;
    border-left-width: 0;
    border-right-width: 0;
    margin: 15px -30px 30px -40px;
    padding: 20px 30px 20px 40px;

    .dayName {
      flex: 1 1 0px;
      text-align: center;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
      font-feature-settings: 'tnum' on, 'lnum' on;

      &.holiday {
        color: var(--primary-color); } } }

  .monthName {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: .86;
    margin-right: 10px;
    opacity: 1; }

  .stat {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center; }

  .leftPart {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: .86;
    margin-right: 10px;
    opacity: 1; }

  .rightPart {
    font-size: 12px;
    line-height: 14px;
    flex: 0 0 45px; }

  .days {
    display: flex;
    flex-wrap: wrap;
    position: relative;

    &.nonInteractive {
      pointer-events: none; } }

  .additionalMonthLabel {
    position: absolute;
    left: 0;
    transform: translateX(-100%);
    width: 40px;
    border: 1px dashed #ebebeb;
    border-right-color: transparent;
    border-left-color: transparent;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    .littleMonthName {
      text-transform: uppercase;
      transform-origin: 100% 100%;
      transform: rotate(-90deg);
      display: block;
      font-size: 10px;
      color: #979797; }

    &.previousMonth {
      top: 0; }

    &.nextMonth {
      bottom: 0; } } }

body[data-theme='dark'] {
  .month {
    color: white;
    background: #2D3743; }

  .additionalMonthLabel,
  .daysLabels {
    border-top-color: rgba(255, 255, 255, .2);
    border-bottom-color: rgba(255, 255, 255, .2); } }
