body[data-theme='light'],
body[data-theme='dark'] {
  .avatar,
  .companyLogo {
    width: 32px;
    height: 32px;
    border-radius: 50%; }

  .avatarLoading {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: var(--grey-background-break-gray); }

  .headerWrapper {
    padding: 13px 10px 0px 10px;
    min-height: 95px;
    border-bottom: 1px solid var(--dt-qs-gray-20);
    width: 100%; }

  .avatarTitle {
    font-family: Gilroy;
    font-weight: 600;
    font-size: 12px;
    color: #00000080; }

  .avatarDesc {
    font-family: Gilroy;
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    color: var(--font-black);
    display: inline-block;

    width: 140px;
    overflow: hidden;
    text-overflow: ellipsis; }

  .avatarDescEmpty {
    color: #00000080; }

  .profilePickerWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;
    border: none;
    padding: 5px 0 5px 10px;
    box-shadow: none;
    white-space: normal;
    text-align: left;
    height: auto; }

  .sidebarList {
    display: none; }

  .sidebarListVisible {
    display: inline-block; } }

body[data-theme='dark'] {
  .avatarTitle {
    color: var(--font-white-85); } }
