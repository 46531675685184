body[data-theme='light'],
body[data-theme='dark'] {
  .entitiesTable {
     :global {
      .borderless {
        border-right: 1px solid transparent !important; }

      .action-column {
        padding: 0; }

      .ant-table-row {
        &:hover {
           :local {
            .editEntityIcon {
              opacity: 1; } } } } } }

  .editEntityIcon {
    opacity: 0; } }
