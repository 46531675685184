body[data-theme='light'],
body[data-theme='dark'] {
  .gridCalendarWrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 16px;
    padding: 0 16px 32px 16px; } }



