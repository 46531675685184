body[data-theme='light'],
body[data-theme='dark'] {
  .specialists-settings {
    background-color: #fff;
    padding: 14px 20px 14px 20px;

    &.collapsed {
      padding: 14px 20px; }

    &_filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 18px;

      &.collapsed {
        margin: 0;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start; }

      &_go-back {
        font-family: Gilroy;
        color: var(--grey-background-break-gray);
        font-size: 20px; }

      &_project-title {
        color: var(--font-black);
        font-family: Gilroy;
        font-size: 20px;
        font-weight: normal;

        .project-title_bold {
          font-weight: bold; }

        .project-title_text {
          color: var(--icon-gray); } } }

    &_select {
      width: 232px;

      &:global(.ant-select-disabled) {
        background-color: var(--background-gray); } }

    &_exp-select {
      width: 100%;
      margin-left: 2px; }

    &_input {
      width: 420px; }

    &_experience-inner {
      flex-grow: 2; }

    &_experience,
    &_experience-inner {
      display: flex;
      align-items: center;

      .experience-skill-tag {
        padding-top: 7px;
        padding-bottom: 7px;
        padding-left: 10px;
        margin: 0 2px 0 2px; } } }

  .clear-button {
    font-weight: 500;
    font-size: 13px;
    line-height: 0;
    color: var(--qs-gray);

    &:hover {
      background-color: transparent; }

    &_icon {
      transition: 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      fill: var(--qs-gray);

      &.disabled {
        fill: rgba(0, 0, 0, 0.25); } } }

  .collapse-icon {
    position: relative;
    top: 2px;
    height: 17px; }


  .collapse-button {
    width: 117px;
    border: 1px solid #E8EDF1;
    border-radius: 5px;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    padding: 12px 24px;
    height: auto;
    color: var(--primary-color);

    &:hover,
    &:focus {
      color: var(--primary-color);
      border: 1px solid var(--primary-color);
      background-color: transparent; } }

  .collapsed-skills {
    &.empty {
      font-size: 15px;
      line-height: 18px;
      color: var(--font-black); } }

  .collapsed-english {
    color: var(--font-black); }

  .splitter {
    margin: 0 10px;
    height: 28px;
    width: 1px;
    background-color: #E8EDF1; }

  .settings-title_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; }

  .generalFiltersButton {
    background-color: var(--background-gray); } }

body[data-theme='dark'] {
  .specialists-settings {
    background-color: var(--primary-backround-black); }

  .specialists-settings_filters_go-back {
    color: #fff; }

  .specialists-settings_filters_project-title {
    .project-title_text {
      color: var(--dt-qs-gray); } }

  .splitter {
    background-color: var(--dt-qs-gray-20); }

  .collapse-button {
    border: 1px solid var(--dt-qs-gray-20); }

  .clear-button {
    color: var(--font-white-85);

    &_icon {
      fill: var(--font-white-85);

      &.disabled {
        fill: rgba(#fff, 0.25); } } }

  .generalFiltersButton {
    background-color: var(--dt-qs-dark); } }
