body[data-theme='light'],
body[data-theme='dark'] {
  .monthList {
    padding: 0 5px 0 15px;
    border-left: 1px solid #D9E3EC;
    overflow-x: auto; }

  .monthListInner {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; }

  .monthButton {
    background: #E5EAEE;
    border-radius: 15px;
    height: 30px;
    border: none;
    margin-right: 0;
    box-shadow: none;
    padding: 8px 10px 7px;
    color: #263341;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    cursor: pointer;
    position: relative;
    transition: .25s;

     :global {
      .ant-radio {
        display: none; } }

    &.active {
      background: #F4536E;
      color: white;

      &:after {
        opacity: 1; } }

    span {
      position: relative;
      z-index: 2; }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 15px;
      height: 0;
      width: 0;
      background: #F4536E;
      opacity: .75;
      transition: .25s;
      z-index: 1; }

    &:hover {
      color: white;

      &:after {
        width: 100%;
        height: 100%; } } } }

body[data-theme='dark'] {
  .monthList {
    border-color: var(--dt-qs-gray-20); }

  .monthButton {
    background-color: var(--dt-qs-dark);
    color: var(--dt-qs-gray); } }
