body[data-theme='light'],
body[data-theme='dark'] {
  .manage-skills-drawer {
     :global {
      .ant-drawer-body {
        padding: 33px 0 22px 32px; } }

    &_footer {
      display: flex;
      justify-content: flex-end; }

    &_header {
      padding-bottom: 33px;
      border-bottom: 1px solid #E8EDF1;

      &-content {
        display: flex;
        align-items: center; }

      &-title {
        padding-left: 24px;

        h2 {
          margin: 0;
          padding-bottom: 6px;
          font-family: Gilroy;
          font-style: normal;
          font-weight: normal;
          font-size: 25px;
          line-height: 29px;
          color: #000000; }

        &_category {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 15px;
          letter-spacing: 0.05em;
          text-transform: uppercase;
          color: var(--qs-gray);
          opacity: 0.75; } } }

    &_header-logo {
      position: relative;
      width: 128px;
      height: 128px;
      border-radius: 50%;
      background-color: var(--primary-color);

      &-arrow {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-40%, -40%); } }

    .drawer-row {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #E8EDF1;

      &_title {
        min-width: 183px;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: var(--qs-gray); }

      &_description {
        span:first-of-type {
          margin-left: 0; } } }

    .synonyms {
      padding: 10px 0;

      .drawer-row_description {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        max-width: 450px; } }

    .experience {
      padding: 11px 0;

      .drawer-row_description {
        display: flex;
        gap: 4px;
        flex-wrap: wrap; } }

    .emotions,
    .description {
      padding: 12px 0; }

    .description {
      .drawer-row_description {
        color: var(--font-black);
        display: flex;
        flex-wrap: wrap; } }

    .author {
      padding: 12px 0;
      border-bottom: none;

      .drawer-row_description {
        display: flex;
        align-items: center;
        color: var(--font-black); } }

    .employees {
      .drawer-row_title {
        align-self: flex-start;
        padding: 18px 0; }

      .drawer-row_description {
        border-left: 1px solid #E8EDF1;

        &.empty {
          border-left: none; } }

      .drawer-row_user {
        width: 100%;
        display: flex;
        align-items: center;
        padding: 7px 14px;
        border-bottom: 1px solid #E8EDF1;
        cursor: pointer;
        color: var(--font-black);

        &:hover {
          color: var(--primary-color); }

        &:last-of-type {
          border-bottom: none; } }

      .show-all-link {
        display: flex;
        align-items: center;

        .show-all-logo {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 13px;
          width: 32px;
          height: 32px;
          border-radius: 50%;
          margin-right: 8px;
          background-color: var(--primary-color);
          color: #fff; }

        .show-all-title {
          color: var(--primary-color); } } }


    .synonyms-input {
      width: 150px;
      padding: 0;
      margin-left: 8px;

      .add-synonym-button {
        opacity: 0;
        color: var(--qs-gray);

        &:hover {
          cursor: pointer;
          color: var(--primary-color); }

        &--show {
          opacity: 1; } } }

    .skill-tags-wrapper {
      max-width: 200px; }

    .author-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;

      &--qs {
        width: 28px;
        height: 28px;
        border-radius: 0; } } }

  .exp-tag {
    padding-top: 7px;
    margin: 0;
    padding-bottom: 7px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05); }

  .emotion-tag {
    color: #000;
    background-color: #FFFFFF;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.15);
    margin: 0 5px;

    &_content {
      display: flex;
      align-items: center;
      gap: 5px; }

    &_icon {
      margin-left: 5px; } }

  .synonyms-wrapper {
    padding: 7px 0;

    &--loading {
      position: relative;
      color: transparent;
      overflow: hidden;
      border-color: transparent;
      pointer-events: none;

      & > .manage-skills-drawer_body-synonyms_desc {
        transition: none;
        opacity: 0; }

      &:before {
        content: '';
        background-color: var(--break-gray);
        border-radius: .25rem;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        min-height: 2rem; } } }

  .employees-wrapper {
    @extend .synonyms-wrapper;
    padding: 0;
    flex-grow: 1; }

  .synonym-error {
    display: none;

    &--show {
      width: 100%;
      text-align: center;
      color: red; } }

  .deleteButton {
    line-height: 9px; }

  .drawerSkillTag {
    margin: 0 5px; }

  .skillsetEmployeeDrawer {
     :global {
      .ant-drawer-header {
        padding: 0;
        display: inline-block; }

      .ant-drawer-header-title {
        display: none; } } }

  .employeeDrawerHeader {
    background-color: var(--primary-color);
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: white;
    padding: 0 24px;

    font-size: 16px;
    height: 58px;
    border-radius: 0;
    gap: 8px;
    font-weight: bold;

    &:hover {} }


  .employeeDrawerIcon {
    transform: rotate(180deg); } }

body[data-theme='dark'] {
  .manage-skills-drawer .employees .drawer-row_user,
  .manage-skills-drawer .employees .drawer-row_description {
    border-color: var(--dt-qs-gray-20); }

  .manage-skills-drawer .drawer-row {
    border-color: var(--dt-qs-gray-20); }

  .manage-skills-drawer_header {
    border-color: var(--dt-qs-gray-20); }

  .manage-skills-drawer_header-title {
    h2 {
      color: var(--dt-qs-gray); } } }
