@import '../../../helpers/styles.module.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .skillset {
    &_language-section.disabled {
      opacity: 0.3;
      pointer-events: none; }

    &_english-knowledge-icon.loading {
      display: none; }

    &_category {
      &--spinning {
        opacity: 0.5;
        .skillset_experience-english {
          cursor: default; } } }

    &_spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;

      &--spinning {
        opacity: 1; } } }

  .skillset_experience-isReadOnlyMode {
    cursor: default;
    pointer-events: none; }

  .skillset_skill-category {
    position: relative;
    @extend .skillset_skill-category; }

  .skillset_language-section {
    margin-top: 34px; }

  .skillset_experience-title,
  .skillset_english-knowledge-description {
    color: var(--icon-gray);
    font-family: Gilroy;
    font-size: 12px;
    line-height: 14px;
    font-weight: 600;
    margin: 0; }

  .skillset_category-title-error {
    color: red; }

  .skillset_experience-english {
    &:hover {
      .skillset_english-knowledge-title,
      .skillset_english-knowledge-description {
        color: rgba(#333, 1); } } }

  .skillset_category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 22px; } }


body[data-theme='dark'] {
  .skillset_experience-english {
    &:hover {
      .skillset_english-knowledge-title,
      .skillset_english-knowledge-description {
        color: #fff; } } } }
