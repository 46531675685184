body[data-theme='light'],
body[data-theme='dark'] {
  .threeDotsPopover {
     :global {
      .three-dots-button {
        opacity: 0;
        transition: .25s ease; } } }

  .popoverLoadingItem {
    display: flex;
    align-items: flex-start;
    gap: 4px; }

  .threeDotsButton {
    width: 24px; }

  .popoverButton {
    height: auto;
    text-align: left;
    border: none;
    position: relative;

    &:not(:last-child) {
      border-bottom: 1px solid var(--dt-qs-gray-20); } }

  .popoverError {
    color: var(--primary-color); }

  .skillTagRowIcon {
    color: var(--qs-gray); } }
