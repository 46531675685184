body[data-theme='light'],
body[data-theme='dark'] {
  .partnerLoadingIcon {
    margin-left: 50px; }

  .disabledPartnerItem {
    cursor: default;

    &:hover {
      color: var(--font-black); } } }
