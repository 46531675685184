body[data-theme='light'],
body[data-theme='dark'] {
  .partner {
    display: flex;
    flex-direction: row;
    align-items: center; }

  .name,
  .description {
    overflow: hidden;
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px; }

  .maskWrapper {
    margin-right: 10px; }

  .threeDotsButton {
    padding: 0;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    transition: .25s ease;
    border-radius: 2px; }

  .employeesTable {
     :global {
      tr {
        cursor: pointer;

        &:hover .three-dots-button {
          opacity: 1; } } } } }
