@import '../../../helpers/styles/mixins.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .trackerLogo {
    position: absolute;
    top: 32px;
    left: 32px; }

  .splitter {
    width: 100%;
    height: 1px;
    margin: 30px 0;
    background-color: var(--dt-qs-gray-20); }

  .welcomePage {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    background: var(--primary-color) url('../../../helpers/images/LoginImage.jpg') center/cover no-repeat; }

  .welcomePageWrapper {
    overflow: auto;
    position: relative;
    padding: 32px 0 32px 32px;
    width: 620px;
    height: 100%;
    background-color: var(--white); }

  .welcomePageHeader {

    h1 {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 32px;
      line-height: 38px;
      margin: 0;
      padding: 0;
      color: var(--font-black); }

    p {
      font-family: Gilroy;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 18px;
      color: #00000080;
      margin-bottom: 0; } }

  .welcomePageBody {
    width: 100%;
    padding-left: 68px;
    padding-right: 45px;
    margin-top: 20px; }

  .welcomePageInput,
  .welcomePageSelect {
    width: 100%; }

  .welcomePageConfirmButton {
    width: 170px; }

  .welcomePageFieldWrapper {
    width: 100%;
    position: relative; }

  .welcomePageFieldTitle {
    font-size: 13px;
    line-height: 15px;
    color: var(--qs-gray); }

  .required {
    position: relative;

    &:after {
      transition: all 0.3s;
      position: relative;
      content: '*';
      color: var(--primary-color);
      left: 4px; } }

  .highlightedText {
    padding: 0;
    margin: 0;
    color: var(--primary-color);
    background-color: transparent;
    font-weight: bold; }

  .errorWrapper {
    position: absolute;
    color: red; }

  .error {
    border: 1px solid red; }

  .questionMarkInfo {
    position: relative;
    color: var(--qs-gray); }

  .addNewLocationButton {
    width: 100%;
    padding-left: 0;
    padding-right: 0;

    svg {
      color: var(--qs-gray); }

    span {
      display: flex;
      justify-content: space-between;
      align-items: center; } }

  @include mq('tablet-wide') {
    .trackerLogo {
      top: 24px;
      left: 50%;
      transform: translate(-50%); }

    .welcomePageHeader {
      padding-bottom: 0;

      p {
        margin: 0; } }

    .welcomePageWrapper {
      padding: 84px 32px 32px 32px; }

    .welcomePageBody {
      margin-top: 0;
      padding: 0; } } }

body[data-theme='dark'] {
  .splitter {
    background-color: var(--dt-qs-gray-20); }
  .welcomePageHeader {
    p {
      color: var(--dt-qs-gray); } } }

