body[data-theme='light'],
body[data-theme='dark'] {
  .selector {
    width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 40px;
    transition: opacity 0.3s ease; }

  .disabledSelector {
    opacity: 0.2;
    pointer-events: none; }

  .dropDownSelector {
    padding: 0px 14px; }

  .arrowButton {
    padding: 0;
    margin: 0;
    border: 0;
    box-shadow: none;
    height: 26px;

    svg {
      fill: var(--primary-color); }

    &.popoverButton > svg {
      width: 18px;
      fill: #727981; } }

  .disabledArrowButton {
    opacity: 0.5;
    svg {
      fill: var(--qs-gray); } }


  .selected {
    display: flex;
    align-items: center;
    cursor: pointer; }

  .selectedMonthLabel {
    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    white-space: nowrap;
    color: var(--font-black); }

  .modified {
    margin-left: 4px;

    font-family: Gilroy;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left; }

  .yearSelector {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid #D9E3EC;
    padding: 6px 0px 15px; }

  .selectedYearLabel {
    font-family: Gilroy;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0px 10px; }

  .monthList {
    width: 100%;
    padding: 10px 0px; }

  .monthListInner {
    width: 100%; }

  .monthButton {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 14px;
    font-family: Roboto;
    border: none;
    padding: 0;
    height: 22px;
    box-shadow: none !important;
    outline: none !important;

    &:after,
    &:before {
      display: none; }

    &.active {
      color: var(--primary-color); }

     :global {
      .ant-radio {
        display: none; } } }

  .popoverIcon {
    margin: 0 6px 0 12px; }

  .spinner {
    color: var(--primary-color); }

  .customDateWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer; }

  .customDateIcon {
    width: 24px;
    height: 24px; }

  .popover {
    width: 270px;

     :global {
      .ant-popover-inner {
        border-radius: 5px; } } } }
