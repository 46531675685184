body[data-theme='light'],
body[data-theme='dark'] {
  .timeSheet {
    position: relative;
    height: 100vh;
    padding-left: 2px;
    transition: .25s ease opacity;
    user-select: none;

    &.isLoading {
      opacity: .5; } }

  .monthDrawerButton {
    border-radius: 5px;
    height: 34px; } }
