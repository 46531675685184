body[data-theme='light'],
body[data-theme='dark'] {
  .partners {
    padding-left: 34px;
    padding-top: 16px;
    padding-right: 24px;
    min-width: 1200px; }

  .partnerSelectionPanelWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 14px;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; }

  .partnerSelectionPanelCount {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000; } }

body[data-theme='dark'] {
  .partnerSelectionPanelCount {
    color: white; } }
