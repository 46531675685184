body[data-theme='light'],
body[data-theme='dark'] {

  .userAvatar,
  .companyAvatar {
    width: 24px;
    height: 24px;
    border-radius: 50%; }

  .userItemWrapper {
    transition: 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px; } }
