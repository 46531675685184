body[data-theme='light'],
body[data-theme='dark'] {
  .cellsGroup {
    position: relative;
    cursor: pointer;

    //&:hover
    //  .commentMarker
    //    background-color: var(--qs-gray) !important

    &.loaded .groupFillWrapper {
      opacity: 1;
      transform: scale(1) translateY(0px); }

    & > span { // ant-popover-open
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column; } }

  .popoverOverlay {
    z-index: 999;

     :global {
      .ant-popover-inner {
        box-shadow: 0px 25px 15px rgba(0, 0, 0, 0.1);
        border-radius: 6px; } } }

  .groupFillWrapper {
    padding: 1px 2px;
    width: 100%;
    height: calc(100% + 1px);
    top: 0px;
    position: absolute;
    z-index: 0;
    transition: .25s ease;
    pointer-events: none;
    opacity: 0;
    transform: scale(.75) translateY(-25px);
    transition: .25s ease;

    .groupFill {
      width: 100%;
      height: 100%;
      border-radius: calc(100vw / 250);
      border: 1px solid;
      position: relative; } }

  .commentMarker {
    position: absolute;
    width: 0;
    height: 0;
    right: -1px;
    top: -1px;
    z-index: 1;
    background-color: var(--qs-gray);

    &::before {
      content: '';
      width: 8px;
      height: 8px;
      border-radius: 0 0 0 5px;
      background-color: #f2f4f6;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 0; }

    &::after {
      content: '';
      position: absolute;
      background: inherit;
      width: 6px;
      height: 6px;
      border-radius: 5px;
      right: 0;
      top: 0; } }

  .partnerMaskWrapper {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 2;

    &:not(.isOneHourOrMore) .partnerMaskInner {
      //margin-top: 1px
      height: 100%; }

    &.isOneHourOrMore .partnerMaskInner {
      height: 200%; }

    svg {
      height: 100% !important; } }

  .partnerMaskInner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; } }

body[data-theme='dark'] {
  .commentMarker {
    background-color: white;

    &:before {
      background-color: var(--dt-second-background-color); } }

  .groupFillWrapper {
    padding: 2px 3px;

    .groupFill {
      border: none; } } }
