$rowHeight: 26px;
$sideColumnWidth: 61px;
$minTableWidth: 1200px;
$minTableHeight: 700px;

$grayBorderColor: #DFE7EF;
$redBorderColor: #FFDBE1;

$tableGray: #232b32;
$darkGrayBorderColor: lighten($tableGray, 25);
$darkCutColor: #35342a;
