body[data-theme='light'],
body[data-theme='dark'] {
  .totalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-top: 1px solid var(--grey-background-break-gray);
    padding: 8px 16px;
    font-family: Gilroy, Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: var(--font-black); } }


