body[data-theme='light'],
body[data-theme='dark'] {
  .monthWrapper {
    flex: 2 2 60%;
    position: relative;
    padding-bottom: 6px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      width: 100px;
      height: 100%;
      z-index: 1;
      pointer-events: none; }

    &:after {
      right: 0;
      background: linear-gradient(-90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); }

    &:before {
      left: 0;
      background: linear-gradient(90deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)); } }

  .loadingWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

  .markersWrapper {
    display: flex;
    flex-direction: row;
    position: absolute;
    overflow-x: hidden;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    -ms-overflow-style: none;  // IE and Edge
    scrollbar-width: none;  // Firefox

    &::-webkit-scrollbar {
      display: none; } }

  .monthNameMarker {
    height: 100%;
    position: sticky;
    left: 0;

    .monthName {
      background-color: white;
      position: relative;
      font-family: 'Gilroy';
      font-style: normal;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
      text-align: left;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      left: 28px;
      top: -6px;
      padding: 2px;

      color: #9BA3AC80; } } }

body[data-theme='dark'] {
  .monthWrapper {
    &:after {
      background: linear-gradient(-90deg, rgba(#1B2228, 1), rgba(#1B2228, 0)); }

    &:before {
      background: linear-gradient(90deg, rgba(#1B2228, 1), rgba(#1B2228, 0)); } }

  .monthNameMarker {
    .monthName {
      background-color: var(--primary-backround-black); } } }
