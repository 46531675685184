body[data-theme='dark'] {
  @import '../node_modules/antd/dist/antd.dark.less';

  @popover-background: #2D3743;
  @popover-customize-border-color: #3a3a3a;
  @body-background: #1B2228;
  @component-background: #232E39;

  @primary-color: #F4536E;

  @table-padding-vertical: 12px;
  @table-header-bg: #232b32;
  @table-header-sort-bg: #232b32;

  @table-row-hover-bg: #2D3743;
  @table-border-color: #9BA3AC33;

  @text-color-dark: #fff;
  @text-color: #fff;
  @border-color-base: #9BA3AC33;

  @tooltip-bg: #2D3743;
  @heading-color: #fff;
  @body-background: #1B2228;
  @menu-bg: #1B2228;
}



// you can override any default antd variable:
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
// dark theme variables:
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/variable.less
