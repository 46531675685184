body[data-theme='light'],
body[data-theme='dark'] {
  .affectedUsers {
    padding: 0px 10px;
    border-radius: 15px;
    margin: 0px 4px;
    background-color: var(--primary-color);
    color: white; }

  .reportSelect {
     :global {
      .ant-select-selection-item {
        display: flex;

        span {
          flex-grow: 2; } } } }

  .option {
     :global {
      .ant-select-item-option-content {
        display: flex;

        span {
          flex-grow: 2; } } } }

  .field {
    display: flex;
    flex-direction: column;
    gap: 10px; }

  .red {
    color: var(--primary-color);
    line-height: 2;

    .redPartnerMask {
      display: inline-flex;
      margin: 0 .5em; }

    span {
      display: block; }

    b {
      padding: 5px 10px;
      border-radius: 15px;
      height: 30px;
      background-color: var(--background-gray); } }

  .collapseWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    .collapseItem {
      padding: 10px;
      border-radius: 5px;
      background-color: var(--background-gray);


      .collapseItemTitle {
        cursor: pointer; }

      .collapseItemContent {
        display: none; }

      &.active {
        .collapseItemContent {
          display: block; }

        .collapseItemTitle {
          display: none; } } }

    .collapseTogglerBtn {
      align-self: center;
      width: 100%;
      height: 38px;
      padding: 0;
      display: flex;
      align-items: center;
      gap: 10px;

      &.default {
        .collapseIcon {
          display: none; } }

      span {
        margin: 0; }

      &:hover .collapseIcon {
        background-color: rgba(0, 0, 0, .2); }

      .separator {
        display: block;
        border-bottom: 1px solid rgba(114, 121, 129, 0.3);
        flex-grow: 1; }

      .collapseIcon {
        padding: 7px;
        border-radius: 5px;
        padding-bottom: 2px;
        height: 28px;
        width: 28px;

        svg {
          transition: .4s ease; }

        &.flipped svg {
          transform: rotate(-180deg); } } } }

  .subtitle {
    margin-top: 10px;
    font-size: 18px;
    font-family: Gilroy;
    font-weight: 700;
    gap: 5px;
    display: flex;
    align-items: baseline;

    .thin {
      font-weight: 400; } }

  .reportSelect {
    padding: 0px 3px;

     :global {
      .ant-select-arrow {
        top: 6px !important;
        height: 100%;
        right: 6px;
        transform: none;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%); } } }

    &:global(.ant-select-disabled) {
      opacity: .5; } }

  .checkboxListPopover {
    width: 333px;

     :global {
      .ant-popover-arrow {
        display: none; }

      .ant-popover-inner {
        border-radius: 5px; }

      .ant-popover-inner-content {
        padding: 0;
        max-height: 320px;
        overflow: auto; } } }

  .spinner {
    font-size: 12px;
    color: var(--primary-color);
    margin-top: 5px; }

  .checkboxSelect {
    height: 40px;
    padding: 0 0 0 11px;
    border-radius: 5px;
    border: 1px solid #d9d9d9;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;

    &.loading {
      padding-right: 11px;

      .spinner {
        margin-top: 0px; } }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
      cursor: not-allowed; } }

  .userHourReportCheckbox {
    padding: 6px 0 6px 8px;
    width: 100%;

    & + .userHourReportCheckbox {
      margin: 0; }

    span + span {
      flex-grow: 2;
      padding-right: 0; } }

  .fieldWrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .fieldLabel {
      color: var(--dt-qs-gray);
      font-size: 13px;
      line-height: 15px; }

    .fieldRow {
      display: flex;
      flex-direction: column;
      gap: 5px; } }

  .stepsWrapper {
    display: flex;
    flex-direction: row;
    position: relative;

    .stepsContent {
      flex: 0 0 364px;
      margin-left: 15px;
      padding: 0 15px;
      border-left: 1px solid #eee3;
      display: flex;
      flex-direction: column;
      gap: 20px; } }

  .stepsHeader {
    flex: 0 0 190px;
    height: 510px;
    padding-top: 25px;

     :global {
      .ant-steps-item {
        opacity: .5; }

      .ant-steps-item.ant-steps-item-active,
      .ant-steps-item.ant-steps-item-finish {
        opacity: 1; }

      .ant-steps-item.ant-steps-item-active,
      .ant-steps-item.ant-steps-item-finish {
        color: var(--primary-color);

        .ant-steps-item-content > .ant-steps-item-title {
          color: var(--primary-color); }

        .ant-steps-item-icon {
          background: var(--primary-color);
          box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, .2);

          .ant-steps-icon {
            color: white; } } }

      .ant-steps-item-icon {
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(255, 255, 255, 0.3);
        color: white;
        border-width: 0; } } }

  .step {
    height: 85px; }

  .nextStepBtn {
    border-radius: 5px;
    font-size: 12px;
    padding: 0px 12px;
    font-weight: 500; }

  .withSeparator {
    margin-top: 20px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      border-top: 1px solid rgba(114, 121, 129, 0.1) !important;
      width: 100%; } }

  .withBlack {
    border-radius: 5px !important;
    background-color: var(--background-gray); }

  .userList {
    max-height: 360px;
    overflow-y: auto; }

  .buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%; }

  .collapseItem {
    &:global(.ant-collapse-item-active) {
       :global {
        .ant-collapse-header {
          .ant-collapse-arrow {
            svg {
              transform: rotate(-90deg) !important; } } } } }

     :global {
      .ant-collapse-header {
        flex-direction: column-reverse;
        align-items: center;

        .ant-collapse-arrow {
          svg {
            transform: rotate(90deg); } } } } }

  .fromToTitle {
    padding-right: 20px;

    &:after {
      content: '';
      position: absolute;
      right: 8.5px;
      top: 10px;
      border: 2px solid rgba(0,0,0, 0.3);
      border-left-color: transparent;
      width: 12px;
      height: calc(100% - 20px);
      border-radius: 0 5px 5px 0; } }

  .collapseTitle {
    display: flex;
    align-self: flex-start;
    flex-direction: column;
    font-size: 14px;
    justify-content: center;
    position: relative;
    gap: 15px;

    .replaceIcon {
      position: absolute;
      right: 0px;
      top: 50%;
      height: 25px;
      transform: translateY(-50%);
      z-index: 1;
      background-color: var(--background-gray); }

    .label {
      font-size: 12px;
      color: var(--primary-color);
      display: block; } }

  .userWrapper {
    display: flex;
    justify-content: space-between;

    span + span {
      text-align: right; } }

  .partnerNameWrapper {
    display: flex;
    flex-grow: 2;

    .partnerName {
      flex-grow: 2; }

    .hours {
      text-align: right;
      padding-right: 0; } }

  .confirmStep {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .collapseTitle {
      & > svg {
        background-color: var(--background-gray); }

      .collapseTitlePart {
        span {
          background-color: transparent; } } } }

  .collapseTitlePart {
    display: flex;
    align-content: center;
    gap: 10px;
    justify-content: flex-start;

    span {
      position: relative;
      z-index: 1;
      background-color: var(--background-gray);
      padding-right: 5px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis; } }

  .usersInfo {
 }    // font-size: 18px

  .list {
    display: flex;
    flex-direction: column;
    gap: 20px; } }

body[data-theme='light'] {
  .reportSelect:global(.ant-select-disabled) {
    background-color: var(--background-gray); }

  .checkboxSelect {
    background: var(--background-gray);
    border-color: transparent; }

  .stepsWrapper .stepsContent {
    border-color: #f0f0f0; }

  .reportSelect {
    background: var(--background-gray); }

  .collapseIcon {
    background: var(--background-gray);
    color: black; } }

body[data-theme='dark'] {
  .collapseIcon {
    background: var(--dt-qs-dark);
    color: white; }

  .collapseItem {
     :global {
      .anticon {
        color: white; } } }

  .collapseTitle {
    color: white; }

  .checkboxSelect {
    background: var(--dt-qs-dark); }

  .reportSelect:global(.ant-select-disabled) {
    background-color: var(--dt-qs-dark); }

  .confirmStep .collapseTitle > svg {
    background-color: var(--background-gray); }

  .fromToTitle:after {
    border-color: #727981;
    border-left-color: transparent; }

  .stepsHeader {
     :global {
      .ant-steps-item {
        .ant-steps-item-icon {
          .ant-steps-icon {
            color: black; } } }

      .ant-steps-item-content {
        & > .ant-steps-item-title,
        & > .ant-steps-item-description {
          color: white; }

        & > .ant-steps-item-description {
          opacity: .5; } } } } }
