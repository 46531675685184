body[data-theme='light'],
body[data-theme='dark'] {
  .yearSelector {
    display: inline-flex;
    align-items: center;
     :global {
      .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
        padding-left: 0; } } }

  .yearSelect {
    margin-right: 3px;
    flex: 0 0 46px;
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    line-height: .86; }

  .spinner {
    color: var(--primary-color);
    animation: spinnerAnimation 1s linear infinite; }

  .yearSelectorTitle {
    font-family: Gilroy;
    font-size: 20px;
    color: #000000; }


  @keyframes spinnerAnimation {
    0% {
      transform: rotate(0); }

    100% {
      transform: rotate(360deg); } } }
