@import '../../../../../../../helpers/tableVariables.sass';
@import '../../../../../../../../../../../helpers/styles/mixins.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .columnHeader {
    flex: 0 0 78px;
    text-align: center;
    display: flex;
    flex-direction: column;
    border-top: 1px solid rgba(223, 231, 239, 0.5);
    border-left: 1px dashed opacify($grayBorderColor, .5);
    background-color: white;
    position: relative;

    &.closedMonth {
      border-left-color: #dfe7ef73;

      &.cutDay,
      &.afterCut {
        border-left-color: #EEE9AC80 !important; }

      // &.weekend
      //   border-left-color: #f4536e1f !important

      & > * {
        opacity: .5; } }

    &:before {
      position: absolute;
      top: -27px;
      height: calc(100% + 27px);
      width: 100%; }

    &.endOfPrevMonth:before {
      content: '';
      background: linear-gradient(-90deg, rgba(5, 17, 46, 0.05) 0%, rgba(2, 14, 46, 0) 100%);
      right: 0; }

    &.startOfNextMonth:before {
      content: '';
      background: linear-gradient(90deg, rgba(5, 17, 46, 0.05) 0%, rgba(2, 14, 46, 0) 100%);
      left: -1px; }

    &.afterCut {
      border-left-color: #EEE9AC !important; }

    &.cutDay {
      background: #fefce5 !important;
      border-left-color: #EEE9AC !important;

      .dayNumber,
      .dayName {
        color: #B6B178 !important;
        opacity: .5; } }

    &.today {
      box-shadow: inset 0px 3px 0px 0px #f4536e; }

    &.afterWeekend {
      border-left-color: opacify($redBorderColor, .5); }

    &.weekend {
      border-left-color: opacify($redBorderColor, .5);

      .dayNumber,
      .dayName {
        color: var(--primary-color); } } }

  .dayNumber {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 15px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 4px;
    color: #23354A; }

  .dayName {
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 10px;
    text-align: center;
    text-transform: uppercase;
    opacity: .5;
    color: #727981; }

  .additionalInfo {
    position: relative;
    margin-top: 7px;
    display: flex;
    flex-grow: 2;
    align-items: flex-end;
    justify-content: center;

    &:after {
      content: '';
      position: absolute;
      left: 50%;
      width: calc(100% - 14px);
      top: 0;
      transform: translateX(-50%);
      border-top: 1px solid #72798133; } }

  .workedHoursWrapper {
    height: 17px;
    margin-bottom: 3px;
    width: calc(100% - 24px);
    padding: 3px 8px;
    transition: .25s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    position: relative; }

  .workedHours {
    color: #727981;
    font-style: normal;
    font-family: Gilroy;
    font-weight: bold;
    line-height: 10px;
    font-size: 11px;
    position: absolute;
    transition: .25s ease; }

  .holidayIcon {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 1px;
    top: 1px;
    z-index: 2; }

  @include mq('2k', min) {
    .holidayIcon {
      width: 18px;
      height: 18px; }

    .dayNumber {
      margin-top: 15px;
      margin-bottom: 6px;
      font-size: 16px; }

    .dayName {
      font-size: 12px; }

    .workedHours {
      font-size: 12px; } } }

body[data-theme='dark'] {
  .columnHeader {
    background-color: var(--dt-ts-bg-gray);
    border-left-color: var(--dt-ts-br-gray);
    border-top-color: var(--dt-ts-br-gray);

    .dayName {
      color: #bec3c9; }

    .workedHours {
      color: #9BA3AC; }

    .dayNumber {
      color: #ccd1d8; }

    &.closedMonth {
      border-left-color: var(--dt-ts-br-gray);

      &.cutDay {
        border-color: var(--dt-ts-br-gray) !important;
        background-color: #242b32 !important; }

      &.afterCut {
        border-left-color: var(--dt-ts-br-gray) !important; } }

    &.cutDay {
      background: #2E363E !important;
      border-left-color: var(--dt-ts-br-gray) !important;

      .dayName,
      .dayNumber {
        color: var(--dt-qs-gray) !important;
        opacity: 1; } }

    &.afterWeekend {
      border-left-color: #61464a !important; }

    &.afterCut {
      border-left-color: var(--dt-ts-br-gray) !important; }

    &.weekend {
      border-left-color: #61464a !important;

      .dayName,
      .dayNumber {
        color: var(--dt-primary-color); } } } }
