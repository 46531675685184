@import '../Table/helpers/tableVariables.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .settings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 80px;
    background-color: #fff;
    padding-right: 24px;
    transition: opacity 0.3s ease, background-color 0.3s ease;
    overflow-x: auto; }

  .settingsDisabled {
    background-color: var(--tracker-gray); } }

body[data-theme='dark'] {
  .settings {
    background-color: var(--dt-ts-bg-gray); }

  .settingsDisabled {
    background-color: var(--dt-ts-bg-gray); } }
