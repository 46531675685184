@import '../../../helpers/styles.module.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .day {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 0 0 100%;
    padding-bottom: 5px; }

  .workedHours {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    opacity: .5;
    position: relative;

    color: #9BA3AC;
    transition: .25s ease;

    &.hidden {
      opacity: 0; } }

  .backgroundLine {
    position: relative;
    margin-bottom: 10px;
    margin-top: 10px;
    height: 100%; }

  .dash,
  .fill {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: .25s ease; }

  .dash {
    height: 100%;
    border-left: 1px dashed #9BA3AC;
    opacity: .5; }

  .fill {
    transition: .25s ease;
    border-radius: 3px;
    width: 5px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      height: 100%;
      border-radius: 3px; }

    &:before {
      background: linear-gradient(0deg, #BFCBD9 0%, #E3EDF9 100%); }

    &:after {
      background: linear-gradient(0deg, #F4536E 0%, #FF227F 100%);
      opacity: 0;
      transition: .25s ease; } }

  .dayNameWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--font-black); }

  .loading {
    opacity: 0;
    transform: scale(0);

    .dash {
      opacity: 0; } }

  .dayNumber {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    text-align: center; }

  .dayName {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 8px;
    line-height: 10px;
    text-transform: uppercase; }

  .today {
    .dayNameWrapper {
      color: var(--primary-color); }

    .fill:after {
      opacity: 1; }

    .dash {
      border-left: 1px solid #F4536E;
      opacity: .3; } }

  .firstDay {
    .dash {
      border-left: 1px solid #9ba3ac26;
      opacity: 1;
      height: calc(100% + 23px); }

    .workedHours {
      left: 13px;
      top: 18px; } } }
