body[data-theme='light'],
body[data-theme='dark'] {
  .requests-table {
    &_wrapper {
      &:not(:last-child) {
        margin-bottom: 40px; } }

    &_request-button {
      color: #727981;
      padding: 5px 11px 7px;
      border-color: #E5EAEE;

      &:hover {
        color: #4f5358;
        background: #cdd0d3;
        border-color: #cdd0d3; }

      svg {
        margin-right: 5px; } }

    &_title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center; }

    &_title {
      font-family: Gilroy;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      display: flex;
      align-items: center; }

    &_logo {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      margin-right: 6px;
      display: inline-flex;
      justify-content: flex-end;
      padding: 3px;
      vertical-align: bottom;
      background-color: white;

      &-svg {
        width: 20px !important;
        height: 20px !important; }

      & > div {
        width: 10px;
        height: 10px;
        border-radius: 3px; } }

    &_no-requests {
      display: flex;
      align-items: center; }

    &_no-requests-warning {
      font-family: Roboto;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #727981;
      margin: 0 10px 0 0; }

    &_own {
      border-top: 1px solid #D1DBE3;
      margin-top: 20px;

       :global {
        .ant-table,
        table,
        thead,
        thead > tr,
        tbody > tr,
        tbody > tr > td,
        thead > tr > th {
          border-color: transparent;
          background: transparent; }



        thead > tr > th {
          font-family: Gilroy;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          line-height: 14px;
          letter-spacing: 0em;
          text-align: left;
          color: #727981; }

        tbody > tr > td {
          border-bottom-color: #D1DBE3;
          font-family: Roboto;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          letter-spacing: 0em;
          text-align: left;
          cursor: pointer;
          color: var(--font-black); }

        .requests-table_candidates {
          display: flex;
          align-items: center;
          gap: 6px; }

        .requests-table_avatar {
          transition: .25s ease;
          position: relative;
          max-width: 32px;
          min-width: 32px;
          height: 32px;
          width: 32px;

          img {
            width: 100%; }

          &-wrapper {
            border-radius: 50%;
            border: 4px solid #F1F3F5;
            z-index: 1;

            &:not(:first-child) {
              margin-left: -16px; } } }

        .requests-table_names {
          text-transform: capitalize; } } }

     :global {
      .borderless {
        border-right: 1px solid transparent !important; }

      .action-column {
        padding: 0;
        box-shadow: none; } } } }

body[data-theme='light'] {
  .requests-table {
    &_own {
       :global {
        .ant-table-tbody > tr.ant-table-row:hover > td {
          background: #e7ecf0;

          .requests-table_avatar-wrapper {
            border-color: #e7ecf0; } } } }
    &_request-button {
      background: #E5EAEE; } } }

body[data-theme='dark'] {
  .requests-table {
    &_own {
      border-top: 1px solid var(--border-gray-30);
       :global {
        tbody > tr > td {
          border-color: var(--dt-qs-gray-20); }
        .requests-table_avatar-wrapper {
          border-color: var(--border-gray-30); } } } } }
