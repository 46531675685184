$popoverBackgroundColor: #222222;

body[data-theme='light'],
body[data-theme='dark'] {
  .tooltip {
    &_inner {
      background-color: $popoverBackgroundColor;
      border-radius: 10px;
      display: flex;
      flex-direction: column; }

    &_tongue {
      position: absolute;
      height: 9px;
      fill: $popoverBackgroundColor;
      transform: translate(-10px, -50%) rotate(90deg);
      top: 50%;
      left: 0; }

    &_overlay {
       :global {
        .ant-tooltip-inner {
          padding: 12px 20px;
          border-radius: 10px;
          background-color: #222222; }

        .ant-tooltip-arrow {
          display: none; } } } }

  .employee-drawer {
    height: 100%;

    .author-avatar {
      width: 128px;
      height: 128px;
      border-radius: 50%; }

     :global {
      .ant-spin-nested-loading > div > .ant-spin {
        max-height: none;
        min-height: 600px; }

      .ant-drawer-body {
        padding: 34px 0 0 32px; }

      .ant-drawer-footer {
        display: flex;
        justify-content: flex-end; } }

    .drawer-content_category {
      display: flex;
      width: 100%;
      padding: 20px 25px 23px 0;
      border-bottom: 1px solid #E8EDF1;

      &:last-of-type {
        border-bottom: none; }

      .category_title {
        min-width: 140px;
        text-align: left;
        font-size: 15px;
        color: var(--qs-gray);

        &.showAll {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-top: 5px; } }

      .category_body {
        width: 100%;

         :global {
          .ant-typography {
            margin-bottom: 24px; } }

        &_skills {
          max-height: 220px;
          overflow-y: auto; } }

      .field_title {
        font-size: 12px;
        line-height: 12px;
        color: var(--qs-gray); }

      .english-field {
        border-top: 1px solid #E8EDF1;
        padding-top: 14px;
        margin-top: 14px;
        display: flex;
        align-items: center;

        &_title {
          min-width: 95px;
          font-family: Gilroy;
          font-weight: bold;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.02em;
          color: var(--qs-gray); } }

      .skillset-field {
        display: flex;
        align-items: center;

        .skilltag-attitudes {
          display: flex;
          align-items: center; }

        &_title {
          min-width: 95px;
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: var(--qs-gray); }

        &_tags {
          display: flex;
          flex-wrap: wrap;
          align-items: center; } } }

    .drawer-content_header {
      width: 100%;
      padding-bottom: 32px;
      border-bottom: 1px solid #E8EDF1;
      padding-right: 8px;

      .header_name {
        font-family: Gilroy;
        font-size: 25px;
        line-height: 29px;
        color: var(--font-black); }

      .header_occupation {
        font-weight: bold;
        font-size: 13px;
        line-height: 15px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: rgba(#727981, 0.75);

        .title-splitter {
          padding: 0 4px; } } } }

  .primary-skill-tag {
    margin: 0;
    padding: 0 25px; }

  .show-all-button {
    padding-left: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    color: var(--primary-color);
    text-align: left;

    &:hover,
    &:focus {
      background-color: transparent;
      color: var(--primary-color);
      opacity: 0.7; }

    &_icon {
      position: relative;
      top: 2px;
      margin-right: 10px; } }

  .english-skill-tag {
    display: flex;
    align-items: center;
    color: var(--font-black);
    background-color: #fff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    text-transform: none; }

  .empty-field {
    color: var(--font-black);
    padding: 5px 0; }

  .customTitle {
    color: var(--primary-color); }

  .titleSkillTag {
    margin-left: 0; }

  .employeeDrawerFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 14px; }

  .socialNetworkButton {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    height: 40px;
    border-radius: 5px;
    padding: 0 8px;
    color: var(--font-black);
    background: none;
    transition: .25s ease;

    &.disabled {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      cursor: not-allowed; }

    &:not(.disabled):hover {
      background: #E5EAEE;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05); }

    svg {
      width: 24px;
      height: 24px; } }

  .emptySocialNetwork {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 0;
    height: 40px;
    color: var(--font-black); } }

body[data-theme='dark'] {

  .employee-drawer {
    .drawer-content_category {
      .english-field {
        border-top: 1px solid var(--border-gray-30); } }

    .drawer-content_category,
    .drawer-content_header {
      border-bottom: 1px solid var(--border-gray-30); } }

  .english-skill-tag {
    background-color: var(--second-backround-black); }

  .employee-drawer {
    .drawer-content_header {
      .header_occupation {
        color: var(--dt-qs-gray); } } }

  .socialNetworkButton {
    &:not(.disabled):hover {
      background-color: darken(#2d3743, 4); } } }

