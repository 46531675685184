body[data-theme='light'],
body[data-theme='dark'] {

  .editProfileLayout {
    margin-left: var(--sidebar-width);
    height: 100%; }

  .editProfileSpinner {
     :global {
      .ant-spin {
        max-height: none !important; } } }

  .editProfileContent {
    min-height: 100vh; }

  .notSelectedUserPreview {
    text-align: center;

    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center; }

  .previewLoading {
    visibility: hidden; }

  .notSelectedUserTitle {
    font-size: 48px;
    font-family: Gilroy, Helvetica Neue; }

  .notSelectedUserIcons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;

    color: rgb(174, 184, 195); } }
