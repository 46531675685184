@import "../../../../../helpers/styles.module.sass";
@import "../components/SkillElement/helpers/styles.module.sass";

body[data-theme='light'],
body[data-theme='dark'] {

  .skill-element_users-skill {
    &--dragging {
      opacity: 1 !important;

      & > div {
        box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, .25) !important; } }

    &--ghost {
      & svg {
        opacity: 0; }

      & > div {
        background-color: #E8EDF1 !important;
        color: transparent !important; } } }

  .skillset_experience {
    @extend .skillset_experience;

    border-left: 1px solid var(--grey-background-break-gray);

    &-body--hovered {
      border-radius: 4px;
      background-color: rgba(#D1DBE3, .3);
 }      // transition: .25s ease

    &-list {
      height: 100%;
      min-height: 40px; } // 1 line

    &--search-list-is-opened {
      border-radius: 5px 5px 0 0 !important; }

    &--editing-in-process {
      border: 1px solid #E8EDF1 !important;
      background-color: white;
      border-radius: 5px;
      padding: 15px 3px;
      min-width: calc((100% - 62px) / 4);
      max-width: calc((100% - 62px) / 4);

      & + .skillset_experience {
        margin-left: 14px; }

      .skillset_experience-header {
        height: 30px;
        margin-bottom: 10px;
        padding-left: 15px; }



      .skillset_experience-title {
        text-transform: uppercase; } } } }

body[data-theme='dark'] {
  .skillset_experience {
    border-left: 1px solid var(--dt-qs-gray-20); }

  .skillset_experience--editing-in-process {
    border: 1px solid transparent !important;
    background-color: var(--dt-qs-dark); } }
