body[data-theme='light'],
body[data-theme='dark'] {
  .partner {
    display: flex;
    flex-direction: row;
    align-items: center; }

  .maskWrapper {
    margin-right: 10px; }

  .actions {
    display: flex;
    gap: 10px;
    align-items: center;
    opacity: 0;
    transition: .25s ease;
    padding-right: 5px; }

  .viewUserTimeSheet {
    height: 18px;
    transition: .25s ease;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:hover {
      background: rgba(255, 255, 255, 0.03); } }

  .expandableCell {
    color: var(--primary-color);
    font-weight: bold; }

  .threeDotsButton {
    padding: 0;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    padding-right: 5px;
    transition: .25s ease;
    border-radius: 2px; }

  .participantsTable {
     :global {
      tr {
        cursor: pointer;

        &:hover .three-dots-button {
          opacity: 1; } } } } }
