$sidebarWidth: 60px;
$columnHeaderHeight: 100px;

.tableSelectionWrapper {
  position: absolute;
  width: calc(100% - #{$sidebarWidth});
  height: calc(100% - #{$columnHeaderHeight});
  left: $sidebarWidth;
  top: $columnHeaderHeight;
  z-index: 11;
 }  // pointer-events: none
