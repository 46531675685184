body[data-theme='light'],
body[data-theme='dark'] {
  /* .dots* prefix classes using in Table action column (some 3-dots stuff) */
  /* z-index: 1000 < modals/drawer z-index */
  .dotsPopoverOverlay {
    width: 224px;
    z-index: 999;
    line-height: 16px;

     :global {
      .ant-popover-inner {
        border-radius: 5px; }

      .ant-popover-inner-content {
        padding: 0; } } }

  .popoverNoArrow {
    padding-top: 0;

     :global {
      .ant-popover-arrow {
        display: none; } } }

  /* You have to set opacity to 1 in your table row class */

  .dotsPopoverItemWrapper {
    width: 100%;

    display: flex;
    flex-direction: column; }

  .dotsPopoverButton {
    height: auto;
    text-align: left; }

  .dotsPopoverButton,
  .dotsPopoverItem {
    padding: 8px 0 8px 16px;
    transition: 0.3s ease;
    cursor: pointer;
    font-size: 13px;
    line-height: 15px;
    color: var(--font-black);
    border-bottom: 1px solid var(--dt-qs-gray-20);
    background: transparent;

    &:first-child {
      padding-top: 18px; }

    &:last-child {
      border: none;
      padding-bottom: 18px; }

    &:hover {
      color: var(--primary-color); } }

  .dotsPopoverItemText {
    display: inline-block;
    width: 156px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  .dotsPopoverItemActive {
    color: var(--primary-color); }

  .dotsPopoverSplitter {
    width: 100%;
    height: 1px;
    background-color: var(--dt-qs-gray-20); } }
