body[data-theme='light'],
body[data-theme='dark'] {

  .dropdownSelectMenuItem {
    border-bottom: 1px solid var(--dt-qs-gray-20);
    margin: 0 !important;

    &:last-of-type {
      border-bottom: none; } }

  .dropdownSelectContent {
    border-radius: 5px;
    padding: 8px 0 2px 0;
    background-color: #fff;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

     :global {
      .ant-menu-item-selected {
        background-color: #fff !important; } } }

  .dropdownSelectButton {
    position: relative;
    border-radius: 5px;
    font-weight: normal;
    width: 150px;
    height: 40px;
    display: flex;
    padding: 12px;
    align-items: center;
    cursor: pointer;
    background-color: #E5EAEE;
    font-size: 14px;
    transition: 0.3s ease; }

  .dropdownSelectOpen {
    color: var(--font-white);
    background-color: var(--primary-color);

    .selectDropdownIcon {
      fill: #fff; } }

  .dropdownSelectDescription {
    font-size: 10px;
    line-height: 12px;
    padding-left: 16px;
    color: #00000080; }

  .selectDropdownIcon {
    transition: 0.3s ease;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    right: 0; }

  .dropdownSelectCheckbox {
    transition: 0.3s ease;
    font-size: 13px; } }

body[data-theme='dark'] {
  .dropdownSelectButton {
    color: #fff; }
  .dropdownSelectDescription {
    color: var(--font-white-85); }

  .dropdownSelectContent {
    background-color: var(--dt-qs-dark);

     :global {
      .ant-menu-item-selected {
        background-color: var(--dt-qs-gray-20) !important; } } }

  .dropdownSelectButton {
    background-color: var(--dt-qs-dark); } }
