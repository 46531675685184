@import '../../../../../helpers/styles/commonStyles.sass';
body[data-theme='light'],
body[data-theme='dark'] {

  .skillTagRowIcon {
    color: var(--qs-gray);
    cursor: pointer; }

  .popoverLoadingItem {
    display: flex;
    align-items:  center;
    gap: 4px; }

  .drawerSkillTag {
    margin: 0 5px; }

  .skillTagModal {

    .skill-tag {
      padding: 6px 10px;
      margin: 0 5px;
      background: #263341;
      font-size: 13px;
      border-radius: 100px;
      color: #fff; } } }

