body[data-theme='light'],
body[data-theme='dark'] {
  .skillDescriptionIcon {
    color: var(--qs-gray); }

  .descriptionLink {
    margin: 0;
    width: 230px;
    font-size: 14px;
    font-family: Gilroy;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  .activeDescriptionLink {
    color: var(--primary-color); }

  .descriptionPopoverOverlay {
     :global {
      .ant-popover-arrow {
        display: none; }
      .ant-popover-inner {
        border-radius: 5px; } } }

  .descriptionContent {
    min-width: 200px;
    display: flex;
    flex-direction: column; }

  .filledContent {
    width: 950px; }

  .descriptionTitles {
    display: inline-flex;
    flex-wrap: wrap;
    max-height: 500px;
    overflow: auto;
    gap: 4px; }

  .descriptionText {
    min-height: 75px;
    font-size: 16px; } }
