body[data-theme='light'],
body[data-theme='dark'] {

  .drawer {
    height: 100%;

     :global {
      .ant-spin-nested-loading > div > .ant-spin {
        max-height: none;
        min-height: 600px; }

      .ant-drawer-body {
        padding: 0;
        position: relative; }

      .ant-drawer-footer {
        display: flex;
        justify-content: flex-end; } } }

  .checkbox {
    position: absolute;
    bottom: 22px; }

  .sum {
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px; }

  .drawerContent {
    margin-top: 0;
    margin-left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative; }

  .withPadding {
    padding-left: 24px;
    padding-right: 24px; }

  .activities {
    flex-grow: 0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05); }

  .activity {
    display: flex;
    align-items: center;
    height: 48px; }

  &.selected .activityLabel {
    font-weight: bold; }

  .activityIconWrapper {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    border: 1px solid;
    margin-right: 6px;
    border-radius: 5px; }

  .activityIcon {}

  .activityText {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    margin: 0;
    flex-grow: 2;

    font-family: Roboto;
    font-style: normal;
    font-size: 12px;
    line-height: 14px; }

  .drawerContentBody {
    overflow-y: hidden;
    display: flex;
    flex-grow: 1;
    flex-direction: column; }

  .closeButton {
    border-radius: 5px;
    height: 34px; }

  .markedHourSpin {
    height: 100%;
     :global {
      .ant-spin-container {
        height: 100%; } } } }

body[data-theme='dark'] {
  .sum {
    color: white; } }
