body[data-theme='light'],
body[data-theme='dark'] {
  .monthPopoverItem {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:last-of-type,
    &:first-of-type {
      padding: 8px 0 8px 16px; }

    &:hover {
      cursor: default;

      .monthPopoverSwapIcon {
        opacity: 0.5;
        width: auto;
        visibility: visible; } } }

  .monthPopoverCounter {
    display: flex;
    align-items: center;
    gap: 8px; }

  .monthPopoverSwapIcon {
    opacity: 0;
    width: 0;
    visibility: hidden;
    transition: 0.3s all ease;
    cursor: pointer;

    &:hover {
      fill: var(--primary-color);
      opacity: 1 !important; } }

  .monthPopoverSalary {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: var(--qs-gray); } }
