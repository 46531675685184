$popoverBackgroundColor: #222222;

body[data-theme='light'],
body[data-theme='dark'] {

  .attitude-popover {
    &_inner {
      background-color: $popoverBackgroundColor;
      border-radius: 10px;
      display: flex;
      flex-direction: column; } }

  .qs-attitude-selector {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, .1);
    box-sizing: border-box;
    background-color: rgba(255, 255, 255, .1);
    border-radius: 16px;
    cursor: pointer;
    margin: 3px;
    transition: .25s ease;
    outline: none;
    position: relative;

    &::before {
      display: none;
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: 100%;
      border-radius: 16px;
      border: 2px solid transparent;
      border-top-color: rgba(255, 255, 255, .75);
      box-sizing: content-box;
      animation: rotateAnimationAtCenter 1s linear infinite;
      transition: .25s ease;
      opacity: 0; }

    &--active {
      background-color: rgba(244, 83, 110, 0.3);
      border-color: #d42240b0; }

    &--is-loading {
      &::before {
        display: inline;
        opacity: 1; } }

    &--disabled {
      background-color: rgba(255, 255, 255, 0.3);
      border-color-width: 0;
      pointer-events: none;
      cursor: default; }

    &:not(.qs-attitude-selector--active):not(.qs-attitude-selector--disabled):hover {
      background-color: rgba(244, 83, 110, 0.15);
      border-color: rgba(#d42240b0, .5); } }

   :global {
    @keyframes rotateAnimationAtCenter {
      0% {
        transform: translate(-50%, -50%) rotate(0deg); }

      100% {
        transform: translate(-50%, -50%) rotate(360deg); } } }

  .tooltip-attitude {
    &_overlay {
       :global {
        .ant-tooltip-inner {
          padding: 12px 20px;
          border-radius: 10px;
          background-color: #222222; }

        .ant-tooltip-arrow {
          display: none; } } }

    &_inner {
      position: relative; }

    &_tongue {
      position: absolute;
      transform: rotate(90deg);
      height: 9px;
      left: -10px;
      bottom: 18px;
      fill: #222222; } }

  .attitude-popover {

     :global {
      .ant-popover-inner {
        border-radius: 10px; }

      .ant-popover-arrow {
        display: none; }

      .ant-popover-inner-content {
        border-radius: 10px;
        background-color: #222222;
        padding: 2px 2px; } } }

  .attitudePopoverTongue {
    position: absolute;
    height: 9px;
    top: 50%;
    left: -7px;
    transform: translate(0, -50%) rotate(90deg);
    fill: $popoverBackgroundColor; } }
