body[data-theme='light'] .qs--text-center,
body[data-theme='dark'] .qs--text-center {
  text-align: center; }

body[data-theme='light'] .qs--text-left,
body[data-theme='dark'] .qs--text-left {
  text-align: left; }

body[data-theme='light'] .qs--text-right,
body[data-theme='dark'] .qs--text-right {
  text-align: right; }

body[data-theme='light'] .qs--full-height,
body[data-theme='dark'] .qs--full-height {
  height: 100%; }

body[data-theme='light'] .qs--full-width,
body[data-theme='dark'] .qs--full-width {
  width: 100%; }

body[data-theme='light'] .qs--no-selection,
body[data-theme='dark'] .qs--no-selection {
  user-select: none; }

body[data-theme='light'] .qs--mt-1,
body[data-theme='dark'] .qs--mt-1 {
  margin-top: 15px; }

body[data-theme='light'] .qs--position-a,
body[data-theme='dark'] .qs--position-a {
  position: absolute; }

body[data-theme='light'] .qs--position-r,
body[data-theme='dark'] .qs--position-r {
  position: relative; }

body[data-theme='light'] .qs--position-s,
body[data-theme='dark'] .qs--position-s {
  position: static; }

body[data-theme='light'] .qs--childs-to-right,
body[data-theme='dark'] .qs--childs-to-right {
  justify-content: flex-end;
  display: flex; }

body[data-theme='light'] .qs--childs-to-center,
body[data-theme='dark'] .qs--childs-to-center {
  justify-content: center;
  display: flex; }

body[data-theme='light'] .qs--modal-is-opened,
body[data-theme='dark'] .qs--modal-is-opened {
  overflow: hidden; }

body[data-theme='light'] .qs--title,
body[data-theme='dark'] .qs--title {
  font-weight: bold;
  padding-bottom: 10px; }
