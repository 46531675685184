body[data-theme='light'],
body[data-theme='dark'] {
  .specialists {
    min-width: 1200px;
    height: 100%;

    &_header {
      background-color: var(--white); }

    &_table {
      width: 100%;

      &.no-data {
         :global {
          .ant-table-cell-fix-left-last {
            &:after {
              background: none;
              width: 30px; } } } }

       :global {
        .ant-table-cell-fix-left-last {
          &:after {
            background: linear-gradient(90deg, #000000 -310.34%, rgba(0, 0, 0, 0) 25%);
            width: 278px; } } }

       :global {
        table th {
          font-family: Gilroy;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          text-transform: none;
          border-bottom: 1px solid transparent; }

        table th.attitudes-column {
          box-shadow: none;

          & ~ .attitudes-column {
            box-shadow: inset 1px 0 0 #DCE4ED; } }

        .ant-table-tbody > tr > td {
          padding: 14px 16px;
          background-color: var(--background-gray); }

        //.ant-pagination-item-link
        //  border: none
        //  background-color: #F2F4F6

        //.ant-pagination-prev,
        //.ant-pagination-item,
        //.ant-select:not(.ant-select-customize-input) .ant-select-selector
        //  background-color: #F2F4F6

        //.ant-table-tbody > tr > .attitudes-column
        //  box-shadow: inset 0 -1px 0 #D9E2EC
        //background-color: #F2F4F6

        //& ~ .attitudes-column
 }        //  box-shadow: inset 1px 0 0 #DCE4ED, inset 0 -1px 0 #D9E2EC


      &_total {
        font-family: Gilroy;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        color: var(--font-black);
        padding-top: 20px;
        padding-bottom: 15px;
        border-bottom: 1px solid var(--grey-background-break-gray); } } } }

body[data-theme='light'] {
  .specialists {
    &_table {
       :global {
        .ant-pagination-item-link {
          border: none;
          background-color: #F2F4F6; }
        .ant-pagination-prev,
        .ant-pagination-item,
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
          background-color: #F2F4F6; }

        .ant-table-tbody > tr > td {
          box-shadow: inset 0px -1px 0px #D9E2EC; }

        .ant-table-tbody > tr > .attitudes-column {
          box-shadow: inset 0 -1px 0 #D9E2EC;
          background-color: #F2F4F6;

          & ~ .attitudes-column {
            box-shadow: inset 1px 0 0 #DCE4ED, inset 0 -1px 0 #D9E2EC; } } } } } }

body[data-theme='dark'] {
  .specialists {}
  &_table {
    :global {
    .ant-table-cell-fix-left-last {
      &:after {
        background: linear-gradient(90deg, #000000 -310.34%, rgba(0, 0, 0, 0) 25%); } } } } }
