body[data-theme='light'],
body[data-theme='dark'] {
  .activityListItem {
    position: relative;
    padding-left: 20px;
    padding-right: 10px;
    cursor: pointer;
    transition: 0.3s ease, padding 0s;
    color: var(--font-black);
    user-select: none;

    &:hover {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);

      .activityListItemDescription {
        color: var(--primary-color); }

      .removeActivityButton {
        opacity: 1;
        visibility: visible; } }

     :global {
      .ant-list-item-meta-avatar {
        margin-right: 6px; } } }

  .activityListItemMeta {
    display: flex;
    align-items: center; }

  .activeActivity {
    background-color: #fff;
    padding-right: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05); }

  .collapsedActivityItem {
    padding-left: 8px;

    .descriptionText {
      display: none; } }

  .activityListItem.editModeActivityItem {
    cursor: grab; }

  .monthClosedActivityItem {
    cursor: default;

    &:hover {
      box-shadow: none;

      .activityListItemDescription {
        color: var(--font-black); } } }


  .activityListItemDescription {
    color: var(--font-black);
    font-size: 12px;
    line-height: 14px;

    display: flex;
    justify-content: space-between;
    align-items: center; }

  .descriptionTextWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
    transition: 0.3s ease; }

  .descriptionText {
    width: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

  .dragIndicator {
    fill: #727981; }

  .removeActivityButton {
    line-height: 0;
    opacity: 0;
    visibility: hidden; }

  .removeActivityIcon {
    &:hover {
      fill: var(--primary-color); } }

  .collapsedActivityIcon {
    position: absolute;
    top: 0;
    left: 0; } }

body[data-theme='light'] {
  .activityListItem {
    &:last-of-type {
      border-bottom: 1px solid var(--gray-4) !important; }

    &:hover {
      background-color: #fff; } }

  .monthClosedActivityItem {
    &:hover {
      background-color: #fff; } }

  .activityListItem.editModeActivityItem {
    background-color: var(--tracker-gray); } }

body[data-theme='dark'] {
  .activeActivity {
    background-color: var(--dt-qs-gray-20); }
  .activityListItem {
    &:last-of-type {
      border-bottom: 1px solid var(--dt-qs-gray-20) !important; } } }


