body[data-theme='light'],
body[data-theme='dark'] {
  .flexRow {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;

    span {
      flex-grow: 2; } }

  .mobileReportHourTable {
     :global {
      .indent-level-1,
      .indent-level-2 {
        padding-left: 0 !important; } } }

  .expandedIcon {
    transition: 0.3s ease;
    float: left;
    fill: var(--font-black);

    &.expanded {
      transform: rotate(90deg); } }

  .expandedRowActivity {
    padding-left: 24px; }

  .mobileExpandedRowActivity {
    padding-left: 12px; }

  .expandedRowUser {
    padding-left: 88px; }

  .mobileExpandedRowUser {
    padding-left: 60px; }

  .clickableRow {
    cursor: pointer; }

  .deactivatedTag {
    background-color: var(--primary-color);
    border-radius: 100px;
    padding: 4px 10px;
    color: #fff;
    font-size: 12px;
    line-height: 12px; } }
