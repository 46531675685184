body[data-theme='light'] .qs-form,
body[data-theme='dark'] .qs-form {
  font-size: 14px; }
  body[data-theme='light'] .qs-form__select, body[data-theme='light'] .qs-form__textarea, body[data-theme='light'] .qs-form__control,
  body[data-theme='dark'] .qs-form__select,
  body[data-theme='dark'] .qs-form__textarea,
  body[data-theme='dark'] .qs-form__control {
    display: block;
    width: 100%;
    height: 2.42em;
    padding: .43em .85em;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    border: 1px solid #ccc;
    background-color: #fff;
    background-image: none;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    transition: .25s ease; }
    body[data-theme='light'] .qs-form__select:focus, body[data-theme='light'] .qs-form__textarea:focus, body[data-theme='light'] .qs-form__control:focus,
    body[data-theme='dark'] .qs-form__select:focus,
    body[data-theme='dark'] .qs-form__textarea:focus,
    body[data-theme='dark'] .qs-form__control:focus {
      border-color: var(--blue);
      outline: 0;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }
  body[data-theme='light'] .qs-form__select,
  body[data-theme='dark'] .qs-form__select {
    padding: .43em 1.55em .43em .55em;
    cursor: pointer;
    -webkit-appearance: none; }
    body[data-theme='light'] .qs-form__select-wrapper,
    body[data-theme='dark'] .qs-form__select-wrapper {
      position: relative; }
      body[data-theme='light'] .qs-form__select-wrapper:after, body[data-theme='light'] .qs-form__select-wrapper:before,
      body[data-theme='dark'] .qs-form__select-wrapper:after,
      body[data-theme='dark'] .qs-form__select-wrapper:before {
        position: absolute;
        right: 2px;
        top: 5px;
        height: calc(100% - 10px);
        width: 1em;
        background-color: white;
        font-size: 1.7em;
        line-height: .66;
        text-align: left;
        pointer-events: none; }
      body[data-theme='light'] .qs-form__select-wrapper:before,
      body[data-theme='dark'] .qs-form__select-wrapper:before {
        content: ''; }
      body[data-theme='light'] .qs-form__select-wrapper:after,
      body[data-theme='dark'] .qs-form__select-wrapper:after {
        content: '';
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="caret-down" class="svg-inline--fa fa-caret-down fa-w-10" role="img" viewBox="0 0 320 512"><path fill="currentColor" d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
        background-repeat: no-repeat;
        background-size: .35em;
        background-position: 7px center;
        opacity: .8;
        right: .1em; }
  body[data-theme='light'] .qs-form__textarea,
  body[data-theme='dark'] .qs-form__textarea {
    height: auto;
    resize: vertical; }
  body[data-theme='light'] .qs-form__group,
  body[data-theme='dark'] .qs-form__group {
    padding-bottom: 15px; }
    body[data-theme='light'] .qs-form__group p,
    body[data-theme='dark'] .qs-form__group p {
      color: var(--font-black); }
    body[data-theme='light'] .qs-form__group--has-error,
    body[data-theme='dark'] .qs-form__group--has-error {
      color: #af394d; }
      body[data-theme='light'] .qs-form__group--has-error .qs-form__control,
      body[data-theme='dark'] .qs-form__group--has-error .qs-form__control {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(175, 57, 77, 0.6);
        border-color: #af394d; }
    body[data-theme='light'] .qs-form__group--has-success,
    body[data-theme='dark'] .qs-form__group--has-success {
      color: var(--green); }
      body[data-theme='light'] .qs-form__group--has-success .qs-form__control,
      body[data-theme='dark'] .qs-form__group--has-success .qs-form__control {
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px rgba(70, 166, 80, 0.6);
        border-color: var(--green); }
  body[data-theme='light'] .qs-form__label,
  body[data-theme='dark'] .qs-form__label {
    display: inline-block;
    max-width: 100%;
    color: var(--font-black);
    margin-bottom: .357em;
    font-size: 14px;
    font-weight: 700; }
    body[data-theme='light'] .qs-form__label-span,
    body[data-theme='dark'] .qs-form__label-span {
      font-weight: 100;
      font-size: 10px;
      margin-left: 1rem;
      color: #a09503; }
  body[data-theme='light'] .qs-form__input-group,
  body[data-theme='dark'] .qs-form__input-group {
    position: relative;
    display: flex;
    align-items: center;
    border-collapse: separate;
    width: 100%; }
    body[data-theme='light'] .qs-form__input-group--alignment-vertical,
    body[data-theme='dark'] .qs-form__input-group--alignment-vertical {
      flex-direction: column; }
      body[data-theme='light'] .qs-form__input-group--alignment-vertical > *:not(:first-child),
      body[data-theme='dark'] .qs-form__input-group--alignment-vertical > *:not(:first-child) {
        margin-top: -1px; }
      body[data-theme='light'] .qs-form__input-group--alignment-vertical > *:first-child,
      body[data-theme='dark'] .qs-form__input-group--alignment-vertical > *:first-child {
        border-radius: 4px 4px 0 0; }
      body[data-theme='light'] .qs-form__input-group--alignment-vertical > *:last-child,
      body[data-theme='dark'] .qs-form__input-group--alignment-vertical > *:last-child {
        border-radius: 0 0 4px 4px; }
      body[data-theme='light'] .qs-form__input-group--alignment-vertical > * input,
      body[data-theme='dark'] .qs-form__input-group--alignment-vertical > * input {
        border-radius: 0 0 4px 4px; }
    body[data-theme='light'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > *:not(:first-child),
    body[data-theme='dark'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > *:not(:first-child) {
      margin-left: -1px; }
    body[data-theme='light'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > *:first-child,
    body[data-theme='dark'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > *:first-child {
      border-radius: 4px 0 0 4px; }
    body[data-theme='light'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > *:last-child,
    body[data-theme='dark'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > *:last-child {
      border-radius: 0 4px 4px 0; }
    body[data-theme='light'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > * input,
    body[data-theme='dark'] .qs-form__input-group:not(.qs-form__input-group--alignment-vertical) > * input {
      display: table-cell;
      border-radius: 0 4px 4px 0; }
    body[data-theme='light'] .qs-form__input-group-addon,
    body[data-theme='dark'] .qs-form__input-group-addon {
      display: flex;
      align-items: center;
      height: 34px;
      padding: 6px 12px;
      font-size: 14px;
      font-weight: 400;
      line-height: 1;
      color: #555;
      text-align: center;
      background-color: #eee;
      border: 1px solid #ccc;
      border-radius: 4px 0 0 4px;
      margin-right: -1px;
      border-right: 0; }

body[data-theme='dark'] .qs-form__control {
  border-color: var(--dt-qs-gray-20);
  background-color: var(--dt-qs-dark);
  color: #fff; }

body[data-theme='dark'] .qs-form__textarea {
  border-color: var(--dt-qs-gray-20);
  background-color: var(--dt-qs-dark);
  color: #fff; }

body[data-theme='dark'] .qs-form__input-group-addon {
  border-color: var(--dt-qs-gray-20);
  background-color: var(--dt-second-background-color);
  color: var(--dt-qs-gray); }

body[data-theme='dark'] .qs-form__select {
  border-color: var(--dt-qs-gray-20);
  background-color: var(--dt-qs-dark);
  color: var(--dt-qs-gray); }
