body[data-theme='light'],
body[data-theme='dark'] {
  .custom-drag-layer {
    position: fixed;
    pointer-events: none;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%; }

  .custom-drag-element {
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25); } }

