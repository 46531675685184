body[data-theme='light'],
body[data-theme='dark'] {
  .sidebarButton {
    padding: 2px 7px;
    position: absolute;
    right: 0;
    top: 25px;

    svg {
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1); }

    &:hover {
      svg {
        fill: var(--primary-color); } }

    &:active,
    &:focus {
      background-color: transparent; }

    &.collapsed {
      transform: rotate(180deg); } } }
