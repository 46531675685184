body[data-theme='light'],
body[data-theme='dark'] {
  .nameIcon {
    display: flex;
    align-items: center; }

  .bookkeepingItemsTable {
     :global {
      .ant-table {
        overflow-x: auto; } } }

  .cubes {
    margin-right: 8px; }

  .hours {
    opacity: .5;
    margin-left: 10px; }

  .activityName {
    opacity: .5;
    margin-left: 5px; }

  .nameStr {
    margin-left: 5px;
    display: block;
    max-width: 200px;
    width: 200px;

    .separator {
      margin: 0px 5px; } }

  .descriptionWrapper {
    position: absolute;
    width: calc(100% - 32px);
    height: 24px;
    top: 12px; }

  .description {
    width: 100%;
    position: absolute;
    margin: auto;
    bottom: 50%;
    transform: translateY(50%); }

  .limited {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }

  .statusIcon {
    color: var(--light-green); }

  .bookkeepingItemsLayout {
    padding-left: 34px;
    padding-top: 16px;
    padding-right: 24px; }

  .partnerMaskWrapper {
    width: 32px;
    height: 32px;
    margin-right: 8px; }

  .bookkeepingItemsInput {
    width: 300px; }

  .bookkeepingItemsSettings {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    padding-bottom: 14px;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; } }

body[data-theme='light'] {
  .bookkeepingItemsInput {
    background-color: white;

     :global {
      .ant-input {
        background-color: white; } } } }
