body[data-theme='light'],
body[data-theme='dark'] {
  .listBlock {
    flex-basis: 40%; }

  .searchListWrapper {
    position: relative;
    height: 100%; }

  .searchListItem {
    padding: 8px 8px 8px 16px;
    border-bottom: 1px solid #82888F1A;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5; } }

  .activeSearchListItem {
    background-color: #fff0f0; }


  .listSpinner {
    position: absolute;
    width: 100px;
    height: 25px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none; } }

body[data-theme='dark'] {
  .searchListItem {
    &:hover {
      background-color: var(--dt-qs-gray-20); } }

  .activeSearchListItem {
    background-color: #372d3b;

    &:hover {
      background-color: #372d3b; } } }

//
//searchListItem
//activeSearchListItem
//listBlock
//searchListWrapper
