body[data-theme='light'],
body[data-theme='dark'] {
  .monthPopoverList {
    padding-right: 16px; }

  .nothingWrapper {
    padding: 16px 16px; }

  .nothingText {
    font-size: 13px;
    line-height: 15px;
    opacity: .7; }

  .vacationBlock {
    display: flex;
    align-items: center;
    gap: 4px;
    padding-left: 20px;
    background-color: var(--background-gray);
    border-radius: 0 0 5px 5px; }

  .vacationBlockTitle {
    padding: 12px 0 12px 0;

    font-family: Gilroy, Helvetica Neue;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--qs-gray); }

  .vacationIcon {
    width: 25px;
    height: 25px;
    fill: var(--qs-gray); }

  .vacationHours {
    color: rgb(66, 159, 126);
    font-family: Gilroy, Helvetica Neue;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px; }

  .vacationSpin {
    margin-left: 16px; } }
