body[data-theme='light'],
body[data-theme='dark'] {

  .employeeFieldTitle {
    font-size: 13px;
    line-height: 15px;
    color: var(--qs-gray); }

  .fieldError {
    color: red; }

  .required {
      position: relative;

      &:after {
        transition: all 0.3s;
        position: relative;
        content: '*';
        color: var(--primary-color);
        left: 4px; } }

  .splitter {
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: #7279811A; }

  .employeeSelect {
    width: 226px; }

  .employeeInput {
    width: 226px; }

  .employeeModalRow {
    margin-bottom: 20px; }

  .wideInput {
    width: 100%; }

  .errorMessage {
    margin-top: 20px;
    color: var(--primary-color); }

  .entitySelect {
     :global {
      .ant-select-multiple .ant-select-selection-search {
        margin-inline-start: 0; }

      .ant-select-selection-search {
        left: 0 !important; }

      .ant-select-selection-item {
        transition: padding 0.3s ease;
        background: none;
        border: none; }

      .ant-select-selection-item {
        line-height: 32px !important;
        height: 32px !important; }

      .ant-select-selection-item-content,
      .ant-select-selection-item-remove {
        display: flex;
        align-items: center; } } }

  .entitySelect:global(.ant-select-disabled) {
     :global {
      .ant-select-selector .ant-select-selection-overflow .ant-select-selection-overflow-item {
        .ant-select-selection-item {
          padding-left: 0 !important;
          background: none;
          border: none; } } } }

  .entitySelect:global(.ant-select-open) {
     :global {
      .ant-select-selection-item-remove {
        color: #fff; }

      .ant-select-selector .ant-select-selection-item {
        border: 1px solid #FFFFFF80;
        background-color: transparent;
        color: #fff; } } } }

