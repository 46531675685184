body[data-theme='light'],
body[data-theme='dark'] {
  .specify-experience {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    margin-left: 14px;

    border-radius: 5px;
    border: 1px solid #E8EDF1;
    width: 120px;
    height: 78px;
    padding: 0 25px;
    transition: 0.25s ease;
    cursor: default;

    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    text-align: center;

    &.disabled {
      background-color: var(--background-gray);
      cursor: not-allowed;
      color: rgba(0, 0, 0, 0.25);

      .specify-experience_icon {
        fill: rgba(0, 0, 0, 0.25); } }

    .specify-experience_label {
      transition: 0.25s ease;
      display: block;
      margin-top: 7px;
      color: var(--qs-gray); }

    .specify-experience_icon {
      transition: 0.25s ease;
      min-height: 11px;
      fill: var(--qs-gray); }

    &--popover {
      background-color: var(--primary-color);
      border-color: var(--primary-color);
      color: white;
      border-radius: 5px 5px 0 0;

      .specify-experience_icon {
        fill: #fff; }

      .specify-experience_label {
        color: #fff; } } }

  .specify-experience-list {
    padding-top: 0;

     :global {
      .ant-popover-inner-content {
        padding: 8px 0 8px 18px; }
      .ant-popover-arrow {
        display: none; }

      .ant-popover-inner {
        border-radius: 5px 0 5px 5px; } } }

  .specify-experience-list-wrapper {
    display: flex;
    flex-direction: column;

    .specify-experience-list_item {
      text-align: left;
      padding: 9px 0;
      cursor: pointer;
      transition: 0.25s ease;
      font-size: 13px;
      color: var(--font-black);
      height: auto;
      min-width: 208px;
      font-family: Roboto;
      font-weight: 400;
      white-space: nowrap;
      border-bottom: 1px solid rgba(#82888F, 0.1);

      &:last-of-type {
        border: none; }

      &:hover {
        color: var(--primary-color); }

      &:disabled {
        opacity: .5;
        cursor: not-allowed;

        &:hover {
          color: var(--font-black); } } } } }


body[data-theme='light'] {
  .specify-experience-list-wrapper {
    .specify-experience-list_item {
      &:hover {
        background-color: #fff; } } } }

body[data-theme='dark'] {
  .specify-experience {
    background-color: var(--dt-qs-gray-20);
    border: 1px solid var(--dt-qs-gray-20); }

  .specify-experience-list-wrapper {
    .specify-experience-list_item {
      &:hover {
        background-color: transparent; } } }

  .specify-experience .specify-experience_label {
    color: var(--dt-qs-gray); }

  .specify-experience--popover .specify-experience_label {
    color: #fff; } }
