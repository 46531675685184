body[data-theme='light'],
body[data-theme='dark'] {
  .requests-tab-partners {
    height: calc(100vh - 137px);
    max-height: calc(100vh - 137px);
    background-color: white;
    overflow: hidden !important;

    &:hover {
      overflow: auto !important; }

    &_preloader {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 69px;
      z-index: 2;
      background-color: #d0d8df23;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none; }

    &_title-wrapper {
      height: 69px;
      position: relative;
      overflow: hidden;
      padding: 0 10px;
      border-bottom: 1px solid #F1F3F5; }

    &_title-visible-box {
      width: 480px;
      height: 100%;
      display: flex;
      position: absolute;
      transition: left .25s ease; }

    &_item {
      padding: 22px 20px;
      cursor: pointer;
      transition: .25s ease;
      border-bottom: none !important;
      position: relative;
      color: #727981;

      &--active {
        background-color: #F1F3F5; }

      &:after {
        content: '';
        position: absolute;
        left: 16px;
        width: calc(100% - 16px);
        bottom: 0px; }

      &:hover {
        color: black; } }

    &_searcher {
      height: 100%;

      &.icon {
        position: relative;
        margin-right: 4px;
        fill: var(--qs-gray); } } } }

body[data-theme='light'] {
  .requests-tab-partners {
    &_item {
      &:after {
        border-bottom: 1px solid #F1F3F5; }

      &:hover {
        background-color: #F1F3F5; } } } }

body[data-theme='dark'] {
  .requests-tab-partners {
    background-color: var(--dt-qs-gray-20);

    &_title-wrapper {
      border-color: var(--border-gray-30); }

    &_item {
      &:after {
        border-bottom: 1px solid var(--dt-qs-gray-20); }

      &--active {
        background-color: var(--second-backround-black); }

      &:hover {
        color: var(--primary-color); } } } }
