body[data-theme='light'],
body[data-theme='dark'] {
  .tableRadio {
    position: relative;
    padding: 0 0 0 20px;

     :global {
      .ant-radio {
        display: none; }

      .ant-radio-wrapper-checked {
        color: var(--primary-color);
        border-bottom: 2px solid var(--primary-color); } } }

  .radioItem {
    display: inline-block;
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    margin-right: 12px;
    margin-bottom: 0;
    padding-bottom: 20px;
    cursor: pointer;
    border-bottom: 2px solid transparent;

    &:last-of-type {
      margin-right: 0; }

    &:hover {
      transition: 0.25s ease;
      color: var(--primary-color);
      fill: var(--primary-color); } }

  .solidRadioGroup {
    white-space: nowrap;
    background-color: #E5EAEE;
    border-radius: 10px !important;

     :global {
      .ant-radio-button-wrapper-checked {
        border: none;
        background-color: #fff;

         :local {
          .solidRadioIcon {
            fill: var(--primary-color); } } } } }

  .solidRadioItem {
    background-color: transparent;
    line-height: 34px;

    border: none !important;
    border-radius: 10px !important;

    &:hover {
      .solidRadioIcon {
        transition: 0.3s ease;
        fill: var(--primary-color);
        color: var(--primary-color); } }

    &::before {
      display: none !important; }

     :global {
      .ant-radio-button {
        display: inline-flex;
        align-items: center; } } }

  .solidRadioIcon {
    position: relative;
    top: 4px; } }

body[data-theme='light'] {
  .radioItem {
    color: rgba(#000, 0.3); } }

body[data-theme='dark'] {
  .radioItem {
    color: var(--dt-qs-gray); }

  .solidRadioGroup {
    background-color: var(--dt-qs-dark);

    .solidRadioItem {
      color: var(--dt-qs-gray);
      box-shadow: none !important;

      svg {
        fill: var(--dt-qs-gray);
        color: var(--dt-qs-gray); }

      &:hover {
        fill: var(--primary-color);
        color: var(--primary-color); } } }

   :global {
    .ant-radio-button-wrapper-checked {
      background-color: var(--dt-qs-gray-20) !important;
      color: var(--primary-color) !important;

      svg {
        fill: var(--primary-color) !important;
        color: var(--primary-color) !important; } } } }
