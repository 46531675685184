@import '../../helpers/styles/mixins.sass';
body[data-theme='light'],
body[data-theme='dark'] {

  .reportsPage {
    padding-left: 34px;
    padding-top: 16px;
    padding-right: 24px;

    @include mq('tablet-wide') {
      padding-left: 8px;
      padding-top: 4px;
      padding-right: 8px; } } }
