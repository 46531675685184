@import 'src/helpers/styles/mixins.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .curatorReportSettingsLayout {
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; }

  .curatorReportFilter {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-wrap: wrap;

    @include mq('tablet-wide') {
      justify-content: center; } }

  .partnerSelect {
    width: 200px;

    @include mq('tablet-wide') {
      width: 100%; } }

  .employeeReportSearch {
    width: 220px;

    @include mq('tablet-wide') {
      width: 100%; } }

  .datepickerDividerLoading {
    opacity: 0.2; }

  .reportMonthPicker {
    margin: 0; } }

body[data-theme='light'], {
  .partnerSelect {
    background-color: #E5EAEE; }

  .employeeReportSearch {
    background-color: #fff;

     :global {
      .ant-input {
        background-color: #fff; } } } }
