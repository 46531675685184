body[data-theme='light'],
body[data-theme='dark'] {
  .activityListTitle {
    color: inherit;
    padding: 28px 24px 8px;
    margin: 0;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px; }

  .activitiesList {
    flex: 2 2 50%;
    width: 100%;
    height: 50%;
    position: relative; }

  .activitiesListInner {
    overflow-y: auto;
    height: calc(100% - 53px); }

  .loadingWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .25s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; } }
