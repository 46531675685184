body[data-theme='light'],
body[data-theme='dark'] {
  .partnersListTitle {
    padding: 28px 24px 8px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    color: rgba(255, 255, 255, .75); }

  .partnersList {
    flex: 2 2 50%;
    width: 100%;
    height: 50%;
    position: relative; }

  .partnersListInner {
    overflow-y: auto;
    height: calc(100% - 53px); }

  .loadingWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: .25s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; } }
