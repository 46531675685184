@import '../../../../../helpers/tableVariables.sass';
body[data-theme='light'],
body[data-theme='dark'] {
  .skillTagModal {
     :global {
      .ant-drawer-body {
        padding: 0;
        position: relative; } } }

  .cell {
    flex: 0px 1 1;
    position: relative;
    user-select: none;

    &.withBottomBorder {
      box-shadow: 0px 1px 0px 0px $grayBorderColor; }

    &.cutDay.withBottomBorder {
      box-shadow: 0px 1px 0px 0px #DFE7EF80; } }

  .cellInner {
    position: relative;
    width: 100%;
    height: 100%; } }

body[data-theme='dark'] {
  .cell {
    &.withBottomBorder {
      box-shadow: 0px 1px 0px 0px var(--dt-ts-br-gray); }

    &.cutDay.withBottomBorder {
      box-shadow: 0px 1px 0px 0px var(--dt-ts-br-gray); } }

  .checkbox {
    position: absolute;
    bottom: 22px; }

  .cellInner {
    position: relative;
    width: 100%;
    height: 100%; } }
