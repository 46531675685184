body[data-theme='light'] .date-picker__wrapper,
body[data-theme='dark'] .date-picker__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%; }

body[data-theme='light'] .date-picker__input,
body[data-theme='dark'] .date-picker__input {
  display: block;
  select: no-selection;
  cursor: default;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s; }

body[data-theme='dark'] .date-picker__input {
  border-color: var(--dt-qs-gray-20);
  background-color: var(--dt-qs-dark);
  color: var(--dt-qs-gray); }
