body[data-theme='light'],
body[data-theme='dark'] {
  .activityListTitle {
    color: rgba(114, 121, 129, 0.75);
    padding: 12px 24px;
    margin: 0;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase; }

  .activitiesList {}

  .activitiesCustomScrollbar {
    height: calc(100% - 36px) !important; } } // 36px -- it's title's height
