body[data-theme='light'],
body[data-theme='dark'] {
  .participantsSettings {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    position: relative;
    box-shadow: 0px -2px 0px var(--grey-background-break-gray) inset; }

  .addNewPopover {
     :global {
      .ant-popover-arrow {
        display: none; }

      .ant-divider {
        margin: 0; }

      .ant-popover-inner {
        border-radius: 5px 0 5px 5px; } }

    .add-new-input {
      width: 340px;
      background-color: var(--background-gray);
      border: none;

       :global {
        .ant-input {
          background-color: var(--background-gray);

          &::placeholder {
            font-size: 15px; } } } } }

  .popoverWrapper {
    width: 350px;
    display: flex;
    align-items: center;
    gap: 10px; }

  .curatorCheckbox {
    white-space: nowrap; }

  .buttonWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end; }

  .buttonAnnotation {
    font-size: 12px;
    padding: 0;
    margin: 0;

    b {
      margin: 0 4px; } }

  .flexToEnd {
    display: flex;
    justify-content: flex-end; }

  .selectionPanel {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 0 15px; }

  .select {
    width: 150px; }

  .filtersWrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-bottom: 14px;
    gap: 8px; }

  .participantsRadio {
    z-index: 2;
    padding-left: 0; }

  .participantsInput {
    width: 220px; }

  .participantsSelect {
    width: 150px; } }

body[data-theme='light'] {
  .participantsInput {
    background-color: #fff;

     :global {
      .ant-input {
        background-color: #fff; } } }

  .participantsSelect {
    background-color: #E5EAEE; }

  .select {
    background-color: #E5EAEE; } }
