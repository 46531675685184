body[data-theme='light'],
body[data-theme='dark'] {
   :global {
    .ant-form-item-has-error {
       :local {
        .qsInput {
          background-color: var(--background-gray); } } } }

  .withError {
    box-shadow: inset 0px 0px 0px 1px red; }

  .qsInput {
    border-radius: 5px;
    border: 1px solid var(--background-gray);
    background-color: var(--background-gray);
    font-size: 14px;
    height: 40px;

    &::placeholder {
      font-size: 14px; }

     :global {
      .ant-input-affix-wrapper-lg {
        padding: 6.4px 8px !important; }

      .ant-input {
        font-size: 14px;
        line-height: 21px;
        background-color: var(--background-gray); } } }

  .qsInput.error {
    border: 1px solid red;

    &:focus-within {
      outline: 0; } }

  .trackerTextArea {
    background-color: var(--background-gray);
    border: 1px solid var(--background-gray); } }

body[data-theme='dark'] {
  .trackerTextArea {
    background-color: var(--dt-qs-dark);
    border: 1px solid transparent; }

  .qsInput {
    background-color: var(--dt-qs-dark);
    border: 1px solid transparent;

     :global {
      .ant-input {
        background-color: transparent; } } } }




