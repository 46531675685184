body[data-theme='light'],
body[data-theme='dark'] {
  .label {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 38px;
    line-height: 44px;
    margin-right: 15px;

    display: flex;
    align-items: center;

    color: #232E39; }

  .monthSelector {
    display: flex;
    flex-direction: row;
    align-items: center;
    background: white;
    position: relative;
    z-index: 1; }

  .popoverInner,
  .list {
    display: flex;
    flex-direction: column; }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 49px;
    border-bottom: 1px solid #D9E3EC; }

  .popoverYear {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 21px;

    color: var(--font-black); }

  .changeYearButton,
  .changeMonthButton {
    border: none;
    background: none;
    box-shadow: none;
    outline: none;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      background-color: #eee;
      color: var(--font-black); }

    &:before,
    &:after {
      content: none; } }

  .changeYearButton {
    border-radius: 10px; }

  .changeMonthButton {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    height: 42px;
    transition: .25s ease;

    color: var(--font-black);

    &.active {
      color: #F4536E; } }

  .popover {
    left: 0 !important;
    top: 0 !important;
    padding: 15px;
    width: 100%;

     :global {
      .ant-popover-content {
        .ant-popover-arrow {
          display: none; }

        .ant-popover-inner {
          border-radius: 20px;

          .ant-popover-inner-content {
            padding: 5px 18px 18px; } } } } } }

body[data-theme='dark'] {
  .label {
    color: white; }

  .monthSelector {
    background-color: var(--primary-backround-black); }

  .changeMonthButton:hover {
    background-color: var(--primary-backround-black); } }
