body[data-theme='light'] .qs-layout,
body[data-theme='dark'] .qs-layout {
  box-sizing: border-box; }
  body[data-theme='light'] .qs-layout__container,
  body[data-theme='dark'] .qs-layout__container {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px; }
    body[data-theme='light'] .qs-layout__container--fluid,
    body[data-theme='dark'] .qs-layout__container--fluid {
      width: 100%; }
    body[data-theme='light'] .qs-layout__container--without-padding,
    body[data-theme='dark'] .qs-layout__container--without-padding {
      padding: 0; }
  body[data-theme='light'] .qs-layout__row,
  body[data-theme='dark'] .qs-layout__row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -15px;
    margin-right: -15px; }
    body[data-theme='light'] .qs-layout__row--justify-center,
    body[data-theme='dark'] .qs-layout__row--justify-center {
      justify-content: center; }
    body[data-theme='light'] .qs-layout__row--justify-flex-start,
    body[data-theme='dark'] .qs-layout__row--justify-flex-start {
      justify-content: flex-start; }
    body[data-theme='light'] .qs-layout__row--justify-flex-end,
    body[data-theme='dark'] .qs-layout__row--justify-flex-end {
      justify-content: flex-end; }
    body[data-theme='light'] .qs-layout__row--justify-space-between,
    body[data-theme='dark'] .qs-layout__row--justify-space-between {
      justify-content: space-between; }
    body[data-theme='light'] .qs-layout__row--justify-space-around,
    body[data-theme='dark'] .qs-layout__row--justify-space-around {
      justify-content: space-around; }
    body[data-theme='light'] .qs-layout__row--align-center,
    body[data-theme='dark'] .qs-layout__row--align-center {
      align-items: center; }
    body[data-theme='light'] .qs-layout__row--align-flex-start,
    body[data-theme='dark'] .qs-layout__row--align-flex-start {
      align-items: flex-start; }
    body[data-theme='light'] .qs-layout__row--align-flex-end,
    body[data-theme='dark'] .qs-layout__row--align-flex-end {
      align-items: flex-end; }
    body[data-theme='light'] .qs-layout__row--align-baseline,
    body[data-theme='dark'] .qs-layout__row--align-baseline {
      align-items: baseline; }
  body[data-theme='light'] .qs-layout__centered,
  body[data-theme='dark'] .qs-layout__centered {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%; }
    body[data-theme='light'] .qs-layout__centered--vertical,
    body[data-theme='dark'] .qs-layout__centered--vertical {
      align-items: center; }
    body[data-theme='light'] .qs-layout__centered--horizontal,
    body[data-theme='dark'] .qs-layout__centered--horizontal {
      justify-content: center; }
  body[data-theme='light'] .qs-layout__column,
  body[data-theme='dark'] .qs-layout__column {
    position: relative;
    width: 100%;
    flex-grow: 1;
    flex-basis: 0;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    max-width: 100%; }
    body[data-theme='light'] .qs-layout__column--grow,
    body[data-theme='dark'] .qs-layout__column--grow {
      flex-grow: 5; }
    body[data-theme='light'] .qs-layout__column--size-1,
    body[data-theme='dark'] .qs-layout__column--size-1 {
      max-width: calc(100% / 12 * 1);
      flex: 0 0 calc(100% / 12 * 1); }
    body[data-theme='light'] .qs-layout__column--size-2,
    body[data-theme='dark'] .qs-layout__column--size-2 {
      max-width: calc(100% / 12 * 2);
      flex: 0 0 calc(100% / 12 * 2); }
    body[data-theme='light'] .qs-layout__column--size-3,
    body[data-theme='dark'] .qs-layout__column--size-3 {
      max-width: calc(100% / 12 * 3);
      flex: 0 0 calc(100% / 12 * 3); }
    body[data-theme='light'] .qs-layout__column--size-4,
    body[data-theme='dark'] .qs-layout__column--size-4 {
      max-width: calc(100% / 12 * 4);
      flex: 0 0 calc(100% / 12 * 4); }
    body[data-theme='light'] .qs-layout__column--size-5,
    body[data-theme='dark'] .qs-layout__column--size-5 {
      max-width: calc(100% / 12 * 5);
      flex: 0 0 calc(100% / 12 * 5); }
    body[data-theme='light'] .qs-layout__column--size-6,
    body[data-theme='dark'] .qs-layout__column--size-6 {
      max-width: calc(100% / 12 * 6);
      flex: 0 0 calc(100% / 12 * 6); }
    body[data-theme='light'] .qs-layout__column--size-7,
    body[data-theme='dark'] .qs-layout__column--size-7 {
      max-width: calc(100% / 12 * 7);
      flex: 0 0 calc(100% / 12 * 7); }
    body[data-theme='light'] .qs-layout__column--size-8,
    body[data-theme='dark'] .qs-layout__column--size-8 {
      max-width: calc(100% / 12 * 8);
      flex: 0 0 calc(100% / 12 * 8); }
    body[data-theme='light'] .qs-layout__column--size-9,
    body[data-theme='dark'] .qs-layout__column--size-9 {
      max-width: calc(100% / 12 * 9);
      flex: 0 0 calc(100% / 12 * 9); }
    body[data-theme='light'] .qs-layout__column--size-10,
    body[data-theme='dark'] .qs-layout__column--size-10 {
      max-width: calc(100% / 12 * 10);
      flex: 0 0 calc(100% / 12 * 10); }
    body[data-theme='light'] .qs-layout__column--size-11,
    body[data-theme='dark'] .qs-layout__column--size-11 {
      max-width: calc(100% / 12 * 11);
      flex: 0 0 calc(100% / 12 * 11); }
    body[data-theme='light'] .qs-layout__column--size-12,
    body[data-theme='dark'] .qs-layout__column--size-12 {
      max-width: calc(100% / 12 * 12);
      flex: 0 0 calc(100% / 12 * 12); }

body[data-theme='dark'] .qs-layout__column p,
body[data-theme='dark'] .qs-layout__column strong,
body[data-theme='dark'] .qs-layout__column small {
  color: var(--font-black); }

body[data-theme='dark'] .qs-layout__column small {
  color: var(--dt-qs-gray); }
