body[data-theme='light'],
body[data-theme='dark'] {
  .addPartnerInput {
    width: 100%; }

  .partnerNamePartnerMask {
    margin-right: 6px; }

  .requestedBySelect {
    width: 100%;
    padding-top: 4px;
    border-radius: 5px;
    background: var(--background-gray); }

  .fieldName {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    height: 18px;
    color: var(--qs-gray); }

  .colorErrorField {
    display: flex;
    justify-content: flex-end; }

  .newPartnerInputsWrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 18px; }

  .activitiesSection {
    margin-top: 20px; }

  .activitiesList {
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin-top: 5px; }

  .activityButtons {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 12px;
    transform: translateX(100%);
    opacity: 0;
    height: 100%; }

  .activityButtonsWrapper {
    position: absolute;
    right: 0;
    height: 100%;
    overflow: hidden; }

  .activityButton {
    border-color: var(--primary-color);
    background: var(--primary-color);
    color: white;
    font-size: 10px;
    border-radius: 5px; }

  .newActivityMask {
    cursor: pointer; }

  .newActivityLabel {
    background-color: var(--primary-color);
    color: white;
    position: absolute;
    right: 0;
    top: 0;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 0px 5px 0px 0px;

    &:before {
      content: '';
      width: 5px;
      height: 100%;
      position: absolute;
      top: 0;
      left: -10px;
      height: 22px;
      border-left: 10px solid transparent;
      border-top: 22px solid var(--primary-color); } }

  .activity {
    height: 48px;
    border-radius: 5px;
    background-color: white;
    position: relative;
    padding: 12px 12px 12px 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--background-gray);

    &:hover {
      .activityButtons {
        transform: translateX(0);
        opacity: 1; } } }

  .activityBorder {
    position: absolute;
    width: 5px;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 5px 0 0 5px; }

  .activityName {
    font-size: 14px;
    flex-grow: 2;
    color: black; }

  .addNewActivityInput {
    flex-grow: 2;
    border: none !important;
    background: none;
    box-shadow: none !important;
    outline: none;
    padding-left: 0;

    &::after,
    &::before {
      content: none; } }

  .emptyActivitiesListWar {
    opacity: 1; }

  .generalColor {
    margin: 0 6px;
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: baseline;
    border-radius: 5px;
    background-color: #ff0000;
    color: white;
    position: relative;
    bottom: -2px; }

  .red {
    color: red; }

  .partnerCardButton {
    color: var(--primary-color);
    font-weight: 500;
    font-size: 13px;
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-radius: 5px;

    svg {
      color: var(--primary-color); }

    &:hover {
      background-color: #eee; } }

  .appearanceTitleBlock {
    display: flex;
    justify-content: space-between;
    align-items: center; }

  .appearanceBlockWrapper {
    margin-top: 18px;
    width: 100%; } }

body[data-theme='dark'] {
  .fieldName {
    color: var(--dt-qs-gray); }

  .activity {
    background-color: var(--dt-qs-dark); }

  .activityName {
    color: white; }

  .partnerCardButton:hover {
    background-color: rgba(255, 255, 255, .1);
    color: var(--primary-color);

    svg {
      fill: var(--primary-color); } } }
