body[data-theme='light'],
body[data-theme='dark'] {

  .employeeSelectionPanel {
    height: 54px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  .employeeSelectionPanelCount {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: var(--font-black); }

  .splitter {
    width: 100%;
    height: 1px;
    background-color: var(--dt-qs-gray-20); }

  .statusModalUser {
    height: 46px; }

  .statusModalList {
    width: 100%;
    max-height: 235px;
    overflow-y: auto; } }
