body[data-theme='light'],
body[data-theme='dark'] {
  .partnerMasksLayout {
    display: flex;
    align-items: center;
    gap: 6px; }

  .partnerMaskWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-color: #ffffff1A;

    &:hover {
      cursor: pointer;
      background-color: rgba(244, 83, 110, 0.3);
      border: 1px solid #F4536E; }

    &.activePartnerMask {
      background-color: rgba(244, 83, 110, 0.3);
      border: 1px solid #F4536E; } }

  .partnerMask {
    fill: #fff;
    height: 18px;
    width: 18px; }

  .currentMask {
    height: 24px;
    width: 24px; }

  .partnerSelection {
    display: flex;
    align-items: center;
    gap: 6px;
    justify-content: center;

    width: 70px;
    height: 40px;
    background-color: #E5EAEE;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;

    &:hover {
      border: 1px solid var(--primary-color); } }

  .customizationPopoverRounded {
     :global {
      .ant-popover-inner {
        border-radius: 20px; }

      .ant-popover-inner-content {
        border-radius: 20px; } } }

  .customizationPopover {
    width: auto;
    z-index: 1001;

     :global {
      .ant-popover-inner-content {
        padding: 8px;
        background-color: #222222;
        border-radius: 5px; }

      .ant-popover-arrow-content {
        background-color: #222222; } } } }

body[data-theme='dark'] {
  .partnerSelection {
    background-color: var(--dt-qs-dark); } }
