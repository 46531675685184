body[data-theme='light'],
body[data-theme='dark'] {
  .wrapper {
    width: 100%;
    padding: 28px 28px 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: auto; }

  .item {
    display: flex;
    justify-content: space-between; }

  .red {
    color: var(--primary-color); }

  .timeSelector {
    width: 100%;
    background-color: rgba(255, 255, 255, .2);
    border-radius: 5px;
    color: white;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

     :global {
      .ant-select-selector {
        background-color: transparent !important;
        border-color: transparent !important;
        box-shadow: none !important;
        outline: none !important; }

      .ant-select-arrow {
        color: rgba(255, 255, 255, .65); } } }

  .comment {
    background-color: rgba(255, 255, 255, .1);
    border-radius: 5px;
    border-color: transparent;
    font-size: 16px;
    resize: none;
    color: white;
    box-shadow: none;
    outline: none;

    &:after,
    &:before {
      content: ''; }

    &:focus,
    &:active {
      border-color: rgba(255, 255, 255, .4);
      box-shadow: none; } }

  .activity {
    color: white; }

  .formWrapper {
    flex-grow: 2;
    position: relative; }

  .preloaderWrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1; }

  .saveButton {
    width: calc(100% + 56px);
    font-size: 15px;
    line-height: 18px;
    color: white;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: none;
    height: 45px;
    border-radius: 0px;
    margin: 20px -28px 0;

    &:hover,
    &:active,
    &:focus {
      background-color: #d1314c;
      border-color: #d1314c;
      color: white; }

    &:disabled {
      &:hover,
      &:active,
      &:focus,
      & {
        background-color: #d1314c;
        border-color: #d1314c;
        color: white;
        opacity: .3;
        cursor: not-allowed; } } }

  .activity,
  .descriptionTextWrapper {
    display: flex;
    align-items: center; }

  .descriptionTextWrapper {
    margin: 0;
    flex-grow: 2;
    justify-content: space-between;
    color: inherit;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px; }

  .activityIconWrapper {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    margin-right: 6px;
    border-radius: 5px; }

  .selectors {
    margin: 20px 0; }

  .selector {
    margin: 0px -28px;
    width: calc(100% + 56px);
    position: relative;
    padding-bottom: 10px;
    padding-top: 5px; }

  .selectedHours {
    width: 100px;
    height: 47px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .25s ease;

    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;

    text-align: center;

    color: #9BA3AC;

    &.disabled {
      opacity: .4; } }

  .activeItem {
    .selectedHours {
      font-size: 28px;
      color: #FFFFFF; } }


  .marker {
    position: absolute;
    left: 50%;
    bottom: -8px;
    transform: translateX(-50%);
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    color: rgba(255, 255, 255, .5);

    &:after {
      content: '';
      background-color: rgba(255, 255, 255, .1);
      position: absolute;
      left: 50%;
      top: -51px;
      transform: translateX(-50%);
      width: 100px;
      height: 47px;
      border-radius: 5px;
      pointer-events: none; } }

  .confirmContentWrapper {
    ul {
      padding: 0; }

    li {
      list-style: none;
      margin-left: 5px;
      margin-top: 5px;
      display: flex;
      align-items: center;

      .dot {
        color: var(--primary-color);
        margin-right: 5px; } } } }
