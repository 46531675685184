body[data-theme='light'],
body[data-theme='dark'] {
  /*
    Base class for Antd Modal.confirm
   */
  .qsConfirmAntdModal {
    font-family: Roboto;
    font-style: normal;
    font-size: 15px;
    line-height: 18px;

     :global {
      .ant-modal-body {
        padding: 0; }

      .ant-modal-content {
        border-radius: 5px; }

      .ant-modal-confirm-content {
        margin: 0;
        min-height: 138px;
        padding: 38px 22px 38px 22px;
        border-bottom: 1px solid rgba(#727981, 0.1);
        font-weight: normal;
        font-size: 15px;
        color: var(--font-black); }

      .ant-modal-confirm-btns {
        padding: 10px 15px;
        margin: 0; }

      .ant-modal-confirm-title {
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: var(--font-black);
        padding: 25px 0 25px 20px;
        border-bottom: 1px solid rgba(#727981, 0.1); } } }

  /*
    Base class for Antd Modal
   */

  .qsBasicAntdModal, {
     :global {
      .ant-modal-content {
        border-radius: 5px; }

      .ant-modal-title {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: var(--font-black); }

      .ant-modal-body {
        padding: 30px 18px; }

      .ant-modal-header {
        padding: 25px 20px;
        border-radius: 5px 5px 0 0; } } }

  .modalOkBtn {
    width: 170px;
    height: 45px;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    border-radius: 5px;
    color: #FFFFFF;

    &:disabled {
      border: none; } }

  .modalCancelBtn {
    padding-left: 21px;
    padding-right: 21px;
    margin-right: 15px;
    line-height: 15px;

    font-weight: 500;
    font-size: 15px;
    height: 45px;
    text-align: center;
    color: #82888F; }

  .mobileQsBasicAntdModal {
    position: absolute;
    left: 8px;
    bottom: 0;
    top: auto;

     :global {
      .ant-modal-content {
        border-radius: 20px; }

      .ant-modal-confirm-btns {
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 10px 15px;

        & > * {
          flex-grow: 2; } } } } }
