body[data-theme='light'],
body[data-theme='dark'] {
  .defaultPanel {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 2;
    color: #F4536E;
    width: 100%; }

  .mainTextWrapper {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    margin: 16px 0px 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

  .alreadyWorkedHours {
    line-height: 33px; }

  .dateMarker {
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: block;
    height: 12px;
    letter-spacing: 0.2em;
    color: var(--font-black);
    transition: .25s ease;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-10px);

    &.today {
      transform: translateY(0);
      opacity: .5; } }

  .buttonsSection {
    flex-grow: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0 40px; }

  .addTimeButton,
  .secondButton {
    border-radius: 50%;
    box-shadow: none;
    transition: .25s ease;
    display: flex;
    justify-content: center;
    align-items: center;

    &:focus,
    &:active {
      border-color: transparent; }

    &:hover,
    &:visited {
      background-color: #3f3f3f;
      border-color: #3f3f3f;
      color: white; }

    &:after,
    &:before {
      content: none; } }

  .addTimeButton {
    width: 72px;
    height: 72px;
    color: white;
    border-color: black;
    background-color: black;
    transition: .25s ease;

    &.locked {
      transform: scale(0); }

    svg {
      width: 32px;
      height: 32px;
      stroke: white; }

    &:disabled {
      svg {
        stroke: #9BA3AC; } } }

  .listButton {
    position: absolute;
    left: 40px; }

  .iconsWrapper {
    position: relative;
    width: 16px;
    height: 18px;

    &.locked {
      .unlockIcon {
        opacity: 1; }

      .lockIcon {
        opacity: 0; } } }

  .lockIcon,
  .unlockIcon {
    transition: .25s ease;
    position: absolute;
    left: 0;
    top: 0;
    // fill: none
 }    // stroke: #9BA3AC

  .unlockIcon {
    opacity: 0; }

  .lockButtonWrapper {
    position: absolute;
    right: 40px;
    transform: translateX(0%);
    transition: .25s ease;
    width: 50px;
    height: 50px;

    &.locked {
      right: 50%;
      transform: translateX(50%);
      width: 72px;
      height: 72px;

      .lockButton {
        width: 72px;
        height: 72px;
        border-color: rgba(0, 0, 0, .15);
        animation: bounce-animation .4s ease; } } }

  .secondButton {
    width: 50px;
    height: 50px;
    border-color: transparent;
    background-color: white;
    color: #9BA3AC;

    svg {
      width: 16px;
      height: 18px; } }

  @keyframes bounce-animation {
    0% {
      transform: rotate(0deg); }

    100% {
      transform: rotate(360deg); } } }

body[data-theme='dark'] {
  .addTimeButton {
    background-color: var(--dt-gray-button);
    border: 1px solid transparent; }

  .secondButton {
    background-color: transparent;

    &:focus,
    &:active,
    &:hover {
      background-color: transparent;
      border: 1px solid transparent; } }

  .secondButton.locked {
    .lockIcon {
      bottom: 2px;
      color: white;
      border-color: black;
      background-color: black; } } }
