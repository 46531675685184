body[data-theme='light'],
body[data-theme='dark'] {
  .MDEditor {
    box-shadow: none;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, .15);
    margin-bottom: 25px;
    border-radius: 5px;

    textarea {
      resize: none;
      overflow-y: hidden; }

    & *::selection {
      background: #32313138; }

    ul {
      margin-bottom: 0; }

    &::selection {
      background: #11090b6e; }

     :global {
      .w-md-editor-preview {
        a {
          color: var(--primary-color) !important; } }

      .w-md-editor-text-pre {
        .code-highlight {
          span.token {
            color: var(--primary-color) !important; } } }

      .w-md-editor-preview {
        padding: 8px 10px; }

      .w-md-editor-toolbar {
        border-bottom: 1px solid rgba(155,163,172,.2);
        border-radius: 5px 5px 0 0;

        ul {
          li {
            button {
              width: 24px;
              height: 24px;
              padding: 0;
              display: flex;
              justify-content: center;
              align-items: center;
              color: var(--color-fg-subtle);

              &:hover {
                color: var(--primary-color); }

              &:disabled {
                border-color: #eee;
                opacity: .15; } }

            & + li {
              margin-left: 5px; } } } }

      .wmde-markdown {
        font-size: 14px;
        font-family: Roboto;
        background: none; }

      .w-md-editor-bar {
        display: none; } } } }

body[data-theme='dark'] {
  .MDEditor {
    background-color: var(--background-gray);
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, .17);
    color: white;

    & *::selection {
      background: #ffffff38; }

     :global {
      .w-md-editor-preview {
        pre {
          background: none;

          .code-highlight {
            color: white;
            background-color: var(--dt-qs-dark); } } } }

     :global {
      .wmde-markdown {
        color: white; }

      .w-md-editor-preview {
        .code-highlight {
          color: black;
          background-color: var(--button-hover-gray); } }

      .w-md-editor-toolbar {
        background-color: var(--background-gray);

        ul {
          li {
            button {
              color: white; } } } } } } }
