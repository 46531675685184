@import '../../../../../../../helpers/styles.module.sass';

body[data-theme='light'],
body[data-theme='dark'] {
  .candidates {
    padding-left: 22px;
    width: 100%;

    &_table {
      @extend .specialists_table;
      width: 100%; }

    .star-select {
      color: #F4536E;
      cursor: pointer;
      transition: hover 0.25s ease;

      &--loading {
        color: #727981; }

      &:hover {
        color: #727981; } }

     :global {
      .ant-table-row {
        cursor: pointer; } }

    .name-cell {
      display: flex;
      align-items: center; }

    .author-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 8px;

      &--qs {
        width: 28px;
        height: 28px;
        border-radius: 0; } }

    &_total {
      @extend .specialists_table_total;

      .empty-candidates-title {
        margin-top: 12px;
        color: var(--qs-gray);
        font-family: Roboto;
        font-size: 13px;
        line-height: 15px; } } } }



