@import '../../../helpers/styles.module.sass';

 :global {
  body[data-theme='light'],
  body[data-theme='dark'] {
    .gearButton {
      position: absolute;
      right: 10px;
      bottom: 14px;
      opacity: 0;
      transition: .25s ease;
      background-color: transparent;
      width: 30px;
      height: 30px;
      padding: 0;
      display: flex;
      justify-content: center;

      svg {
        margin: 0; }

      &:active,
      &:focus {
        outline: none;
        border-color: transparent;
        box-shadow: none; } } } }

body[data-theme='light'],
body[data-theme='dark'] {

  .monthInner.drawerMode {
    .monthInnerPadding {
      padding-left: 70px;
      padding-right: 70px; } }

  .monthInnerPadding {
    padding-left: 35px;
    padding-right: 35px; }

  .monthHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding-bottom: 18px;
    padding-top: 20px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px; }

  .monthTitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: var(--font-black); }

  .monthTitleModified {
    margin-left: 5px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: var(--font-black); }

  .monthTable {
    border-collapse: collapse;
    //border-top: 1px solid #F1F1F2
    border-spacing: 1px;

    td {
      padding: 0;
      border: 1px solid #F1F1F2;
      border-top-width: 0px;
      width: 40px;
      height: 40px; } }

  .monthDaysInner {
    background-image: linear-gradient(330deg, #F2F4F6 44.12%, #fff 44.12%, #fff 50%, #F2F4F6 50%, #F2F4F6 94.12%, #fff 94.12%, #fff 100%);
    background-size: 19px 11px;
    border-top: 1px solid #D1DBE34d;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      height: 2px;
      opacity: .75;
      transition: .25s ease;
      background-color: #d1dbe3; }

    &.addDaysStatusActive:before {
      width: 100%; } }

  .monthDays {
    white-space: normal;
    position: relative;

    &.notEditing {
      opacity: .5; }

    &.prevMonth {
      margin-top: 14px; }

    &.currentMonth {
      margin-top: 14px;
      margin-bottom: 14px;

      .monthDaysInner {
        margin-top: -1px; } }

    &.nextMonth {
      margin-bottom: 14px; }

    &.prevMonth,
    &.nextMonth {
      .monthDaysInner {
        display: flex;
        justify-content: flex-end; } } }

  .monthNameWrapper {
    position: absolute;
    color: #727981;
    top: -12px;
    left: 289px;
    width: 40px;
    height: 12px;
    transform-origin: 0px 12px;
    transform: rotate(90deg);
    text-align: center; }

  .monthName {
    position: absolute;
    font-family: Gilroy;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: .86;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    letter-spacing: 0.02em;
    text-transform: uppercase; }

  .monthSubTitleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px; }

  .monthSubTitle {
    font-family: Gilroy;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    color: var(--font-black); }

  .addDaysButton {
    font-family: Roboto;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #727981;
    border-color: #E5EAEE;
    background: #E5EAEE;
    border-radius: 5px;
    padding: 4px 10px 5px;
    height: 22px;

    transition: .25s ease;

    &.active {
      color: white;
      background-color: var(--primary-color); }

    &:hover {
      color: white;
      background-color: var(--primary-color); } }

  .daysList {
    border-top: 1px solid #7279811a;
    border-bottom: 1px solid #7279811a;
    display: flex; }

  .daysWrapper {
    padding-top: 1px;
    overflow-y: auto;

    &.limited {
      height: calc(100vh - 60px - 62px - 55px); // widhout header, footer and days
      position: relative;

      .days {
        position: absolute; } } }

  .dayName {
    height: $cellWidth;
    flex: 0 0 $cellWidth;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    text-align: center;
    font-feature-settings: 'tnum' on, 'lnum' on;
    color: var(--font-black); }

  .emptyDay {
    height: calc(#{$cellWidth} - 1px);
    width: calc(#{$cellWidth} - 1px);
    margin-top: 1px;
    margin-right: 1px;
    display: inline-flex;
    vertical-align: bottom;
    position: relative;

    &:after {
      content: '';
      position: absolute;
      left: -1px;
      top: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 1px solid #F1F1F2; } } }

body[data-theme='dark'] {
  .monthTable {
    td {
      border-color: var(--dt-qs-gray-20); } }

  .monthNameWrapper {
    color: var(--dt-qs-gray); }

  .daysList {
    border-color: var(--dt-qs-gray-20); }

  .monthDaysInner {
    border-color: var(--dt-qs-gray-20);
    background-image: linear-gradient(330deg, var(--dt-qs-dark) 44.12%, var(--dt-qs-gray-20) 44.12%, var(--dt-qs-gray-20) 50%, var(--dt-qs-dark) 50%, var(--dt-qs-dark) 94.12%, var(--dt-qs-gray-20) 94.12%, var(--dt-qs-gray-20) 100%);
    &:before {
      background-color: var(--dt-qs-gray-20); } }

  .addDaysButton {
    color: var(--dt-qs-gray);
    border-color: var(--dt-qs-gray-20);
    background-color: var(--dt-qs-dark); }

  .gearIcon {
    fill: var(--dt-qs-gray); } }
